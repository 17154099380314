import React, { useState, useEffect } from 'react';
import {
    MOBIUS_LABELS,
    MOBIUS_DESCRIPTION_TEXT_AREA_ROWS
} from '../../../constants/Mobius';
import { MOBIUS_PROGRAM_FIELDS, MOBIUS_DESCRIPTION_LENGTHS } from '../../../constants/MobiusProgramValidation';
import GNInput from '../../common/gnInput/GNInput';
import GNDropdown from '../../common/gnDropdown/GNDropdown';
import './DescriptionFields.scss';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

export const DescriptionFields = ({
    allLanguages,
    defaultLanguage,
    descriptions,
    errors,
    status,
    handleFormChange,
    validateForm
}) => {
    const [selectedLanguage, setSelectedLanguage] = useState(descriptions?.[0]?.language || defaultLanguage);
    const [numOfDescriptionsEntered, setNumOfDescriptionsEntered] = useState(0);

    useEffect(() => {
        const langValuesEntered =
            descriptions?.filter((description) => description?.value?.length > 0) || [];
        setNumOfDescriptionsEntered(langValuesEntered.length);
    }, [descriptions]);

    const changeAllLanguages = (item) => {
        setSelectedLanguage(item);
        descriptions.forEach((d, idx) => {
            handleFormChange(MOBIUS_PROGRAM_FIELDS.DESCRIPTIONS_LANGUAGE_CODE(idx), item)
        });
    }

    return (
        <div className="mobius-description-fields">
            <GNDropdown
                label={MOBIUS_LABELS.DESCRIPTION_LANGUAGE}
                options={allLanguages}
                value={selectedLanguage}
                isAlphabeticallySorted
                handleChange={(item) => changeAllLanguages(item)}
                {...(numOfDescriptionsEntered > 0 && {
                    isRequired: true,
                    validateInput: validateForm(MOBIUS_PROGRAM_FIELDS.DESCRIPTIONS)
                })}
            />
            <div className='description-message'>
                {MOBIUS_LABELS.DESCRIPTIONS}{' '}
                {(status?.descriptions?.length > 0 || errors) && (
                    <span className={ClassNames('required-message', { invalid: numOfDescriptionsEntered === 0 })}>
                        {MOBIUS_LABELS.DESCRIPTION_REQUIRED_MESSAGE}
                    </span>
                )}
            </div>
            {descriptions.map((description, idx) => (
                <div className="description-field-wrapper" key={idx}>
                    <span className="description-length">{description.length}</span>
                    <GNInput
                        handleChange={(item) => handleFormChange(MOBIUS_PROGRAM_FIELDS.DESCRIPTIONS_TEXT(idx), item)}
                        placeholder="Description"
                        textAreaMax={MOBIUS_DESCRIPTION_LENGTHS[description.length]}
                        textAreaRows={MOBIUS_DESCRIPTION_TEXT_AREA_ROWS[description.length]}
                        type="textarea"
                        validationText={Array.isArray(errors) ? errors[idx]?.value : errors}
                        validateInput={() => (Array.isArray(errors) && validateForm(MOBIUS_PROGRAM_FIELDS.DESCRIPTIONS_TEXT(idx)) || !Array.isArray(errors) && validateForm(MOBIUS_PROGRAM_FIELDS.DESCRIPTIONS))}
                        value={description.value}
                        status={status?.descriptions?.length > 0 && numOfDescriptionsEntered === 0}
                    />
                </div>
            ))}
        </div>
    )
}

DescriptionFields.propTypes = {
    allLanguages: PropTypes.array,
    defaultLanguage: PropTypes.string,
    descriptions: PropTypes.array,
    errors: PropTypes.oneOf([PropTypes.string, PropTypes.array]),
    status: PropTypes.object,
    handleFormChange: PropTypes.func,
    validateForm: PropTypes.func
};

export default DescriptionFields;