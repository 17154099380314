import React from 'react';
import "./GNDatePickerWithYear.scss";
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { REQUIRED } from '../../../constants/App';

export const GNDatePickerWithYear = (props) => {
    return (
        <div className="gnview-date-picker-with-year">
            <div className={'date-picker-label'}>{props.label}</div>
            <DatePicker
                disabled={props?.disabled}
                strictParsing={props.strictParsing}
                className={ClassNames('date-picker-input form-control', { invalid: !props?.validateYear() })}
                isClearable={!props?.disabled}
                onChange={date => props.handleChange(date)}
                placeholderText={props.placeholder}
                selected={props.value || null} />
            <div className={ClassNames("validation-text", {'invalid': !props?.validateYear()})}>
                <Form.Text className="required-text">{props?.isRequired && REQUIRED}{props?.validationText}</Form.Text>
            </div>
        </div>
    );
}

GNDatePickerWithYear.defaultProps = {
    disabled: false,
    strictParsing: false,
    isRequired: false,
    placeholder: ''
};

GNDatePickerWithYear.propTypes = {
    disabled: PropTypes.bool,
    strictParsing: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    validateYear: PropTypes.func.isRequired,
    validationText: PropTypes.string,
    value: PropTypes.object // Date object or null
};

export default GNDatePickerWithYear;