import React from "react";
import { useSelector } from "react-redux";
import { MOBIUS_PROGRAM_FIELDS } from "../../../constants/MobiusProgramValidation";
import GNInput from "../../common/gnInput/GNInput";
import {
    MOBIUS_LABELS,
    MOBIUS_PROGRAM_TYPES_VALUES,
    EDIT_DEFAULT_OPTION
} from "../../../constants/Mobius";
import "./ReleaseDetails.scss";
import PropTypes from "prop-types";
import DurationFields from "../durationFields/DurationFields";
import GNDropdown from "../../common/gnDropdown/GNDropdown";
import {
    mobiusVocabSelColor,
    mobiusVocabSelTargetAudience
} from "../../../reducers/MobiusVocabReducer";
import GNDatePickerWithYear from "../../common/gnDatePickerWithYear/GNDatePickerWithYear";
import ErrorBoundary from '../../common/errorBoundary/ErrorBoundary';
import {
    convertDurationTime,
    isOneOfTwoRequired
} from "../../../utils/MobiusUtils";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IndustryFields from "../industryFields/IndustryFields";
import isString from "lodash.isstring";

export const ReleaseDetails = ({
    errors,
    handleFormChange,
    isDurationRequired,
    isColorRequired,
    isFinaleDateRequired,
    isPresentationReleaseDateRequired,
    isPresentationReleaseYearRequired,
    isPublishState,
    isVersionReleaseDateRequired,
    isVersionReleaseYearRequired,
    isIndustryNetworkNumberRequired,
    isEpisodeNumberRequired,
    isPartNumbersRequired,
    isTargetAudienceRequired,
    program,
    status,
    registerPresentationMode,
    validateForm
}) => {
    const allMobiusVocabColors = useSelector(mobiusVocabSelColor);
    const formattedVocabColors =
    allMobiusVocabColors?.map((color) => ({
        name: color.label,
        value: color.value
    })) || [];
    const allMobiusVocabTargetAudiences = useSelector(
        mobiusVocabSelTargetAudience
    );
    const formattedVocabTargetAudiences =
    allMobiusVocabTargetAudiences?.map((targetAudience) => ({
        name: targetAudience.label,
        value: targetAudience.value
    })) || [];
    const isMovie =
    program?.type.toLowerCase() === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE;
    const isShow =
    program?.type.toLowerCase() === MOBIUS_PROGRAM_TYPES_VALUES.SHOW;
    const isEpisode =
    program?.type.toLowerCase() === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE;
    const presentationReleaseDateComponent = (type) => (
        <GNDatePickerWithYear
            strictParsing={true}
            handleChange={(item) =>
                handleFormChange(
                    MOBIUS_PROGRAM_FIELDS.PRESENTATION_RELEASE_DATE,
                    !item ? "" : moment(item).format("YYYY-MM-DD")
                )
            }
            isRequired={
                type === MOBIUS_PROGRAM_TYPES_VALUES.SHOW
                    ? true
                    : isPresentationReleaseDateRequired ||
            isOneOfTwoRequired(isMovie, program?.releaseYear)
            }
            label={MOBIUS_LABELS.PRESENTATION_RELEASE_DATE}
            placeholder={MOBIUS_LABELS.DATE_PLACEHOLDER}
            validateYear={() =>
                validateForm(MOBIUS_PROGRAM_FIELDS.PRESENTATION_RELEASE_DATE)
            }
            validationText={errors?.releaseDate}
            value={
        program?.releaseDate
            ? new Date(moment(program?.releaseDate).format())
            : null
            }
        />
    );
    const oneOfTwoComponent = () => (
        <div className="one-of-two-required">
            <FontAwesomeIcon icon="info-circle" />
            {MOBIUS_LABELS.ONE_OF_TWO_REQUIRED}
        </div>
    );

    return (
        <ErrorBoundary>
            <div className="additional-details-main-container">
                <div className="first-row">
                    {!isEpisode && (
                        <GNDatePickerWithYear
                            strictParsing={true}
                            disabled={registerPresentationMode}
                            handleChange={(item) =>
                                handleFormChange(
                                    MOBIUS_PROGRAM_FIELDS.VERSION_RELEASE_DATE,
                                    !item ? "" : moment(item).format("YYYY-MM-DD")
                                )
                            }
                            isRequired={isVersionReleaseDateRequired || isOneOfTwoRequired(isMovie, program?.versions?.releaseYear)}
                            label={MOBIUS_LABELS.VERSION_RELEASE_DATE}
                            placeholder={MOBIUS_LABELS.DATE_PLACEHOLDER}
                            validateYear={() =>
                                validateForm(MOBIUS_PROGRAM_FIELDS.VERSION_RELEASE_DATE)
                            }
                            validationText={errors?.versions?.releaseDate}
                            value={
                program?.versions?.releaseDate
                    ? new Date(moment(program?.versions.releaseDate).format())
                    : null
                            }
                        />
                    )}
                    {!isMovie &&
            presentationReleaseDateComponent(program?.type.toLowerCase())}
                    {isEpisode &&
                        <GNInput
                            handleChange={(item) =>
                                handleFormChange(MOBIUS_PROGRAM_FIELDS.EPISODE_NUMBER, item)
                            }
                            isRequired={isEpisodeNumberRequired}
                            label={MOBIUS_LABELS.EPISODE_NUMBER}
                            placeholder={MOBIUS_LABELS.EPISODE_NUMBER}
                            validateInput={() =>
                                validateForm(MOBIUS_PROGRAM_FIELDS.EPISODE_NUMBER)
                            }
                            validationText={errors?.episodeNumber}
                            value={program?.episodeNumber}
                        />
                    }
                    {isShow && (
                        <GNDatePickerWithYear
                            strictParsing={true}
                            handleChange={(item) =>
                                handleFormChange(
                                    MOBIUS_PROGRAM_FIELDS.FINALE_DATE,
                                    !item ? "" : moment(item).format("YYYY-MM-DD")
                                )
                            }
                            isRequired={isFinaleDateRequired}
                            label={MOBIUS_LABELS.FINALE_DATE}
                            placeholder={MOBIUS_LABELS.FINALE_DATE}
                            validateYear={() =>
                                validateForm(MOBIUS_PROGRAM_FIELDS.FINALE_DATE)
                            }
                            validationText={errors?.finaleDate}
                            value={
                                program?.finaleDate
                                    ? new Date(moment(program?.finaleDate).format())
                                    : null
                            }
                        />
                    )
                    }
                    {isMovie && (
                        <GNInput
                            className="year-of-release"
                            disabled={registerPresentationMode}
                            handleChange={(item) =>
                                handleFormChange(
                                    MOBIUS_PROGRAM_FIELDS.VERSION_RELEASE_YEAR,
                                    item
                                )
                            }
                            isRequired={isVersionReleaseYearRequired || isOneOfTwoRequired(isMovie, program?.versions.releaseDate)}
                            label={MOBIUS_LABELS.VERSION_RELEASE_YEAR}
                            placeholder={MOBIUS_LABELS.VERSION_RELEASE_YEAR}
                            validateInput={() =>
                                validateForm(MOBIUS_PROGRAM_FIELDS.VERSION_RELEASE_YEAR)
                            }
                            validationText={errors?.versions?.releaseYear}
                            value={program?.versions?.releaseYear}
                        />
                    )}
                    {isMovie && oneOfTwoComponent()}
                </div>
                {isMovie && (
                    <div className="second-row">
                        {presentationReleaseDateComponent(program?.type.toLowerCase())}
                        <GNInput
                            className="year-of-release"
                            handleChange={(item) =>
                                handleFormChange(
                                    MOBIUS_PROGRAM_FIELDS.PRESENTATION_RELEASE_YEAR,
                                    item
                                )
                            }
                            isRequired={isPresentationReleaseYearRequired || isOneOfTwoRequired(isMovie, program?.releaseDate)}
                            label={MOBIUS_LABELS.PRESENTATION_RELEASE_YEAR}
                            placeholder={MOBIUS_LABELS.PRESENTATION_RELEASE_YEAR}
                            validateInput={() =>
                                validateForm(MOBIUS_PROGRAM_FIELDS.PRESENTATION_RELEASE_YEAR)
                            }
                            validationText={errors?.releaseYear}
                            value={program?.releaseYear}
                        />
                        {oneOfTwoComponent()}
                    </div>
                )}
                <div className="second-row">
                    {isEpisode && (
                        <GNInput
                            className="part-number"
                            handleChange={(item) =>
                                handleFormChange(MOBIUS_PROGRAM_FIELDS.PART_NUMBERS, item)
                            }
                            isRequired={isPartNumbersRequired}
                            label={MOBIUS_LABELS.PART_NUMBER}
                            placeholder={MOBIUS_LABELS.PART_NUMBER}
                            validateInput={() =>
                                validateForm(MOBIUS_PROGRAM_FIELDS.PART_NUMBERS)
                            }
                            validationText={errors?.partNumbers}
                            value={program?.partNumbers}
                        />
                    )}
                    {isEpisode &&
                        <IndustryFields
                            errors={errors}
                            handleFormChange={handleFormChange}
                            industryNetworkNumber={program?.industryNetworkNumber}
                            industryNetworkSyndicated={program?.industryNetworkSyndicated}
                            // Since these fields go together, only need to check one
                            isRequired={isIndustryNetworkNumberRequired}
                            validateForm={validateForm}
                        />
                    }
                </div>
                <DurationFields
                    errors={errors?.versions}
                    status={status?.versions}
                    disabled={registerPresentationMode}
                    duration={
                        typeof program?.versions?.duration === "number"
                            ? convertDurationTime(program.versions.duration)
                            : program.versions.duration
                    }
                    handleFormChange={handleFormChange}
                    isRequired={isDurationRequired}
                    validateForm={validateForm}
                />
                <div className="fifth-row">
                    <GNInput
                        className="original-source"
                        handleChange={(item) =>
                            handleFormChange(MOBIUS_PROGRAM_FIELDS.ORIGINAL_SOURCE, item)
                        }
                        label={MOBIUS_LABELS.ORIGINAL_SOURCE}
                        placeholder={MOBIUS_LABELS.ORIGINAL_SOURCE}
                        status={isString(status?.originalSource) && status.originalSource}
                        validateInput={() =>
                            validateForm(MOBIUS_PROGRAM_FIELDS.ORIGINAL_SOURCE)
                        }
                        validationText={errors?.originalSource}
                        hasValue={Boolean(program?.originalSource)}
                        value={program?.originalSource}
                    />
                    <GNDropdown
                        className="color"
                        disabled={registerPresentationMode}
                        handleChange={(item) =>
                            handleFormChange(MOBIUS_PROGRAM_FIELDS.VERSIONS_COLOR, item)
                        }
                        isRequired={isColorRequired}
                        label={MOBIUS_LABELS.COLOR}
                        options={
                            isMovie || isPublishState
                                ? formattedVocabColors
                                : [EDIT_DEFAULT_OPTION, ...formattedVocabColors]
                        }
                        validateInput={() =>
                            validateForm(MOBIUS_PROGRAM_FIELDS.VERSIONS_COLOR)
                        }
                        value={program?.versions?.color}
                        status={isString(status?.versions?.color) && status.versions.color}
                    />
                    <GNDropdown
                        handleChange={(item) =>
                            handleFormChange(MOBIUS_PROGRAM_FIELDS.TARGET_AUDIENCE, item)
                        }
                        isRequired={isTargetAudienceRequired}
                        label={MOBIUS_LABELS.TARGET_AUDIENCE}
                        options={[EDIT_DEFAULT_OPTION, ...formattedVocabTargetAudiences]}
                        value={program?.[MOBIUS_PROGRAM_FIELDS.TARGET_AUDIENCE]}
                    />
                </div>
            </div>
        </ErrorBoundary>
    );
};

ReleaseDetails.propTypes = {
    errors: PropTypes.object.isRequired,
    handleFormChange: PropTypes.func.isRequired,
    isDurationRequired: PropTypes.bool,
    isColorRequired: PropTypes.bool,
    isFinaleDateRequired: PropTypes.bool,
    isPresentationReleaseDateRequired: PropTypes.bool,
    isPresentationReleaseYearRequired: PropTypes.bool,
    isPublishState: PropTypes.bool,
    isVersionReleaseDateRequired: PropTypes.bool,
    isVersionReleaseYearRequired: PropTypes.bool,
    isIndustryNetworkNumberRequired: PropTypes.bool,
    isEpisodeNumberRequired: PropTypes.bool,
    isPartNumbersRequired: PropTypes.bool,
    isTargetAudienceRequired: PropTypes.bool,
    program: PropTypes.object.isRequired,
    registerPresentationMode: PropTypes.bool,
    status: PropTypes.object,
    validateForm: PropTypes.func.isRequired
};

export default ReleaseDetails;
