// GN View Auth
export const GVAUTH_AUTH_UPDATE = 'gvauth/AUTH_UPDATE';

// Mobius
export const MOBIUS_ADD_GNVOCABULARY_IN_PROGRESS = 'mobius/GNVOCABULARY_IN_PROGRESS';
export const MOBIUS_ADD_GNVOCABULARY_SUCCESS = 'mobius/GNVOCABULARY_SUCCESS';
export const MOBIUS_ADD_GNVOCABULARY_FAIL = 'mobius/GNVOCABULARY_FAIL';
export const MOBIUS_ADD_ENTITLEMENTS = 'mobius/ENTITLEMENTS';
export const MOBIUS_ADD_ENTITLEMENTS_FAIL = 'mobius/ENTITLEMENTS_FAIL';
export const MOBIUS_ADD_CATALOGS_SUCCESS = 'mobius/CATALOGS_SUCCESS';
export const MOBIUS_ADD_CATALOGS_FAIL = 'mobius/CATALOGS_FAIL';

// GNIDS Season
export const GNIDS_TOGGLE_SEASON_MODAL = 'gnids/GNIDS_TOGGLE_SEASON_MODAL';
export const GNIDS_SET_SEASON_MODAL_TITLE = 'gnids/GNIDS_SET_SEASON_MODAL_TITLE';
export const GNIDS_SET_SEASON_GNID = 'gnids/GNIDS_SET_SEASON_GNID';
export const GNIDS_SET_SEASON_NUMBER = 'gnids/GNIDS_SET_SEASON_NUMBER';
export const GNIDS_SET_SEASON = 'gnids/GNIDS_SET_SEASON';
export const GNIDS_CLEAR_SEASON = 'gnids/GNIDS_CLEAR_SEASON';

// GNIDS Episode
export const GNIDS_UPDATE_EPISODE = 'gnids/UPDATE_EPISODE';
export const GNIDS_CLEAR_EPISODE = 'gnids/CLEAR_EPISODE';

// ProgramModal
export const SET_GNIDS_PROGRAM_DATA = 'gnids/SET_GNIDS_PROGRAM_DATA';
export const SET_GNIDS_PROGRAM = 'gnids/SET_GNIDS_PROGRAM';
export const SET_GNIDS_PROGRAM_IMAGES = 'gnids/SET_GNIDS_PROGRAM_IMAGES';
export const CLEAR_GNIDS_PROGRAM_IMAGES = 'gnids/CLEAR_GNIDS_PROGRAM_IMAGES';
export const CLEAR_GNIDS_PROGRAM_MODAL_DATA = 'gnids/CLEAR_GNIDS_PROGRAM_MODAL_DATA';
export const UPDATE_PRESENTATION_ID = 'gnids/UPDATE_PRESENTATION_ID';
export const SET_BREADCRUMB_TO_EDIT_MODE = 'gnids/SET_BREADCRUMB_TO_EDIT_MODE';
export const SET_CURRENT_PROGRAMS = 'gnids/SET_CURRENT_PROGRAMS';

// ImageModal
export const SET_GNIDS_IMAGE = 'gnids/SET_GNIDS_IMAGE_DATA';
export const CLEAR_GNIDS_IMAGE_MODAL_DATA = 'gnids/CLEAR_GNIDS_IMAGE_MODAL_DATA';
export const SHOW_IMAGE_MODAL = 'gnids/SHOW_IMAGE_MODAL';

// CatalogModal
export const SET_GNIDS_CATALOG = 'gnids/SET_GNIDS_CATALOG';
export const CLEAR_GNIDS_CATALOG_MODAL_DATA = 'gnids/CLEAR_GNIDS_CATALOG_MODAL_DATA';
export const SHOW_CATALOG_MODAL = 'gnids/SHOW_CATALOG_MODAL';
export const SET_SELECTED_CATALOG_FROM_DROPDOWN = 'gnids/SELECTED_CATALOG_FROM_DROPDOWN'
// To toggle a specific program
export const GNIDS_TABLE_POPOVER_TOGGLE = 'gnids/GNIDS_TABLE_POPOVER_TOGGLE';
// To toggle all the rows' ellipses in a table (for example for collapsing all of them)
export const GNIDS_TABLE_POPOVER_TOGGLE_ALL = 'gnids/GNIDS_TABLE_POPOVER_TOGGLE_ALL';

// To toggle a specific catalog
export const GNIDS_CATALOG_TABLE_POPOVER_TOGGLE = 'gnids/GNIDS_CATALOG_TABLE_POPOVER_TOGGLE';
// To toggle all the programs (for example for collapsing all of them)
export const GNIDS_CATALOG_TABLE_POPOVER_TOGGLE_ALL = 'gnids/GNIDS_CATALOG_TABLE_POPOVER_TOGGLE_ALL';

// catalog mapping related actions
export const SET_CURRENT_SELECTED_CATALOG = 'gnids/SET_CURRENT_SELECTED_CATALOG';
export const GNIDS_UPDATE_REMOVE_CATALOG_PROGRAM_MAPPINGS = 'gnids/GNIDS_REMOVE_CATALOG_MAPPINGS_SUCCESS';
export const GNIDS_UPDATE_REMOVE_CATALOG_PROGRAM_MAPPINGS_FAIL = 'gnids/GNIDS_REMOVE_CATALOG_MAPPINGS_FAIL';

// Program files
export const GNIDS_SET_PROGRAM_FILES = 'gnids/GNIDS_SET_PROGRAM_FILES';
export const GNIDS_CLEAR_PROGRAM_FILES = 'gnids/GNIDS_CLEAR_PROGRAM_FILES';
export const GNIDS_BULK_UPLOAD_SUCCESS = 'gnids/GNIDS_BULK_UPLOAD_SUCCESS';
export const GNIDS_BULK_UPLOAD_FAILURE = 'gnids/GNIDS_BULK_UPLOAD_FAILURE';