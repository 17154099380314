import {
    MOBIUS_STATUS_COLORS,
    MOBIUS_STATUS_ICONS,
    MOBIUS_VOCABULARIES_LIST,
    MOBIUS_PROGRAM_TYPES_VALUES,
    MOBIUS_PROGRAM_DEFAULT_SUBTYPE,
    MOBIUS_LABELS,
    MOBIUS_BOOLEAN_TEXT,
    MOBIUS_PROGRAM_PUBLISHING_STATUS,
    MOBIUS_PROGRAM_SUBTYPES_VALUES
} from '../constants/Mobius';
import {
    MOBIUS_PROGRAM_INITIAL_VALUES,
    MOBIUS_PROGRAM_FIELDS,
    MOBIUS_INITIAL_DESCRIPTIONS,
    MOBIUS_MOVIES_REGISTER_VALIDATION_SCHEMA,
    MOBIUS_SHOWS_REGISTER_VALIDATION_SCHEMA,
    MOBIUS_EPISODE_REGISTER_VALIDATION_SCHEMA,
    MOBIUS_PROGRAM_REGISTER_VALIDATION_SCHEMA,
    MOBIUS_MOVIES_PUBLISHED_VALIDATION_SCHEMA,
    MOBIUS_SHOWS_SERIES_PUBLISHED_VALIDATION_SCHEMA,
    MOBIUS_SHOWS_NON_SERIES_PUBLISHED_VALIDATION_SCHEMA,
    MOBIUS_EPISODE_PUBLISHED_VALIDATION_SCHEMA
} from '../constants/MobiusProgramValidation';
import moment from 'moment-timezone';
import isEmpty from 'lodash.isempty';
import get from 'lodash.get';
import set from 'lodash.set';
import cloneDeep from 'lodash.clonedeep';
import { generateDescriptions } from './ProgramUtils';
import { MOBIUS_SEASON_FIELDS, MOBIUS_SEASON_INITIAL_VALUES } from '../constants/MobiusSeasonValidation';

export const getStatusColor = (status) => {
    return MOBIUS_STATUS_COLORS[status];
};

export const getStatusIcon = (status) => {
    return MOBIUS_STATUS_ICONS[status];
};

export const getProgramMenuItems = (menuItems, programType, editMode = false, isRegisterModal = false, publishException = false) => {
    const noSeasonsAndEpisodesMenuItems = Object.values(menuItems).filter(
        (item) => !item.id.includes('seasons-and-episodes')
    );

    const registerMenuItems = Object.values(noSeasonsAndEpisodesMenuItems).filter(
        (item) => !item.id.includes('images')
    )
    let finalMenu;
    if (isRegisterModal) {
        finalMenu = registerMenuItems
    } else if (editMode || programType === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE || programType === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE) {
        finalMenu = noSeasonsAndEpisodesMenuItems;
    } else {
        finalMenu = Object.values(menuItems);
    }

    if (publishException) {
        return finalMenu;
    } else {
        return finalMenu.filter(i => !i.id.includes('publish-exception'));
    }
};

export const getProgramMenuRatios = (menuRatios, programType, editMode = false) => {
    const noSeasonAndEpisodesMenuRatios = menuRatios.slice(0, 1).concat(menuRatios.slice(2))
    let finalRatio;
    if (editMode || programType === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE || programType === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE) {
        finalRatio = noSeasonAndEpisodesMenuRatios;
    } else {
        finalRatio = menuRatios;
    }
    return finalRatio;
}

export const convertDurationTime = (milliseconds) => {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    seconds = Math.floor(seconds % 60);
    minutes = Math.floor(minutes % 60);
    return { hours: hours.toString(), minutes: minutes.toString(), seconds: seconds.toString() };
};

export const convertDurationIntoMillis = (durationString) => {
    const [hours, minutes, seconds] = durationString.split(':');

    let convertedDuration = 0;

    if (!isNaN(hours) && hours.length > 0) {
        convertedDuration += parseInt(hours, 10) * 3600;
    }
    if (!isNaN(minutes) && minutes.length > 0) {
        convertedDuration += parseInt(minutes, 10) * 60;
    }
    if (!isNaN(seconds) && seconds.length > 0) {
        convertedDuration += parseInt(seconds, 10);
    }

    // Corrected placement of multiplication
    convertedDuration *= 1000;

    return convertedDuration;
};

export const getValidationSchema = (type, publishingStatus = '', subtype = '') => {
    const isPublishState = publishingStatus === MOBIUS_PROGRAM_PUBLISHING_STATUS.REQUESTED || publishingStatus === MOBIUS_PROGRAM_PUBLISHING_STATUS.PUBLISHED || publishingStatus === MOBIUS_PROGRAM_PUBLISHING_STATUS.PUBLISHING;
    const isSubTypeSeries = subtype === MOBIUS_PROGRAM_SUBTYPES_VALUES.SERIES || subtype === MOBIUS_PROGRAM_SUBTYPES_VALUES.MINISERIES;
    if (type === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE) {
        return isPublishState ? MOBIUS_MOVIES_PUBLISHED_VALIDATION_SCHEMA : MOBIUS_MOVIES_REGISTER_VALIDATION_SCHEMA;
    } else if (type === MOBIUS_PROGRAM_TYPES_VALUES.SHOW) {
        if (isPublishState) {
            return isSubTypeSeries ? MOBIUS_SHOWS_SERIES_PUBLISHED_VALIDATION_SCHEMA : MOBIUS_SHOWS_NON_SERIES_PUBLISHED_VALIDATION_SCHEMA;
        }
        return MOBIUS_SHOWS_REGISTER_VALIDATION_SCHEMA;
    } else if (type === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE) {
        return isPublishState ? MOBIUS_EPISODE_PUBLISHED_VALIDATION_SCHEMA : MOBIUS_EPISODE_REGISTER_VALIDATION_SCHEMA;
    }
    return MOBIUS_PROGRAM_REGISTER_VALIDATION_SCHEMA;
}

export const isOneOfTwoRequired = (isMovie, fieldValue) => {
    return isMovie ? !fieldValue : false;
};

export const getDefaultLanguage = (allLanguages) => allLanguages.find((lang) => lang.value === 'en') || allLanguages[0];

/**
 * Compare program with initialProgram
 * If a field is missing or null, add all missed field
 * @param {Object} initialProgram - initial values of program
 * @param {Object} program - current program data which should be updated
 * @param {string} field - field of current program which should be updated
 * @param {string} defaultLanguage
 */
export const updateFieldIfNotExist = (initialProgram, program, field, defaultLanguage = '') => {
    for (const [key, val] of Object.entries(initialProgram)) {
        if (typeof val === 'object' && !Array.isArray(val)) {
            // Non iterable object - cast.name, crew.name
            updateFieldIfNotExist(val, program, `${field}.${key}`);
        }

        if (key === 'uniqKey') {
            continue;
        }

        if (key.includes(MOBIUS_PROGRAM_FIELDS.LANGUAGE) && !get(program, `${field}.${key}`)?.length) {
            set(program, `${field}.${key}`, defaultLanguage);
        } else {
            if (typeof get(program, `${field}.${key}`) === 'boolean') {
                continue;
            }
            set(program, `${field}.${key}`, get(program, `${field}.${key}`) || val);
        }
    }
};

/**
 * Validate if a given program has all the required fields
 * @param {Object} program - current program data
 * @param {Object} defaultLanguage - if defaultLanguage does not exist, language will be an empty string
 * @returns {Object}
 */
export const verifyDataIntegrity = (program, defaultLanguage) => {
    if (isEmpty(program)) {
        return {
            ...MOBIUS_PROGRAM_INITIAL_VALUES,
            title: {
                value: '',
                language: get(defaultLanguage, MOBIUS_PROGRAM_FIELDS.VALUE, '')
            },
            descriptions: MOBIUS_INITIAL_DESCRIPTIONS.map((description) => ({
                ...description,
                language: get(defaultLanguage, MOBIUS_PROGRAM_FIELDS.VALUE, '')
            }))
        };
    }

    for (const [field, val] of Object.entries(MOBIUS_PROGRAM_INITIAL_VALUES)) {
        switch (field) {
        // Array of Object
        case MOBIUS_PROGRAM_FIELDS.CAST:
        case MOBIUS_PROGRAM_FIELDS.CREW:
        case MOBIUS_PROGRAM_FIELDS.EXTERNAL_IDS:
        case MOBIUS_PROGRAM_FIELDS.RATINGS:
            if (isEmpty(program[field])) {
                set(program, field, val);
            } else {
                program[field].forEach((_, idx) => {
                    updateFieldIfNotExist(val[0], program, `${field}[${idx}]`);
                });
            }
            break;
        case MOBIUS_PROGRAM_FIELDS.DESCRIPTIONS:
            if (isEmpty(program[field])) {
                set(
                    program,
                    field,
                    val.map((desc) => ({
                        ...desc,
                        language: get(defaultLanguage, MOBIUS_PROGRAM_FIELDS.VALUE, '')
                    }))
                );
            } else {
                set(program, field, generateDescriptions(program[field]));
                program[field].forEach((_, idx) => {
                    updateFieldIfNotExist(val[idx], program, `${field}[${idx}]`, get(defaultLanguage, MOBIUS_PROGRAM_FIELDS.VALUE, ''));
                });
            }
            break;
        // Object
        case MOBIUS_PROGRAM_FIELDS.TITLE:
            updateFieldIfNotExist(val, program, field, get(defaultLanguage, MOBIUS_PROGRAM_FIELDS.VALUE, ''));
            break;
        case MOBIUS_PROGRAM_FIELDS.VERSIONS:
            // eslint-disable-next-line no-case-declarations
            const durationInMilliSeconds = get(program, MOBIUS_PROGRAM_FIELDS.DURATION);
            if (typeof durationInMilliSeconds === 'number') {
                set(program, MOBIUS_PROGRAM_FIELDS.DURATION, convertDurationTime(durationInMilliSeconds))
            }

            // Update Default Color
            // Movie - color
            // Show, Episode - an empty string
            if (program[MOBIUS_PROGRAM_FIELDS.TYPE] === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE) {
                updateFieldIfNotExist(val, program, field);
            } else {
                const updatedColorInitialValues = cloneDeep(val);
                set(updatedColorInitialValues, MOBIUS_PROGRAM_FIELDS.COLOR, '');
                updateFieldIfNotExist(updatedColorInitialValues, program, field);
            }
            break;
        // When subtype is null/undefined, add a default subtype value
        case MOBIUS_PROGRAM_FIELDS.SUBTYPE:
            if (isEmpty(program[field]) && program[MOBIUS_PROGRAM_FIELDS.TYPE] === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE) {
                set(program, field, MOBIUS_PROGRAM_DEFAULT_SUBTYPE[MOBIUS_PROGRAM_TYPES_VALUES.MOVIE]);
            } else if (isEmpty(program[field]) && program[MOBIUS_PROGRAM_FIELDS.TYPE] === MOBIUS_PROGRAM_TYPES_VALUES.SHOW) {
                set(program, field, MOBIUS_PROGRAM_DEFAULT_SUBTYPE[MOBIUS_PROGRAM_TYPES_VALUES.SHOW]);
            } else if (isEmpty(program[field]) && program[MOBIUS_PROGRAM_FIELDS.TYPE] === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE) {
                set(program, field, MOBIUS_PROGRAM_DEFAULT_SUBTYPE[MOBIUS_PROGRAM_TYPES_VALUES.EPISODE]);
            } else if (isEmpty(program[field]) && isEmpty(program[MOBIUS_PROGRAM_FIELDS.TYPE])) {
                set(program, MOBIUS_PROGRAM_FIELDS.TYPE, MOBIUS_PROGRAM_TYPES_VALUES.MOVIE);
                set(program, field, MOBIUS_PROGRAM_DEFAULT_SUBTYPE[MOBIUS_PROGRAM_TYPES_VALUES.MOVIE]);
            }
            break;
        default:
            set(program, field, get(program, field) || val);
            break;
        }
    }
    set(program, 'userMapping', {
        id: program.mappingInfo?.id || program.internalAttributes?.selfMappingID || '',
        type: 'tmsID'
    })
    return program;
};

export const verifyPublishPrereqs = (publishPreReqsFileds) => {
    const initialFormikStatus = {}
    const versionStatus = {}
    publishPreReqsFileds.forEach(data => {
        if (data.level === 'version' || data.field === 'color' || data.field === 'duration') {
            versionStatus[data.field] = MOBIUS_LABELS.REQUIRED_FOR_PUBLISH
        } else {
            initialFormikStatus[data.field] = MOBIUS_LABELS.REQUIRED_FOR_PUBLISH
        }
        initialFormikStatus[MOBIUS_PROGRAM_FIELDS.VERSIONS] = versionStatus
    })
    return initialFormikStatus;
}

export const convertMobiusPresentationProgram = (
    program,
    programTypes,
    colorTypes,
    castTypes,
    crewTypes,
    countriesTypes
) => {
    const { type, subType, color, cast, crew, productionCountries } = program;
    let convertedType = type;
    let convertedSubType = subType;
    let convertedColor = color;
    const convertedCountries = [];

    // Update Program Type
    if (type?.length > 0) {
        programTypes.map((programType) => {
            if (programType.value === convertedType) {
                convertedType = programType.label;
                // Update SubType
                if (subType?.length > 0) {
                    programType.programSubType.map((sub) => {
                        if (sub.value === convertedSubType) {
                            convertedSubType = sub.label;
                        }
                    });
                }
            }
        });
    }

    // Update Color Type
    if (color?.length > 0) {
        colorTypes.map((colorType) => {
            if (colorType.value === color) {
                convertedColor = colorType.label;
            }
        });
    }

    // Update Production Countries
    if (productionCountries?.length > 0) {
        const countriesLabel = [];
        const countriesValue = [];
        countriesTypes.map((country) => {
            countriesLabel.push(country.label);
            countriesValue.push(country.value);
        });

        productionCountries.map((country) => {
            const productionCountryValueIndex = countriesValue.findIndex(
                (val) => val === country
            );
            convertedCountries.push(
                countriesLabel[productionCountryValueIndex]
            );
        });
    }

    // Update Cast Type
    if (cast?.length > 0) {
        const castLabels = [];
        const castValues = [];
        castTypes.map((castType) => {
            castLabels.push(castType.label);
            castValues.push(castType.value);
        });

        cast.map((castType) => {
            const castValueIndex = castValues.findIndex(
                (val) => val === castType.role
            );
            castType.role = castLabels[castValueIndex];
        });
    }

    // Update Crew Type
    if (crew?.length > 0) {
        const crewLabels = [];
        const crewValues = [];
        crewTypes.map((crewType) => {
            crewLabels.push(crewType.label);
            crewValues.push(crewType.value);
        });

        crew.map((crewType) => {
            const crewValueIndex = crewValues.findIndex(
                (val) => val === crewType.type
            );
            crewType.type = crewLabels[crewValueIndex];
        });
    }

    return {
        ...program,
        type: convertedType,
        subType: convertedSubType,
        color: convertedColor,
        productionCountries: convertedCountries,
        cast: cast?.length > 0 ? cast : null,
        crew: crew?.length > 0 ? crew : null
    };
};

export const convertRegisterProgramToPost = (
    program,
    programTypes,
    castTypes,
    colorTypes,
    crewTypes,
    countriesTypes
) => {
    const {
        cast,
        crew,
        descriptions,
        episodeNumber,
        externalIDs,
        finaleDate,
        genres,
        industryNetworkNumber,
        industryNetworkSyndicated,
        userMapping = {},
        originalSource,
        partNumbers,
        presentationLabels,
        productionCompanies,
        productionCountries,
        productionStatus,
        ratings,
        subType,
        targetAudience,
        title,
        type,
        versions: {
            color,
            duration,
            versionLabels
        }
    } = program;

    let convertedSubType = subType;
    let convertedDuration = 0;
    let convertedColor = null;
    let convertedFinaleDate = finaleDate || null;
    let convertedPresentationReleaseDate = program[MOBIUS_PROGRAM_FIELDS.PRESENTATION_RELEASE_DATE] || null;
    let convertedVersionReleaseDate = get(program, MOBIUS_PROGRAM_FIELDS.VERSION_RELEASE_DATE) || null;
    const convertedPresentationReleaseYear = parseInt(program[MOBIUS_PROGRAM_FIELDS.PRESENTATION_RELEASE_YEAR]) || null;
    const convertedVersionReleaseYear = parseInt(get(program, MOBIUS_PROGRAM_FIELDS.VERSION_RELEASE_YEAR)) || null;
    const convertedDescriptions = [];
    const convertedProductionCompanies = [];
    const convertedProductionCountries = [];
    const convertedRatings = [];
    const convertedCasts = [];
    const convertedCrews = [];
    const convertedExternalIDs = [];
    const programSubTypes = [];

    // Overview Section
    // Program Type
    if (type?.length > 0) {
        programTypes.map((programType) => {
            if (programType.label === type) {
                programSubTypes.push(...programType.programSubType);
            }
        });
    }

    // Program Subtype
    if (subType?.length > 0 && programSubTypes?.length > 0) {
        programSubTypes.map((programSubType) => {
            if (programSubType.label === subType) {
                convertedSubType = programSubType.value;
            }
        });
    }

    // Descriptions Section
    // Description
    if (descriptions?.length > 0 && descriptions?.filter(item => item.value.length > 0).length) {
        descriptions.map((item) => {
            if (item.value.length > 0) {
                convertedDescriptions.push(item);
            }
        });
    }

    // Ratings - ratings, ratingBody, notYetRated, ratingExempt, advisories
    if (ratings?.length > 0 && ratings?.filter(item => item.ratingBody?.length > 0).length) {
        ratings.map((item) => {
            const currentRating = {}
            const {ratingBody, rating, notYetRated, ratingExempt, advisories} = item
            if (item?.ratingBody?.length > 0 && (item?.rating?.length > 0 || item?.notYetRated || item?.ratingExempt)) {
                currentRating["ratingBody"] = ratingBody
                currentRating["notYetRated"] = notYetRated
                currentRating["ratingExempt"] = ratingExempt
                currentRating["advisories"] = advisories?.map((advisory) => typeof (advisory) === 'object' ? advisory.label : advisory)
                // api expects rating to be more than 1 character if it is present
                if (rating.length > 0) {
                    currentRating["rating"] = rating
                }
                convertedRatings.push(currentRating)
            }
        });
    }

    // External IDs
    if (externalIDs?.[0].label?.length > 0 && externalIDs?.[0].id?.length > 0) {
        externalIDs.map((externalID) => {
            convertedExternalIDs.push(externalID);
        })
    }

    // Release Details
    // Duration
    if (duration?.hours?.length > 0) {
        convertedDuration += parseInt(duration.hours) * 3600;
    }
    if (duration?.minutes?.length > 0) {
        convertedDuration += parseInt(duration.minutes) * 60;
    }
    if (duration?.seconds?.length > 0) {
        convertedDuration += parseInt(duration.seconds);
    }
    convertedDuration *= 1000;

    if (color?.length > 0) {
        colorTypes.map((colorType) => {
            if (colorType.value === color) {
                convertedColor = colorType.value;
            }
        });
    }
    // Finale Date
    if (convertedFinaleDate) {
        convertedFinaleDate = moment(finaleDate).format('YYYY-MM-DD');
    }
    // Presentation Release Date
    if (convertedPresentationReleaseDate) {
        convertedPresentationReleaseDate = moment(program[MOBIUS_PROGRAM_FIELDS.PRESENTATION_RELEASE_DATE]).format('YYYY-MM-DD');
    }
    // Version Release Date
    if (convertedVersionReleaseDate) {
        convertedVersionReleaseDate = moment(get(program, MOBIUS_PROGRAM_FIELDS.VERSION_RELEASE_DATE)).format('YYYY-MM-DD');
    }

    // Production Section
    // Production Countries
    if (productionCountries?.length > 0) {
        const countriesLabels = [];
        const countriesValues = [];
        countriesTypes.map((country) => {
            countriesLabels.push(country.label);
            countriesValues.push(country.value);
        });

        productionCountries.map((country) => {
            const productionCountryLabelIndex = countriesLabels.findIndex(
                (label) => label === country
            );
            if (productionCountryLabelIndex !== -1) {
                convertedProductionCountries.push(
                    countriesValues[productionCountryLabelIndex]
                );
            } else {
                const productionCountryValueIndex = countriesValues.findIndex(
                    (value) => value === country
                ); convertedProductionCountries.push(
                    countriesValues[productionCountryValueIndex]
                );
            }
        });
    }

    // Production Company
    if (productionCompanies?.length > 0 && productionCompanies?.[0] !== '') {
        productionCompanies.map((company) => convertedProductionCompanies.push(company));
    }

    // Cast
    if (cast?.[0].name?.first?.length > 0 && cast?.[0].character?.length > 0 && cast?.[0].role.length > 0) {
        cast.map((castMember) => {
            convertedCasts.push({
                ...castMember,
                role: castTypes.find(t => t.label === castMember.role)?.value ? castTypes.find(t => t.label === castMember.role).value : castMember.role
            });
        });
    }

    // Crew
    if (crew?.[0].name?.first?.length > 0 && crew?.[0].type?.length > 0) {
        crew.map((crewMember) => {
            convertedCrews.push({
                ...crewMember,
                type: crewTypes.find(t => t.label === crewMember.type)?.value ? crewTypes.find(t => t.label === crewMember.type)?.value : crewMember.type
            });
        });
    }

    return {
        cast: convertedCasts,
        color: convertedColor,
        crew: convertedCrews,
        descriptions: convertedDescriptions,
        duration: convertedDuration > 0 ? convertedDuration : null,
        episodeNumber: episodeNumber?.length > 0 ? episodeNumber : null,
        externalIDs: convertedExternalIDs,
        finaleDate: convertedFinaleDate,
        genres,
        industryNetworkNumber: industryNetworkNumber?.length > 0 ? industryNetworkNumber : null,
        industryNetworkSyndicated: industryNetworkSyndicated?.length > 0 ? industryNetworkSyndicated : null,
        userMapping: {
            selfMappingID: userMapping.id,
            selfMappingType: userMapping.type
        },
        originalSource: originalSource?.length > 0 ? originalSource : null,
        partNumbers,
        presentationLabels,
        presentationReleaseDate: convertedPresentationReleaseDate,
        presentationReleaseYear: convertedPresentationReleaseYear,
        productionCompanies: convertedProductionCompanies,
        productionCountries: convertedProductionCountries,
        productionStatus: productionStatus?.length > 0 ? productionStatus : null,
        ratings: convertedRatings?.length ? convertedRatings : null,
        subType: convertedSubType,
        targetAudience: targetAudience?.length > 0 ? targetAudience : null,
        title,
        type,
        versionLabels,
        versionReleaseDate: convertedVersionReleaseDate,
        versionReleaseYear: convertedVersionReleaseYear
    };
};

// marks a image as linked
export const linkImages = (images, isLinked = false) => {
    const updatedImages = images.map((image) => {
        if (!image?.link) {
            return { ...image, link: isLinked}
        }
        return image
    })
    return updatedImages
}

export const convertProgramImageForMapping = (presentationGnID, images, type) => {
    const data = []
    images.forEach((image) => {
        if ((type === 'post' && !image.link) || (type === 'patch' && image.link)) {
            const presentationImageMapping = {
                imageGnID: image.gnID,
                presentationGnID
            }
            data.push(presentationImageMapping)
        }
    })
    return data
}

/**
 * Find the program subtype vocab inside the program type vocab
 * @param {Object} vocab - The entire Program Type gnvocabulary
 * @param {String} programTypeField - The value or label of program type
 * @returns {Array} - Will return Program Subtype vocab
 */
export const getProgramSubTypeVocab = (vocab, programTypeField) => {
    if (!vocab || programTypeField?.length <= 0) {
        return {};
    }

    for (const programType of vocab) {
        const { label, value } = programType;
        if (label === programTypeField || value === programTypeField) {
            return programType[MOBIUS_VOCABULARIES_LIST.PROGRAM_SUBTYPE];
        }
    }
    return {};
};

/**
 * Convert Vocab from value -> label OR from label -> value
 * @param {Object} vocab - The entire gnvocabulary
 * @param {String} item - The value you want to convert into a label
 * @param {String} field - The field from the vocab to do the conversion. If convertToProgramSubType is true, field must be programType
 * @param {Boolean} convertToLabel - true: convert from value -> label, false: label -> value
 * @param {Boolean} convertToProgramSubType - true: you want to convert something by using prog subtype vocab
 * @param {String} programTypeField - The value or label of program type to get the program subtype vocab. When convertToProgramSubType is true, programTypeField should be entered
 * @returns {String} - Will return the converted label or the value if it wasn't to convert
 */
export const convertVocab = (
    vocab,
    item,
    field,
    convertToLabel = true,
    convertToProgramSubType = false,
    programTypeField = ''
) => {
    if (!vocab || !vocab[field]) {
        return item;
    }
    const checkLabel = (i) => i.label === item;
    const checkValue = (i) => i.value === item;

    const vocabItemList = !convertToProgramSubType
        ? vocab[field]
        : getProgramSubTypeVocab(vocab[field], programTypeField);
    const vocabItem =
        vocabItemList?.find((i) => (convertToLabel ? checkValue(i) : checkLabel(i))) || {};
    const converted = convertToLabel ? vocabItem?.label : vocabItem?.value;
    return converted || item;
};

/**
 * Format time duration (in seconds) into HH:MM:SS
 * @param {number} duration - Duration value in seconds (number)
 * @returns {string} formatted duration
 */
export const formatDurationField = (duration) => {
    const formatTimeUnit = (unit) => {
        if (!unit) {
            return '00';
        }
        return unit.toString().length === 1 ? `0${unit}` : unit;
    };
    const { hours, minutes, seconds } = convertDurationTime(duration);
    return `${formatTimeUnit(hours)}:${formatTimeUnit(
        minutes
    )}:${formatTimeUnit(seconds)}`;
};

export const hasMobiusEntitlements = (authState) =>
    authState.entitlements?.mobius?.include?.access?.length > 0 &&
    authState.entitlements?.mobius?.include?.sourceId?.length > 0;

export const clearPresentationValues = (program) => {
    return {
        ...MOBIUS_PROGRAM_INITIAL_VALUES,
        [MOBIUS_PROGRAM_FIELDS.TYPE]: program?.[MOBIUS_PROGRAM_FIELDS.TYPE],
        [MOBIUS_PROGRAM_FIELDS.SUBTYPE]: program?.[MOBIUS_PROGRAM_FIELDS.SUBTYPE],
        versions: {
            ...program?.versions
        },
        publishPrereqs: null,
        versionGnID: program?.versionGnID
    };
}

export const verifySeasonDataIntegrity = (season, defaultLanguage) => {
    if (isEmpty(season)) {
        return {
            ...MOBIUS_SEASON_INITIAL_VALUES,
            descriptions: MOBIUS_INITIAL_DESCRIPTIONS.map((description) => ({
                ...description,
                language: get(defaultLanguage, MOBIUS_PROGRAM_FIELDS.VALUE, '')
            }))
        };
    }
    for (const [field, val] of Object.entries(MOBIUS_SEASON_INITIAL_VALUES)) {
        switch (field) {
        // Array of Object
        case MOBIUS_SEASON_FIELDS.DESCRIPTIONS:
            if (isEmpty(season[field])) {
                set(
                    season,
                    field,
                    MOBIUS_INITIAL_DESCRIPTIONS.map((desc) => ({
                        ...desc,
                        language: get(defaultLanguage, MOBIUS_PROGRAM_FIELDS.VALUE, '')
                    }))
                );
            } else {
                set(season, field, generateDescriptions(season[field]));
                season[field].forEach((_, idx) => {
                    updateFieldIfNotExist(season[field][idx], season, `${field}[${idx}]`, get(defaultLanguage, MOBIUS_PROGRAM_FIELDS.VALUE, ''));
                });
            }
            break;
        default:
            set(season, field, get(season, field) || val);
            break;
        }
    }
    return season;
};

export const clearVersionAndPresentationValues = (program) => {
    return {
        ...MOBIUS_PROGRAM_INITIAL_VALUES,
        [MOBIUS_PROGRAM_FIELDS.TYPE]: program?.[MOBIUS_PROGRAM_FIELDS.TYPE],
        [MOBIUS_PROGRAM_FIELDS.SUBTYPE]: program?.[MOBIUS_PROGRAM_FIELDS.SUBTYPE],
        publishPrereqs: null,
        rootGnID: program?.rootGnID
    };
}

export const sortOptionsByName = (options) => options?.sort((a, b) => (a.name > b.name) ? 1 : -1);

export const convertBooleanToText = (val) => val ? MOBIUS_BOOLEAN_TEXT.TRUE : MOBIUS_BOOLEAN_TEXT.FALSE;

export const isValidUrl = (url) => {
    try {
        // eslint-disable-next-line no-new
        new URL(url);
    } catch (e) {
        return false;
    }
    return true;
};