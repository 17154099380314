import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Formik } from 'formik';
// import './ProgramModalEdit.scss';
import './ProgramModal.scss';
import get from 'lodash.get';
import {
    COLOR_INITIAL_VALUE_BY_TYPE,
    MOBIUS_PROGRAM_FIELDS,
    MOBIUS_PROGRAM_INITIAL_TOUCHED,
    MOBIUS_PROGRAM_INITIAL_VALUES,
    MOBIUS_PROGRAM_TITLE_MAX
} from '../../constants/MobiusProgramValidation';
import {
    MOBIUS_BUTTON_TEXT,
    MOBIUS_LABELS,
    MOBIUS_NOTIFICATIONS,
    MOBIUS_PROGRAM_DEFAULT_SUBTYPE,
    MOBIUS_PROGRAM_TYPES_LABELS,
    MOBIUS_PROGRAM_TYPES_VALUES,
    MOBIUS_PROGRAM_SUBTYPES_VALUES,
    MOBIUS_REGISTER_PROGRAM_TYPE_OPTIONS,
    PROGRAM_SIDE_MENU,
    MOBIUS_TOOLTIPS,
    MOBIUS_VOCABULARIES_LIST,
    PUBLISH_MOVIE_PRE_REQS_FIELDS,
    PUBLISH_SHOW_AND_EPISODE_PRE_REQS_FIELDS,
    PUBLISH_SHOW_MINISERIES_PRE_REQS_FIELDS,
    MOBIUS_PROGRAM_EDIT_MODAL_IMAGE_HEADERS,
    MOBIUS_PROGRAM_PUBLISHING_STATUS
} from '../../constants/Mobius';
import {
    mobiusVocabSelCountries,
    mobiusVocabSelGenre,
    mobiusVocabSelGnvocab,
    mobiusVocabSelVersionLabels
} from '../../reducers/MobiusVocabReducer';
import GNInput from '../../components/common/gnInput/GNInput';
import GNDropdown from '../../components/common/gnDropdown/GNDropdown';
import TypeaheadFilter from '../../components/common/typeaheadFilter/TypeaheadFilter';
import PropTypes from 'prop-types';
import ExternalIDs from '../../components/mobius/externalIds/ExternalIDs';
import RatingsFields from '../../components/mobius/ratingFields/RatingsFields';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReleaseDetails from '../../components/mobius/releaseDetails/ReleaseDetails';
import DescriptionFields from '../../components/mobius/descriptionFields/DescriptionFields';
import Production from '../../components/mobius/production/Production';
import CastAndCrewFields from '../../components/mobius/castAndCrewFields/CastAndCrewFields';
import {
    convertVocab,
    getProgramSubTypeVocab,
    verifyDataIntegrity,
    getValidationSchema,
    verifyPublishPrereqs,
    linkImages
} from '../../utils/MobiusUtils';
import {
    mobiusRegisterNewPresentation,
    mobiusRegisterNewProgram,
    mobiusRegisterNewVersion,
    mobiusSaveProgram,
    gnidsClearEpisode,
    mobiusProgramImageMapping
} from '../../actions/MobiusActions';
import { gnviewSendLogMessage } from '../../services/GeneralService';
import GNNotification from '../../components/common/gnNotification/GNNotification';
import { gvauthHasUserSelectedMappingFeature, gvauthSelMobiusSourceID } from '../../reducers/GNVAuthReducer';
import ClassNames from 'classnames';
import LoadingSpinner from '../../components/common/loadingSpinner/LoadingSpinner';
import { gnidsSelSeasonGNID } from '../../reducers/GNIDSEpisodeReducer';
import PublishException from '../../components/mobius/publishException/PublishException';
import { clearProgramModalData, setBreadcrumbToEditMode, setGnidsProgram } from '../../actions/GNIDSProgramModalAction';
import GNButton from '../../components/common/gnButton/GNButton';
import ImagesFields from '../../components/mobius/imagesFields/ImagesFields';
import GNClientTable from '../../components/common/gnClientTable/GNClientTable';
import { setGnidsImage, showImageModal } from '../../actions/GNIDSImageModalActions';
import GNConfirmationModal from '../../components/common/gnConfirmationModal/GNConfirmationModal';
import { gnidsProgramImages } from '../../reducers/GNIDSProgramModalReducer';
import { generateErrorMessage } from '../../utils/ProgramUtils';
import Mappings from './Mappings';

export const ProgramModalEdit = ({
    allLanguages,
    handleCancel,
    program,
    isDirty,
    setIsDirty,
    isRegisterModal,
    handleModal,
    handleNotification,
    setRefreshProgram,
    setShowProgramModal,
    registerPresentationMode,
    registerVersionMode
}) => {
    const dispatch = useDispatch();
    const sourceId = useSelector(gvauthSelMobiusSourceID);
    const allMobiusVocabularies = useSelector(mobiusVocabSelGnvocab);
    const vocabAllGenres = useSelector(mobiusVocabSelGenre);
    const vocabAllVersionLabels = useSelector(mobiusVocabSelVersionLabels)?.map((versionLabels) => versionLabels.label);
    const vocabAllCountries = useSelector(mobiusVocabSelCountries);
    const vocablAllCountriesLabels = vocabAllCountries?.map((country) => country.label) || [];
    const [subtypeIsHidden, setSubtypeIsHidden] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [showFieldsInRedNotification, setShowFieldsInRedNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState(null);
    const [notificationIsSuccess, setNotificationIsSuccess] = useState(false);
    const [selectedProgramType, setSelectedProgramType] = useState(program?.type || 'movie');
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [inputPresentationLabelValue, setInputPresentationLabelValue] = useState(null);
    const [inputGenresValue, setInputGenresValue] = useState(null);
    const [inputVersionLabelValue, setInputVersionLabelValue] = useState(null);
    const [selectedPresentationLabels, setSelectedPresentationLabels] = useState(program?.[MOBIUS_PROGRAM_FIELDS.PRESENTATION_LABEL]?.filter((label) => label.length > 0) || null);
    const [selectedGenres, setSelectedGenres] = useState(program?.genres || null);
    const [selectedVersionLabels, setSelectedVersionLabels] = useState(program?.versions?.versionLabels || null);
    const [presentationLabelRef, setPresentationLabelRef] = useState(null);
    const [genresRef, setGenresRef] = useState(null);
    const [versionLabelRef, setVersionLabelRef] = useState(null);
    const isEpisode = program?.type === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE;
    const formattedRegisterProgramOptions = isEpisode ? [{ name: MOBIUS_PROGRAM_TYPES_LABELS.EPISODE, value: MOBIUS_PROGRAM_TYPES_LABELS.EPISODE }] : MOBIUS_REGISTER_PROGRAM_TYPE_OPTIONS;
    const defaultLanguage = allLanguages.find((lang) => lang.value === 'en') || allLanguages[0];
    const seasonGnID = useSelector(gnidsSelSeasonGNID);
    const [deletedImages, setDeletedImages] = useState([]);
    const [imagesTouched, setImagesTouched] = useState(false);
    const imagesData = useSelector(gnidsProgramImages);
    const [images, setImages] = useState(imagesData || []);
    const [showImageModalConfirm, setShowImageModalConfirm] = useState(false);
    const [selectedImageTitle, setSelectedImageTitle] = useState(null);
    const userSelectedMappingFeatureEnabled = useSelector(gvauthHasUserSelectedMappingFeature);

    // A "callback" to update the subtype dropdown after progType changes (mimicking setState({}, callback))
    useEffect(() => {
        setSubtypeIsHidden(false);
    }, [subtypeIsHidden]);

    const setNotification = (show, message, isSuccess) => {
        setShowNotification(show);
        setNotificationMsg(message);
        setNotificationIsSuccess(isSuccess);
    }

    const canRegisterProgram = (dirty, isValid) => dirty && isValid;

    const isPublishState = program?.publishingStatus === MOBIUS_PROGRAM_PUBLISHING_STATUS.REQUESTED || program?.publishingStatus === MOBIUS_PROGRAM_PUBLISHING_STATUS.PUBLISHED || program?.publishingStatus === MOBIUS_PROGRAM_PUBLISHING_STATUS.PUBLISHING;

    const getPublishPreReqs = (programType, programSubType) => {
        switch (programType) {
        case MOBIUS_PROGRAM_TYPES_VALUES.EPISODE:
        case MOBIUS_PROGRAM_TYPES_VALUES.SHOW:
            if (programSubType === MOBIUS_PROGRAM_SUBTYPES_VALUES.MINISERIES || programSubType === MOBIUS_PROGRAM_SUBTYPES_VALUES.SERIES) {
                return PUBLISH_SHOW_MINISERIES_PRE_REQS_FIELDS;
            } else {
                return PUBLISH_SHOW_AND_EPISODE_PRE_REQS_FIELDS;
            }
        default: return PUBLISH_MOVIE_PRE_REQS_FIELDS;
        }
    }
    const clearCastAndCrewEmptyFields = (registeredProgram) => {
        if (registeredProgram && registeredProgram?.cast && registeredProgram.cast.length > 1) {
            registeredProgram.cast = registeredProgram.cast?.filter((castObj, index, values) => {
                if (index < values.length) {
                    if (castObj.name.first !== '' || castObj.role !== '' || castObj.character !== '') {
                        return true;
                    }
                    return false;
                }
                return true;
            });
        }
        if (registeredProgram && registeredProgram?.crew && registeredProgram.crew.length > 1) {
            registeredProgram.crew = registeredProgram.crew?.filter((crewObj, index, values) => {
                if (index < values.length) {
                    if (crewObj.name.first !== '' || crewObj.type !== '') {
                        return true;
                    }
                    return false;
                }
                return true;
            });
        }
        return registeredProgram;
    }

    const onSaveRegisterButtonClick = (registeredProgram, dirty, imageTouched, isValid, setStatus, resetForm) => {
        // Existing program - Save
        const clearedRegisterProgram = clearCastAndCrewEmptyFields(registeredProgram);
        if (clearedRegisterProgram?.gnID && !isRegisterModal) {
            // edit form and image updates
            if (dirty && imageTouched) {
                setSaveInProgress(true);
                dispatch(mobiusSaveProgram(sourceId, clearedRegisterProgram, allMobiusVocabularies))
                    .then(() => {
                        dispatch(mobiusProgramImageMapping(sourceId, program?.gnID, images, deletedImages))
                            .then(() => {
                                setNotification(true, MOBIUS_NOTIFICATIONS.PROGRAM_SUCCESS_SAVED, true);
                                setSaveInProgress(false);
                                setRefreshProgram(true);
                                setIsDirty(false);
                                const filteredPublishPrereqs = verifyPublishPrereqs(getPublishPreReqs(clearedRegisterProgram.type, clearedRegisterProgram.subType));
                                setStatus(filteredPublishPrereqs);
                                resetForm({values: { ...clearedRegisterProgram, publishPrereqs: filteredPublishPrereqs }});
                                setImages(linkImages(images, true));
                                setImagesTouched(false);
                                setDeletedImages([]);
                            })
                            .catch((error) => {
                                const errorMessage = generateErrorMessage(error?.description || error?.message || error?.error);
                                dispatch(gnviewSendLogMessage(`mobiusSaveProgram error: ${errorMessage}`, error, program.gnID));
                                setNotification(true, errorMessage, false);
                                setSaveInProgress(false);
                            })
                    })
            // image updates
            } else if (!dirty && imageTouched) {
                setSaveInProgress(true);
                dispatch(mobiusProgramImageMapping(sourceId, program?.gnID, images, deletedImages))
                    .then(() => {
                        setNotification(true, MOBIUS_NOTIFICATIONS.PROGRAM_SUCCESS_SAVED, true);
                        setSaveInProgress(false);
                        setRefreshProgram(true);
                        setIsDirty(false);
                        setImages(linkImages(images, true));
                        setImagesTouched(false);
                        setDeletedImages([]);
                    }).catch((error) => {
                        const errorMessage = generateErrorMessage(error?.description || error?.message || error?.error);
                        dispatch(gnviewSendLogMessage(`mobiusSaveProgram error: ${errorMessage}`, error, program.gnID));
                        setNotification(true, errorMessage, false);
                        setSaveInProgress(false);
                    })
            // edit form updates
            } else if (dirty && !imageTouched) {
                setSaveInProgress(true);
                dispatch(mobiusSaveProgram(sourceId, clearedRegisterProgram, allMobiusVocabularies))
                    .then((savePrgmResp) => {
                        setNotification(true, MOBIUS_NOTIFICATIONS.PROGRAM_SUCCESS_SAVED, true);
                        setSaveInProgress(false);
                        setRefreshProgram(true);
                        setIsDirty(false);
                        if (program?.type === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE) {
                            dispatch(setGnidsProgram({
                                ...program,
                                ...(savePrgmResp?.[0] || {})
                            }));
                        } else {
                            const versionsResponse = savePrgmResp[0];
                            const presentationsResponse = savePrgmResp[1];
                            const { color, duration, releaseDate, releaseYear, versionLabels } = versionsResponse;
                            dispatch(setGnidsProgram({
                                ...presentationsResponse,
                                versions: {
                                    color,
                                    duration,
                                    releaseDate,
                                    releaseYear,
                                    versionLabels
                                }
                            }));
                        }
                        // Update publishPrereqs
                        const filteredPublishPrereqs = verifyPublishPrereqs(getPublishPreReqs(clearedRegisterProgram.type, clearedRegisterProgram.subType));
                        setStatus(filteredPublishPrereqs);
                        resetForm({values: { ...clearedRegisterProgram, publishPrereqs: filteredPublishPrereqs }});
                    }).catch((error) => {
                        dispatch(gnviewSendLogMessage(`mobiusSaveProgram error: ${error.message}`, error, program.gnID));
                        setNotification(true, MOBIUS_NOTIFICATIONS.PROGRAM_ERROR_SAVED, false);
                        setSaveInProgress(false);
                    })
            }
        } else if (isRegisterModal && (!clearedRegisterProgram?.gnID || registerPresentationMode || registerVersionMode)) { // New program, confirm they want to Register
            if (canRegisterProgram(dirty, isValid)) {
                handleRegisterConfirm(clearedRegisterProgram)
                setShowFieldsInRedNotification(false);
            } else {
                setShowFieldsInRedNotification(true);
            }
        }
    }

    const handleRegisterConfirm = (programToRegister) => {
        const getRegisterAction = () => {
            if (registerPresentationMode) {
                return mobiusRegisterNewPresentation;
            } else if (registerVersionMode) {
                return mobiusRegisterNewVersion;
            }
            return mobiusRegisterNewProgram;
        };
        dispatch(getRegisterAction()(sourceId, programToRegister, allMobiusVocabularies, seasonGnID))
            .then(() => {
                handleModal(programToRegister, false, null, null, false, true);
                handleNotification(true, MOBIUS_NOTIFICATIONS.PROGRAM_SUCCESS_REGISTERED, true);
                dispatch(clearProgramModalData());
                dispatch(gnidsClearEpisode());
            })
            .catch((error) => {
                handleModal(programToRegister, true, null, null, true);
                dispatch(gnviewSendLogMessage(`mobiusRegisterNewProgram error: `, error));
                setNotification(true, MOBIUS_NOTIFICATIONS.PROGRAM_ERROR_REGISTERED, false);
            });
    };

    const deleteImageFromList = (image) => {
        if (image.link) {
            setDeletedImages(deletedImagesList => [...deletedImagesList, image])
        }
        setImages(images.filter((obj) => obj.gnID !== image.gnID))
        setImagesTouched(true)
    }

    const imageTableColumns = [...MOBIUS_PROGRAM_EDIT_MODAL_IMAGE_HEADERS];

    const imageTableTitleIndex = imageTableColumns.findIndex((col) => col.id === 'title');
    if (imageTableTitleIndex !== -1) {
        imageTableColumns[imageTableTitleIndex] = {
            ...imageTableColumns[imageTableTitleIndex],
            /* eslint-disable react/display-name */
            /* eslint-disable react/prop-types */
            Cell: ({ row }) => (
                <span
                    className='image-title'
                    onClick={() => {
                        if (imagesTouched || isDirty) {
                            setSelectedImageTitle(row?.original)
                            setShowProgramModal(false)
                            setShowImageModalConfirm(true)
                        } else {
                            handleModal(null, false);
                            dispatch(setGnidsImage(row?.original))
                            dispatch(showImageModal(true))
                            dispatch(setBreadcrumbToEditMode(true))
                        }
                    }}
                >
                    {row?.original?.title}
                </span>
            )
            /* eslint-enable react/display-name */
            /* eslint-enable react/prop-types */
        };
    }

    const imageTableDeleteButtonIndex = imageTableColumns.findIndex((col) => col.id === 'deleteButton');
    if (imageTableDeleteButtonIndex !== -1) {
        imageTableColumns[imageTableDeleteButtonIndex] = {
            ...imageTableColumns[imageTableDeleteButtonIndex],
            /* eslint-disable react/prop-types */
            Cell: ({ row }) =>
                <OverlayTrigger
                    overlay={
                        <Tooltip id='delete-image-tooltip'>{MOBIUS_TOOLTIPS.DELETE_IMAGE_FIELD}</Tooltip>
                    }
                    placement='top'
                >
                    <div className='delete-image' onClick={() => deleteImageFromList(row.original)}>
                        <FontAwesomeIcon icon='trash-alt' className='fa-trash-alt'/>
                    </div>
                </OverlayTrigger>
                /* eslint-enable react/prop-types */
        }
    }
    return (
        <div className="program-modal-edit-container">
            <Formik
                initialValues={verifyDataIntegrity(program, defaultLanguage)}
                initialStatus={program && program?.gnID && !isPublishState ? verifyPublishPrereqs(getPublishPreReqs(program?.type, program?.subType)) : []}
                initialTouched={MOBIUS_PROGRAM_INITIAL_TOUCHED}
                validationSchema={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)}
                onSubmit={(values, { setStatus, resetForm }) => onSaveRegisterButtonClick(values, null, null, setStatus, resetForm)
                }
            >
                {({
                    dirty,
                    errors,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    resetForm,
                    setFieldTouched,
                    setFieldError,
                    setFieldValue,
                    setTouched,
                    setStatus,
                    touched,
                    status,
                    values
                }) => {
                    // Return errors only if we are submitting the form or the field has been touched
                    const validateForm = (formName) => {
                        return (isSubmitting || get(touched, formName)) ? !get(errors, formName) : true;
                    }
                    const handleFormChange = (formName, value) => {
                        setFieldValue(formName, value).then(() => {
                            if (!touched[formName]) {
                                setFieldTouched(formName, true);
                            }
                        })
                        // Let parent ProgramModal have updated dirty value
                        setIsDirty(dirty);

                        setFieldErrorMessage(formName);
                    };

                    const setFieldErrorMessage = (fieldName) => {
                        if (!get(errors, fieldName) && getPublishPreReqs(program?.type, program?.subType).some((field) => field.field === fieldName)) {
                            setFieldError(fieldName, MOBIUS_LABELS.REQUIRED_FOR_PUBLISH);
                        }
                    }


                    const handleOnBlurTypeaheads = (inputRef, inputValue, selectedItems, fieldName, setSelectedItems, setInputValue) => {
                        if (inputValue) {
                            let formattedItems = selectedItems === null ? [] : selectedItems;
                            formattedItems = [...formattedItems, inputValue];
                            handleFormChange(fieldName, formattedItems);
                            setSelectedItems(formattedItems);
                            setInputValue('');
                        }
                    }

                    return (
                        <Form noValidate onSubmit={handleSubmit}>
                            <div className='edit-mode-container'>
                                {saveInProgress && <LoadingSpinner />}
                                {program?.publishExceptions &&
                                    <PublishException
                                        publishExceptions={program?.publishExceptions}
                                    />
                                }
                                <div className='overview-section' id={PROGRAM_SIDE_MENU.OVERVIEW.id}>
                                    <div className='program-modal-tab-header'>
                                        {MOBIUS_LABELS.OVERVIEW}
                                        <OverlayTrigger overlay={<Tooltip>{MOBIUS_TOOLTIPS.OVERVIEW_INFO}</Tooltip>} placement="right">
                                            <FontAwesomeIcon icon='circle-info' />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='program-modal-tab-body'>
                                        <div className='type-section'>
                                            <GNDropdown
                                                disabled={(program?.gnID !== undefined) || isEpisode || registerPresentationMode || registerVersionMode}
                                                handleChange={(item) => {
                                                    // When the user changes the type of the program, it'll reset the fields and make them not red
                                                    setTouched(MOBIUS_PROGRAM_INITIAL_TOUCHED);
                                                    setShowFieldsInRedNotification(false);
                                                    const convertedProgramType = convertVocab(allMobiusVocabularies, item, MOBIUS_VOCABULARIES_LIST.PROGRAM_TYPE, false);
                                                    setSelectedProgramType(convertedProgramType);
                                                    handleFormChange(MOBIUS_PROGRAM_FIELDS.TYPE, convertedProgramType);
                                                    handleFormChange(MOBIUS_PROGRAM_FIELDS.SUBTYPE, MOBIUS_PROGRAM_DEFAULT_SUBTYPE[convertedProgramType] || "");
                                                    handleFormChange(MOBIUS_PROGRAM_FIELDS.VERSIONS_COLOR, COLOR_INITIAL_VALUE_BY_TYPE(convertedProgramType));
                                                    setSubtypeIsHidden(true);
                                                }}
                                                isRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.TYPE]?.exclusiveTests?.required}
                                                label={MOBIUS_LABELS.PROGRAM_TYPE}
                                                options={formattedRegisterProgramOptions}
                                                validateInput={() => validateForm(MOBIUS_PROGRAM_FIELDS.TYPE)}
                                                value={values?.type ? convertVocab(allMobiusVocabularies, values.type, MOBIUS_VOCABULARIES_LIST.PROGRAM_TYPE) : null}
                                            />
                                            {!subtypeIsHidden && values?.type && <GNDropdown
                                                disabled={(program?.gnID !== undefined) || registerPresentationMode || registerVersionMode}
                                                handleChange={(item) => handleFormChange(MOBIUS_PROGRAM_FIELDS.SUBTYPE, item)}
                                                isRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.SUBTYPE]?.exclusiveTests?.required}
                                                label={MOBIUS_LABELS.PROGRAM_SUBTYPE}
                                                options={
                                                    getProgramSubTypeVocab(
                                                        allMobiusVocabularies[MOBIUS_VOCABULARIES_LIST.PROGRAM_TYPE],
                                                        values.type
                                                    )?.map((subtype) => ({
                                                        name: subtype.label,
                                                        value: subtype.value
                                                    })) || []
                                                }
                                                validateInput={() => validateForm(MOBIUS_PROGRAM_FIELDS.SUBTYPE)}
                                                value={values?.subType} />}
                                            <TypeaheadFilter
                                                addLabelKey={MOBIUS_LABELS.ADD_VERSION_LABELS}
                                                allowNew={true}
                                                className="version-labels-typeahead"
                                                disabled={isEpisode || registerPresentationMode}
                                                domRef={versionLabelRef}
                                                filterLabel={MOBIUS_LABELS.VERSION_LABELS}
                                                onInputChange={(val) => {
                                                    setInputVersionLabelValue(val);
                                                }}
                                                handleChange={(versionLabels) => {
                                                    const formattedVersionLabels = versionLabels.map((versionLabel) => versionLabel?.label || versionLabel);
                                                    handleFormChange(MOBIUS_PROGRAM_FIELDS.VERSION_LABELS, formattedVersionLabels);
                                                    if (formattedVersionLabels?.length === 0) {
                                                        const versionLabelsInput = document.querySelector('.version-labels-typeahead input');
                                                        if (versionLabelsInput) {
                                                            versionLabelsInput.blur();
                                                        }
                                                    }

                                                    setSelectedVersionLabels(formattedVersionLabels);
                                                    setInputVersionLabelValue('');
                                                }}
                                                handleBlur={() => {
                                                    handleOnBlurTypeaheads(versionLabelRef, inputVersionLabelValue, selectedVersionLabels, MOBIUS_PROGRAM_FIELDS.VERSION_LABELS, setSelectedVersionLabels, setInputVersionLabelValue);
                                                }}
                                                inherited={isEpisode}
                                                isRequired={true}
                                                list={vocabAllVersionLabels}
                                                multiple={true}
                                                placeholder={MOBIUS_LABELS.VERSION_LABELS}
                                                ref={(ref) => {
                                                    setVersionLabelRef(ref);
                                                }}
                                                selected={values?.versions?.versionLabels}
                                                validationText={errors?.versions?.versionLabels}
                                                validateTypeahead={() => validateForm(MOBIUS_PROGRAM_FIELDS.VERSION_LABELS)} />
                                            <TypeaheadFilter
                                                allowNew={true}
                                                className='presentation-labels-typeahead'
                                                disabled={isEpisode}
                                                domRef={presentationLabelRef}
                                                filterLabel={MOBIUS_LABELS.PRESENTATION_LABELS}
                                                handleChange={(item) => {
                                                    const formattedPresentationLabels = item.map((presentationLabel) => presentationLabel?.label || presentationLabel);
                                                    handleFormChange(MOBIUS_PROGRAM_FIELDS.PRESENTATION_LABEL, formattedPresentationLabels);

                                                    setSelectedPresentationLabels(formattedPresentationLabels);
                                                    setInputPresentationLabelValue('');
                                                }}
                                                handleBlur={() => {
                                                    handleOnBlurTypeaheads(presentationLabelRef, inputPresentationLabelValue, selectedPresentationLabels, MOBIUS_PROGRAM_FIELDS.PRESENTATION_LABEL, setSelectedPresentationLabels, setInputPresentationLabelValue);
                                                }}
                                                inherited={isEpisode}
                                                isRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.PRESENTATION_LABEL]?.exclusiveTests?.required}
                                                list={[]}
                                                multiple={true}
                                                onInputChange={(val) => {
                                                    setInputPresentationLabelValue(val);
                                                }}
                                                placeholder={MOBIUS_LABELS.PRESENTATION_LABELS}
                                                ref={(ref) => {
                                                    setPresentationLabelRef(ref);
                                                }}
                                                selected={values?.[MOBIUS_PROGRAM_FIELDS.PRESENTATION_LABEL]?.filter((label) => label.length > 0) || []}
                                                validateTypeahead={() => validateForm(MOBIUS_PROGRAM_FIELDS.PRESENTATION_LABEL)}
                                                validationText={errors[MOBIUS_PROGRAM_FIELDS.PRESENTATION_LABEL]}
                                            />
                                        </div>
                                        <div className='title-section'>
                                            <GNInput
                                                handleChange={(item) => handleFormChange(MOBIUS_PROGRAM_FIELDS.TITLE_VALUE, item)}
                                                isRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.TITLE]?.fields[MOBIUS_PROGRAM_FIELDS.VALUE]?.exclusiveTests?.required}
                                                label={isEpisode ? MOBIUS_LABELS.EPISODE_TITLE : MOBIUS_LABELS.TITLE}
                                                placeholder={isEpisode ? MOBIUS_LABELS.EPISODE_TITLE : MOBIUS_LABELS.TITLE}
                                                textAreaMax={MOBIUS_PROGRAM_TITLE_MAX}
                                                type="textarea"
                                                validateInput={() => validateForm(MOBIUS_PROGRAM_FIELDS.TITLE_VALUE)}
                                                validationText={errors?.title?.valueOf}
                                                value={values?.title?.value} />
                                            <GNDropdown
                                                handleChange={(item) => handleFormChange(MOBIUS_PROGRAM_FIELDS.TITLE_LANGUAGE_CODE, item)}
                                                isRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.TITLE]?.fields[MOBIUS_PROGRAM_FIELDS.LANGUAGE]?.exclusiveTests?.required}
                                                isAlphabeticallySorted
                                                label={isEpisode ? MOBIUS_LABELS.EPISODE_TITLE_LANGUAGE : MOBIUS_LABELS.TITLE_LANGUAGE}
                                                options={allLanguages}
                                                validateInput={() => validateForm(MOBIUS_PROGRAM_FIELDS.TITLE_LANGUAGE_CODE)}
                                                value={values?.title?.language} />
                                        </div>
                                        <TypeaheadFilter
                                            addLabelKey={MOBIUS_LABELS.ADD_GENRES}
                                            allowNew={true}
                                            className="genres-typeahead"
                                            domRef={genresRef}
                                            filterLabel={MOBIUS_LABELS.GENRES}
                                            onInputChange={(val) => {
                                                setInputGenresValue(val);
                                            }}
                                            handleChange={(genres) => {
                                                const formattedGenres = genres.map(genre => genre?.label ? genre.label : genre);
                                                handleFormChange(MOBIUS_PROGRAM_FIELDS.GENRES, formattedGenres);

                                                setSelectedGenres(formattedGenres);
                                                setInputGenresValue('');
                                            }}
                                            handleBlur={() => {
                                                handleOnBlurTypeaheads(genresRef, inputGenresValue, selectedGenres, MOBIUS_PROGRAM_FIELDS.GENRES, setSelectedGenres, setInputGenresValue);
                                            }}
                                            isRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.GENRES]?.exclusiveTests?.required}
                                            list={vocabAllGenres}
                                            multiple={true}
                                            placeholder={MOBIUS_LABELS.GENRES}
                                            ref={(ref) => {
                                                setGenresRef(ref);
                                            }}
                                            selected={values?.genres}
                                            validationText={errors?.genres}
                                            validateTypeahead={() => validateForm(MOBIUS_PROGRAM_FIELDS.GENRES)} />
                                    </div>
                                </div>
                                <div className='program-modal-section' id={PROGRAM_SIDE_MENU.RELEASE_DETAILS.id}>
                                    <div className='program-modal-tab-header'>
                                        {MOBIUS_LABELS.RELEASE_DETAILS}
                                        <OverlayTrigger overlay={<Tooltip>{MOBIUS_TOOLTIPS.RELEASE_INFO}</Tooltip>} placement="right">
                                            <FontAwesomeIcon icon='circle-info' />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='program-modal-tab-body'>
                                        <ReleaseDetails
                                            errors={errors}
                                            status={status}
                                            handleFormChange={handleFormChange}
                                            isDurationRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.DURATION]?.exclusiveTests?.required}
                                            isOriginalSourceRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.ORIGINAL_SOURCE]?.exclusiveTests?.required}
                                            isColorRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.VERSIONS]?.fields[MOBIUS_PROGRAM_FIELDS.COLOR]?.exclusiveTests?.required}
                                            isFinaleDateRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.FINALE_DATE]?.exclusiveTests?.required}
                                            isPresentationReleaseDateRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.PRESENTATION_RELEASE_DATE]?.exclusiveTests?.required}
                                            isPresentationReleaseYearRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.PRESENTATION_RELEASE_YEAR]?.exclusiveTests?.required}
                                            isPublishState={isPublishState}
                                            isVersionReleaseDateRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.VERSIONS]?.fields[MOBIUS_PROGRAM_FIELDS.PRESENTATION_RELEASE_DATE]?.exclusiveTests?.required}
                                            isVersionReleaseYearRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.VERSIONS]?.fields[MOBIUS_PROGRAM_FIELDS.PRESENTATION_RELEASE_YEAR]?.exclusiveTests?.required}
                                            isPartNumbersRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.PART_NUMBERS]?.exclusiveTests?.required}
                                            isTargetAudienceRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.TARGET_AUDIENCE]?.exclusiveTests?.required}
                                            isEpisodeNumberRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.EPISODE_NUMBER]?.exclusiveTests?.required}
                                            isIndustryNetworkNumberRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.INDUSTRY_NETWORK_NUMBER]?.exclusiveTests?.required}
                                            program={values}
                                            registerPresentationMode={registerPresentationMode}
                                            validateForm={validateForm} />
                                    </div>
                                </div>
                                <div className='program-modal-section' id={PROGRAM_SIDE_MENU.EXTERNAL_IDS.id}>
                                    <div className='program-modal-tab-header'>
                                        {MOBIUS_LABELS.EXTERNAL_IDS}
                                        <OverlayTrigger overlay={<Tooltip>{MOBIUS_TOOLTIPS.EXTERNAL_ID_INFO}</Tooltip>} placement="right">
                                            <FontAwesomeIcon icon='circle-info' />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='program-modal-tab-body'>
                                        <ExternalIDs
                                            externalIDs={values?.externalIDs || []}
                                            handleFormChange={handleFormChange}
                                            isRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.EXTERNAL_IDS]?.exclusiveTests?.required}
                                            validateForm={validateForm}
                                            validationText={errors.externalIDs} />
                                    </div>
                                </div>
                                {userSelectedMappingFeatureEnabled && <div className={ClassNames('program-modal-section')} id={PROGRAM_SIDE_MENU.MAPPINGS.id}>
                                    <div className='program-modal-tab-header'>
                                        {MOBIUS_LABELS.MAPPINGS}
                                        <OverlayTrigger overlay={<Tooltip>{MOBIUS_TOOLTIPS.MAPPINGS_INFO}</Tooltip>} placement="right">
                                            <FontAwesomeIcon icon='circle-info' />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='program-modal-tab-body'>
                                        <Mappings validateForm={validateForm} values={values} handleFormChange={handleFormChange} errors={errors} />
                                    </div>
                                </div>}
                                <div className='program-modal-section' id={PROGRAM_SIDE_MENU.RATINGS.id}>
                                    <div className='program-modal-tab-header'>
                                        {MOBIUS_LABELS.RATINGS}
                                        <OverlayTrigger overlay={<Tooltip>{MOBIUS_TOOLTIPS.RATING_INFO}</Tooltip>} placement="right">
                                            <FontAwesomeIcon icon='circle-info' />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='program-modal-tab-body'>
                                        <RatingsFields
                                            handleFormChange={handleFormChange}
                                            isRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.RATINGS]?.exclusiveTests?.required}
                                            ratings={values?.[MOBIUS_PROGRAM_FIELDS.RATINGS] || []}
                                            errors={errors}
                                            values={values}
                                            status={status}
                                            validateForm={validateForm} />
                                    </div>
                                </div>
                                <div className='program-modal-section' id={PROGRAM_SIDE_MENU.DESCRIPTIONS.id}>
                                    <div className='program-modal-tab-header'>
                                        {MOBIUS_LABELS.DESCRIPTIONS}
                                        <OverlayTrigger overlay={<Tooltip>{MOBIUS_TOOLTIPS.DESCRIPTION_INFO}</Tooltip>} placement="right">
                                            <FontAwesomeIcon icon='circle-info' />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='program-modal-tab-body'>
                                        <DescriptionFields
                                            allLanguages={allLanguages}
                                            defaultLanguage={defaultLanguage}
                                            descriptions={get(values, MOBIUS_PROGRAM_FIELDS.DESCRIPTIONS)}
                                            errors={errors[MOBIUS_PROGRAM_FIELDS.DESCRIPTIONS]}
                                            status={status}
                                            handleFormChange={handleFormChange}
                                            isRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.DESCRIPTIONS]?.exclusiveTests?.required}
                                            validateForm={validateForm} />
                                    </div>
                                </div>
                                <div className='program-modal-section' id={PROGRAM_SIDE_MENU.PRODUCTION.id}>
                                    <div className='program-modal-tab-header'>
                                        {MOBIUS_LABELS.PRODUCTION}
                                        <OverlayTrigger overlay={<Tooltip>{MOBIUS_TOOLTIPS.PRODUCTION_INFO}</Tooltip>} placement="right">
                                            <FontAwesomeIcon icon='circle-info' />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='program-modal-tab-body'>
                                        <Production
                                            isMovie={selectedProgramType?.toLowerCase() === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE}
                                            errors={errors}
                                            handleFormChange={handleFormChange}
                                            productionCompanies={
                                                values?.[MOBIUS_PROGRAM_FIELDS.PRODUCTION_COMPANIES]?.length > 0
                                                    ? values[MOBIUS_PROGRAM_FIELDS.PRODUCTION_COMPANIES]
                                                    : MOBIUS_PROGRAM_INITIAL_VALUES[MOBIUS_PROGRAM_FIELDS.PRODUCTION_COMPANIES]
                                            }
                                            productionCompaniesIsRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.PRODUCTION_COMPANIES]?.exclusiveTests?.required}
                                            productionCountries={
                                                !values?.productionCountries
                                                    ? []
                                                    : values?.productionCountries.map((country) =>
                                                        convertVocab(
                                                            allMobiusVocabularies,
                                                            country,
                                                            MOBIUS_VOCABULARIES_LIST.COUNTRIES
                                                        )
                                                    )
                                            }
                                            productionCountriesIsRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.PRODUCTION_COUNTRIES]?.exclusiveTests?.required}
                                            status={status}
                                            validateForm={validateForm}
                                            GNVocabCountries={vocablAllCountriesLabels}
                                            value={values.productionCountries}
                                            productionStatus={values?.[MOBIUS_PROGRAM_FIELDS.PRODUCTION_STATUS]}
                                            productionStatusIsRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.PRODUCTION_STATUS]?.exclusiveTests?.required}
                                        />
                                    </div>
                                </div>
                                <div className='program-modal-section' id={PROGRAM_SIDE_MENU.CAST.id}>
                                    <div className='program-modal-tab-header'>
                                        {MOBIUS_LABELS.CAST}
                                        <OverlayTrigger overlay={<Tooltip>{MOBIUS_TOOLTIPS.CAST_INFO}</Tooltip>} placement="right">
                                            <FontAwesomeIcon icon='circle-info' />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='program-modal-tab-body'>
                                        <CastAndCrewFields
                                            errors={errors.cast}
                                            handleFormChange={handleFormChange}
                                            isCast={true}
                                            isRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.CAST]?.exclusiveTests?.required}
                                            members={values?.cast || []}
                                            validateForm={validateForm} />
                                    </div>
                                </div>
                                <div className={ClassNames('program-modal-section', { 'last-section': isRegisterModal })} id={PROGRAM_SIDE_MENU.CREW.id}>
                                    <div className='program-modal-tab-header'>
                                        {MOBIUS_LABELS.CREW}
                                        <OverlayTrigger overlay={<Tooltip>{MOBIUS_TOOLTIPS.CREW_INFO}</Tooltip>} placement="right">
                                            <FontAwesomeIcon icon='circle-info' />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='program-modal-tab-body'>
                                        <CastAndCrewFields
                                            errors={errors.crew}
                                            handleFormChange={handleFormChange}
                                            isCast={false}
                                            isRequired={getValidationSchema(selectedProgramType, program?.publishingStatus, program?.subType)?.fields[MOBIUS_PROGRAM_FIELDS.CREW]?.exclusiveTests?.required}
                                            members={values?.crew || []}
                                            validateForm={validateForm} />
                                    </div>
                                </div>
                                {!isRegisterModal && program?.gnID &&
                                <div className='program-modal-section images' id={PROGRAM_SIDE_MENU.IMAGES.id}>
                                    <div className='program-modal-tab-header'>
                                        {MOBIUS_LABELS.IMAGES}
                                        <OverlayTrigger overlay={<Tooltip>{MOBIUS_TOOLTIPS.IMAGE_SECTION}</Tooltip>} placement="right">
                                            <FontAwesomeIcon icon='circle-info' />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='program-modal-tab-body'>
                                        <ImagesFields
                                            images={images}
                                            setImages={setImages}
                                            deletedImages={deletedImages}
                                            setDeletedImages={setDeletedImages}
                                            validateForm={validateForm}
                                            setImagesTouched={setImagesTouched}
                                        />
                                        {images?.length !== 0 &&
                                                <GNClientTable
                                                    className='image-table'
                                                    columns={imageTableColumns}
                                                    data={images}
                                                    pagination={false}
                                                />
                                        }
                                    </div>
                                </div>
                                }
                            </div>
                            <div className='bottom-section'>
                                {canRegisterProgram(dirty, isValid) && !program?.gnID && (
                                    <div className='register-requirements-msg'>
                                        {MOBIUS_LABELS.REGISTER_REQUIREMENTS_COMPLETED}
                                    </div>
                                )}
                                {showFieldsInRedNotification && !canRegisterProgram(dirty, isValid) && !program?.gnID && (
                                    <div className='revise-fields-red-msg'>
                                        {MOBIUS_LABELS.PLEASE_REVISE_FIELDS_IN_RED}
                                    </div>
                                )}
                                <div className='button-container'>
                                    <Button
                                        className='cancel-button'
                                        variant='light'
                                        onClick={() =>
                                            handleCancel(!notificationIsSuccess ? dirty || imagesTouched : false)
                                        }
                                    >
                                        {MOBIUS_BUTTON_TEXT.CANCEL}
                                    </Button>
                                    <GNButton
                                        buttonClass={ClassNames('register-button')}
                                        tooltip={<Tooltip>{MOBIUS_TOOLTIPS.MUST_UPDATE_FIELD}</Tooltip>}
                                        tooltipPlacement='top'
                                        {...(!(isRegisterModal || !program?.gnID) && { disabled: !(isValid && (imagesTouched || dirty)) })}
                                        type='submit'
                                        variant='primary'
                                        onClick={() => onSaveRegisterButtonClick(values, dirty, imagesTouched, isValid, setStatus, resetForm)}>
                                        {isRegisterModal || !program?.gnID
                                            ? MOBIUS_BUTTON_TEXT.REGISTER
                                            : MOBIUS_BUTTON_TEXT.SAVE}
                                    </GNButton>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
            {showNotification && <GNNotification handleShow={setShowNotification} message={notificationMsg} milliseconds={5000} show={showNotification} success={notificationIsSuccess} />}
            <GNConfirmationModal
                cancelButtonHandler={() => {
                    setShowImageModalConfirm(false)
                    setShowProgramModal(true)
                }}
                cancelButtonText={MOBIUS_BUTTON_TEXT.CANCEL}
                message={'Any unsaved changes will be lost.'}
                show={showImageModalConfirm}
                submitButtonHandler={() => {
                    handleModal(null, false);
                    dispatch(showImageModal(true))
                    dispatch(setGnidsImage(selectedImageTitle))
                    dispatch(setBreadcrumbToEditMode(true))
                }}
                submitButtonText={'Proceed without saving'}
                title={'Proceed to view image details?'}
            />
        </div>
    )
};

ProgramModalEdit.propTypes = {
    allLanguages: PropTypes.array,
    handleCancel: PropTypes.func,
    handleModal: PropTypes.func,
    handleNotification: PropTypes.func,
    isDirty: PropTypes.bool,
    isRegisterModal: PropTypes.bool,
    program: PropTypes.object,
    setIsDirty: PropTypes.func,
    setShowProgramModal: PropTypes.func,
    setRefreshProgram: PropTypes.func,
    registerPresentationMode: PropTypes.bool,
    registerVersionMode: PropTypes.bool
};

export default ProgramModalEdit;