import React, { useState, useEffect } from 'react';
import { MOBIUS_LABELS, MOBIUS_TOOLTIPS } from '../../../constants/Mobius'
import './ExternalIDs.scss';
import PropTypes from 'prop-types';
import { MOBIUS_PROGRAM_FIELDS, EXTERNAL_ID_INITIAL_VALUE } from '../../../constants/MobiusProgramValidation';
import uniqId from 'uniqid';
import GNInput from '../../common/gnInput/GNInput';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ProgramModalAddButton from '../programModalAddButton/ProgramModalAddButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ExternalIDs = ({ externalIDs, handleFormChange, isRequired, validateForm, validationText }) => {
    const [allExternalIds, setAllExternalIds] = useState([...externalIDs]);
    if (externalIDs.length === 1 || allExternalIds.length === 1) {
        externalIDs[0].isPrimary = true;
    }

    useEffect(() => {
        setAllExternalIds([...externalIDs]);
    }, [externalIDs, setAllExternalIds]);

    const handleNewExternalId = () => {
        handleFormChange(MOBIUS_PROGRAM_FIELDS.EXTERNAL_IDS, [...externalIDs, {...EXTERNAL_ID_INITIAL_VALUE, uniqKey: uniqId()}]);
    };

    const handleClearExternalId = (index) => {
        const filteredExternalIds = [...externalIDs].filter((i, idx) => idx !== index);
        // make first in list primary if deleted index was primary (because primary is required)
        if (externalIDs[index].isPrimary) {
            filteredExternalIds[0].isPrimary = true;
        }
        handleFormChange(MOBIUS_PROGRAM_FIELDS.EXTERNAL_IDS, filteredExternalIds);
    };

    const handleNewPrimary = (index) => {
        const currentPrimary = externalIDs.findIndex((id) => id.isPrimary);
        if (currentPrimary !== -1 && index !== currentPrimary) {
            handleFormChange(MOBIUS_PROGRAM_FIELDS.EXTERNAL_IDS_PRIMARY(currentPrimary), false);
            handleFormChange(MOBIUS_PROGRAM_FIELDS.EXTERNAL_IDS_PRIMARY(index), true);
        }
    };

    const handleNewProvider = (index) => {
        const currentProvider = externalIDs.findIndex((id) => id.isProvider);
        if (currentProvider !== -1) {
            if (index !== currentProvider) {
                handleFormChange(MOBIUS_PROGRAM_FIELDS.EXTERNAL_IDS_PROVIDER(currentProvider), false);
                handleFormChange(MOBIUS_PROGRAM_FIELDS.EXTERNAL_IDS_PROVIDER(index), true);
            } else {
                handleFormChange(MOBIUS_PROGRAM_FIELDS.EXTERNAL_IDS_PROVIDER(index), !externalIDs[index].isProvider);
            }
        } else {
            handleFormChange(MOBIUS_PROGRAM_FIELDS.EXTERNAL_IDS_PROVIDER(index), true);
        }
    };

    return (
        <div className='mobius-external-ids-main-container'>
            <div className='external-id-form-headers'>
                <span className='external-id-label'>{MOBIUS_LABELS.EXTERNAL_ID_LABEL}</span>
                <span className='external-id'>{MOBIUS_LABELS.EXTERNAL_ID}</span>
                <span className='primary'>{MOBIUS_LABELS.EXTERNAL_ID_PRIMARY}</span>
                <span className='provider'>{MOBIUS_LABELS.EXTERNAL_ID_PROVIDER}</span>
            </div>
            {allExternalIds.map((externalId, idx) => {
                const isLast = idx === allExternalIds.length - 1;
                const isOnlyExternalId = allExternalIds.length === 1;
                const newFieldIsFilled =
                    externalId?.label?.length > 0 && externalId?.id?.length > 0;
                return (
                    <div className='external-id-single-form-container' key={idx}>
                        <GNInput
                            className='external-id-label'
                            handleChange={(item) => handleFormChange(MOBIUS_PROGRAM_FIELDS.EXTERNAL_IDS_LABEL(idx), item)}
                            isRequired={isRequired}
                            placeholder={MOBIUS_LABELS.EXTERNAL_ID_LABEL}
                            validateInput={() => validateForm(MOBIUS_PROGRAM_FIELDS.EXTERNAL_IDS_LABEL(idx))}
                            validationText={
                                Array.isArray(validationText)
                                    ? validationText[idx]?.label
                                    : validationText
                            }
                            value={externalId?.label}
                        />
                        <GNInput
                            className='external-id-value'
                            handleChange={(item) => handleFormChange(MOBIUS_PROGRAM_FIELDS.EXTERNAL_IDS_VALUE(idx), item)}
                            isRequired={isRequired}
                            placeholder={MOBIUS_LABELS.EXTERNAL_ID}
                            validateInput={() => validateForm(MOBIUS_PROGRAM_FIELDS.EXTERNAL_IDS_VALUE(idx))}
                            validationText={
                                Array.isArray(validationText)
                                    ? validationText[idx]?.id
                                    : validationText
                            }
                            value={externalId?.id}
                        />
                        <div className='primary-provider-radio-section'>
                            <Form.Check
                                custom
                                inline
                                name='primary'
                                id={`custom-radio-primary-${idx}`}
                                type='radio'
                                onClick={() => handleNewPrimary(idx)}
                                checked={isOnlyExternalId || externalId.isPrimary}
                            />
                            <Form.Check
                                custom
                                inline
                                name='provider'
                                id={`custom-radio-provider-${idx}`}
                                type='radio'
                                onClick={() => handleNewProvider(idx)}
                                checked={externalId.isProvider}
                            />
                        </div>
                        {!isOnlyExternalId && (
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id='clear-ext-id-tooltip'>
                                        {MOBIUS_TOOLTIPS.CLEAR_EXTERNAL_ID}
                                    </Tooltip>
                                }
                                placement='bottom'
                            >
                                <div
                                    className='clear-external-id-button'
                                    onClick={() => handleClearExternalId(idx)}
                                >
                                    <FontAwesomeIcon icon='trash-alt' />
                                </div>
                            </OverlayTrigger>
                        )}
                        {isLast && (
                            <ProgramModalAddButton
                                enabled={newFieldIsFilled}
                                onButtonClick={handleNewExternalId}
                                tooltipPlacement='right'
                                tooltipText={MOBIUS_TOOLTIPS.ADD_EXTERNAL_ID}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    )
};

ExternalIDs.propTypes = {
    addNewExternalId: PropTypes.func,
    clearExternalId: PropTypes.func,
    externalIDs: PropTypes.array.isRequired,
    handleFormChange: PropTypes.func.isRequired,
    isRequired: PropTypes.bool.isRequired,
    selectNewPrimary: PropTypes.func,
    selectNewProvider: PropTypes.func,
    validateForm: PropTypes.func.isRequired,
    validationText: PropTypes.array
};

export default ExternalIDs;