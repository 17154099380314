import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './GNGetHelp.scss';
import ClassNames from 'classnames';
import { MENU_CONTENTS } from '../../../constants/GetHelp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const GNGetHelp = () => {
    const [showHelpMenu, setShowHelpMenu] = useState(false);

    const renderLinkMenuItem = (item, index) => (
        <Link to={item.url} key={index} onClick={() => setShowHelpMenu(false)} className={ClassNames('menu-item-container', { 'horizontal-lines': item.title === "What's New!" })}>
            <FontAwesomeIcon icon={item.icon} />
            <span className='item-title'>{item.title}</span>
        </Link>
    );

    return (
        <div className="get-help-btn-container">
            {showHelpMenu && <div className={ClassNames("get-help-menu", "gnids")}>
                {MENU_CONTENTS.map((item, index) => {
                    if (!item?.newTab) {
                        return renderLinkMenuItem(item, index);
                    } else {
                        return (
                            <a href={item.url} target="_blank" rel="noreferrer" onClick={() => setShowHelpMenu(false)} className={ClassNames('menu-item-container')} key={item.path}>
                                <FontAwesomeIcon icon={item.icon} />
                                <span className='item-title'>{item.title}</span>
                            </a>
                        );
                    }
                })}
            </div>}
            <div className="button-container">
                <Button className={ClassNames("get-help-button", "gnids", { 'menu-open': showHelpMenu })}
                    onClick={() => setShowHelpMenu(!showHelpMenu)}>
                    <FontAwesomeIcon icon='circle-question' /> Get Help
                </Button>
            </div>
        </div>
    )
};

export default GNGetHelp;