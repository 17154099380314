import {unflatten} from 'flat';
import Papa from 'papaparse';
import { MOBIUS_PROGRAM_SUBTYPES_LOWERCASE_MAPPINGS, MOBIUS_PROGRAM_TYPES_VALUES } from '../constants/Mobius';
// given size in bytes, return 10MB etc
export const formatFileSize = (size) => {
    if (isNaN(size)) {
        size = 0;
    }
    const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return Math.round(size / Math.pow(1024, i)) + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};


function isArrayofStrings(arr) {
    return Array.isArray(arr) && arr.every(item => typeof item === 'string');
}

function removeEmptyFields(obj) {
    // iterate over all the fields in the object
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const value = obj[key];
            if (value === '') {
                delete obj[key];
            } else if (Array.isArray(value) && isArrayofStrings(value)) {
            // filter out the values in the array that are empty
                obj[key] = value.filter(arrElement => arrElement.trim() !== '');
                // if the array is empty, we can remove the entire field
                if (obj[key].length === 0) {
                    delete obj[key];
                }
            } else if (typeof value === 'object' && key !== 'parent') {
                // if the type is an object, then we need to traverse all the fields inside the object
                removeEmptyFields(value);
                // if the object is empty, we can delete it
                if (value === null || Object.keys(value).length === 0) {
                    delete obj[key];
                }
            }
        }
    }
}
// this is needed because we use the delete operation, it leaves behind an undefined value
function removeUndefinedVals (flatObj) {
    for (const key in flatObj) {
        if (Array.isArray(flatObj[key])) {
            flatObj[key] = flatObj[key].filter(v => v)
        }
    }
}

export const convertSubTypeValue = (rows) => {
    rows.forEach((row) => {
        // checks if the program's subtype without case sensitivity matches the accepted vocab
        // eslint-disable-next-line no-prototype-builtins
        if (row['subType'] && MOBIUS_PROGRAM_SUBTYPES_LOWERCASE_MAPPINGS.hasOwnProperty(row['subType']?.toLowerCase())) {
            row['subType'] = MOBIUS_PROGRAM_SUBTYPES_LOWERCASE_MAPPINGS[row['subType']?.toLowerCase()];
        }
    })
    return rows;
}

export const parseCSVRow = (row) => {
    const flatObj = unflatten(row);
    removeEmptyFields(flatObj)
    removeUndefinedVals(flatObj)
    return flatObj;
}

export const isRowEmpty = (row) => {
    const rowLength = Object.keys(row).length;
    if (rowLength === 1) {
        if (row?.parent?.rowID?.length < 1 && row?.parent?.GnID?.length < 1) {
            return true;
        }
    }
    return false;
}

export const parseFiles = (files, onComplete, onHeaderErrors, onRowErrors) => {
    const file = files[0];
    const parsedRows = [];
    let headerErrors = [];
    let headers = [];
    let rows = [];
    let newCsvString = '';
    if (file) {
        file.text().then((csv) => {
            // Create a new CSV string with the header row and the data rows
            const { data } = Papa.parse(csv, {})
            headers = data[3]
            rows = data.splice(3)
            headerErrors = validateHeader(headers, rows);
            newCsvString = Papa.unparse(rows)
            Papa.parse(newCsvString,
                {
                    header: true,
                    comments: '#',
                    // putting full params here
                    // step: (results, parser)
                    step: (results) => {
                        const row = parseCSVRow(results.data);
                        if (!isRowEmpty(row)) {
                            parsedRows.push(row);
                        }
                    },
                    // putting full params here
                    // complete: (results, file)
                    complete: () => {
                        // Because step is defined(and therefore we are streaming), the results won't be here we need to save the data ourselves
                        if (headerErrors?.length > 0) {
                            onHeaderErrors(headerErrors)
                        } else if (headerErrors?.length === 0) {
                            const rowValidationErrors = validateRows(parsedRows);
                            if (rowValidationErrors?.length > 0) {
                                onRowErrors(rowValidationErrors);
                            } else {
                                onComplete(convertSubTypeValue(parsedRows), file);
                            }
                        }
                    }
                }
            );
        }).catch((error) => console.error(error))
    }
}


/**
* Get the rowID from the context property at the specified path in the object.
* @param {object} data - The object to traverse.
* @param {string} path - The path to the desired property.
* @returns {string|null} - The rowID or null if not found.
*/
export const getRowIDByPath = (data, path) => {
    const keys = path.split('.');

    let current = data;
    for (const key of keys) {
        const match = key.match(/(.+?)\[(\d+)]/);
        if (match) {
            const prop = match[1];
            const index = parseInt(match[2]);
            current = current[prop] ? current[prop][index] : null;
        } else {
            current = current[key];
        }

        if (!current) {
            return null;
        }
    }

    return current.context ? current.context.rowID : null;
};

const requiredHeaderFields = [
    'rowID',
    'parent.rowID',
    'parent.GnID',
    'programType',
    'subType',
    'operationType'
]

const headersForShowOnly = [
    'season.releaseDate',
    'season.seasonNumber',
    'season.nonSeason',
    'season.ordinal',
    'episode.releaseDate',
    'episode.episodeNumber',
    'episode.ordinal',
    'episode.partNumbers',
    'episode.industryNetworkNumber',
    'episode.industryNetworkSyndicated'
];

const generalHeaderFields = [
    'rowID',
    'programType',
    'subType',
    'operationType',
    'parent.rowID',
    'parent.GnID',
    'title.value',
    'title.language',
    'duration',
    'originalSource',
    'color',
    'finaleDate',
    'selfMappedTmsId',
    'version.releaseDate',
    'version.releaseYear',
    'presentation.releaseDate',
    'presentation.releaseYear',
    'productionStatus',
    'targetAudience',
    ...headersForShowOnly
]

const regexPatternHeaderFields = [
    'versionLabels.#',
    'presentationLabels.#',
    'genres.#',
    'descriptions.#.language',
    'descriptions.#.length',
    'descriptions.#.value',
    'externalIDs.#.label',
    'externalIDs.#.id',
    'externalIDs.#.isPrimary',
    'externalIDs.#.isProvider',
    'productionCompanies.#',
    'productionCountries.#',
    'ratings.#.ratingBody',
    'ratings.#.rating',
    'ratings.#.ratingExempt',
    'ratings.#.notYetRated',
    'ratings.#.advisories.#',
    'cast.#.role',
    'cast.#.character',
    'cast.#.name.prefix',
    'cast.#.name.first',
    'cast.#.name.middle',
    'cast.#.name.last',
    'cast.#.name.suffix',
    'cast.#.aka.prefix',
    'cast.#.aka.first',
    'cast.#.aka.middle',
    'cast.#.aka.last',
    'cast.#.aka.suffix',
    'cast.#.order',
    'crew.#.type',
    'crew.#.name.prefix',
    'crew.#.name.first',
    'crew.#.name.middle',
    'crew.#.name.last',
    'crew.#.name.suffix',
    'crew.#.aka.prefix',
    'crew.#.aka.first',
    'crew.#.aka.middle',
    'crew.#.aka.last',
    'crew.#.aka.suffix',
    'crew.#.order'
]

export const generateHeaderRegexPatterns = (dynamicPatternFields) => {
    const regexPatterns = dynamicPatternFields.map((field) => {
        // Escape any special characters in the field
        const escapedField = field.replace(/[#.]/g, '\\$&');

        // Replace '#' with a regex pattern for a positive integer
        // eslint-disable-next-line no-useless-escape
        const pattern = escapedField.replace(/#/g, '\d+');

        // Wrap the pattern in ^ and $ to ensure exact matching
        return `^${pattern}$`;
    });

    return regexPatterns;
}

export const validateHeaderUsingRegex = (headerString, regexPatterns) => {
    const matchesPattern = regexPatterns.some((pattern) => new RegExp(pattern).test(headerString))
    return matchesPattern
}

const errorObject = {
    type: '',
    columnName: '',
    rowID: ''
}

function findIndexesOfRepeatedHeaders(arr) {
    const indexMap = {};
    const repeatedIndexes = {};

    arr.forEach((string, index) => {
        // map the string to it's index if we haven't already
        if (indexMap[string] === undefined) {
            indexMap[string] = index;
        } else if (!repeatedIndexes[string]) {
            repeatedIndexes[string] = [indexMap[string], index];
        } else {
            repeatedIndexes[string].push(index);
        }
    });

    return repeatedIndexes;
}


function checkForValueUnderColumn(rows, col) {
    // start the row from 1 to ignore the header row
    for (let row = 1; row < rows.length; row++) {
        if (rows[row][col]?.trim() !== '') {
            return true;
        }
    }
    return false;
}
export function checkForRepeatedFields(headers, rows, headerErrors) {
    let repeatedHeaderWithRowData = 0;
    const repeatedHeaders = findIndexesOfRepeatedHeaders(headers)
    for (const header in repeatedHeaders) {
        if (Object.prototype.hasOwnProperty.call(repeatedHeaders, header)) {
            const repeatedHeaderIndices = repeatedHeaders[header]
            repeatedHeaderWithRowData = 0;
            for (let i = 0; i < repeatedHeaderIndices.length; i++) {
                if (checkForValueUnderColumn(rows, repeatedHeaderIndices[i])) {
                    repeatedHeaderWithRowData += 1;
                }
                if (repeatedHeaderWithRowData >= 2) {
                    headerErrors.push({...errorObject, type: `Repeated columns: We are only accepting 1 column`, columnName: `${header}`});
                    break;
                }
            }
        }
    }
    return headerErrors;
}

export const validateHeader = (headers, rows) => {
    const headerArray = headers;
    let errors = [];
    const headerArrayLength = headerArray.length;
    if (headerArrayLength > 1000) {
        errors.push({...errorObject, type: 'Max number of rows allowed is 1000'})
    }
    const programTypeIndex = headers.indexOf('programType');
    const invalidHeaders = []
    if ((programTypeIndex !== -1) && (rows?.[1]?.[programTypeIndex]?.toLowerCase() === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE)) {
        for (const invalidHeader of headersForShowOnly) {
            if (headers.includes(invalidHeader)) {
                errors.push({...errorObject, type: `Invalid header ${invalidHeader} for Program Type Movie`});
                invalidHeaders.push(invalidHeader)
            }
        }
    }
    for (const requiredHeader of requiredHeaderFields) {
        const valid = headerArray.includes(requiredHeader);
        if (!valid) {
            errors.push({...errorObject, type: `Missing required header(s) - please refer to template`})
            break;
        }
    }
    const regexPatterns = generateHeaderRegexPatterns(regexPatternHeaderFields);
    for (const header of headerArray) {
        const valid = generalHeaderFields.includes(header) || validateHeaderUsingRegex(header, regexPatterns);
        if (!valid && header.trim() !== '') {
            errors.push({...errorObject, type: `We don't recognize this header '${header}'`})
            invalidHeaders.push(header)
        }
    }
    const remaningHeaders = headers.filter(header => !invalidHeaders.includes(header))
    errors = checkForRepeatedFields(remaningHeaders, rows, errors)
    return errors
}

const duplicateRow = (rows, thisRowCount, thisRowID) => {
    let count = 1;
    let foundDuplicateRow = -1;
    rows.forEach((row) => {
        if (row.rowID === thisRowID && count !== thisRowCount) {
            foundDuplicateRow = row.rowID;
        }
        count++;
    });
    return foundDuplicateRow;
}

const fetchParentRow = (rows, parentId) => {
    const parentRow = rows.find(row => row.rowID === parentId);
    if (!parentRow) {
        return false;
    } else {
        return parentRow;
    }
}

export const validateRows = (rows) => {
    const invalidRows = [];
    const rowTypes = [];
    let rowNumber = 1;
    let parentRow = null;
    rows.forEach((row) => {
        let reportRowNumber = rowNumber;
        if (row.operationType) {
            row.operationType = row.operationType.trim();
        }
        if (row.programType) {
            row.programType = row.programType.trim();
        }
        if (row.rowID) { // use the rowId if it exists, otherwise, we're stuck with counting rows
            reportRowNumber = row.rowID;
        }
        const requiredColumns = ['rowID', 'programType', 'subType', 'operationType'];
        requiredColumns.forEach((column) => {
            if (!row[column]) {
                invalidRows.push({type: "Value Missing", columnName: column, rowID: reportRowNumber});
            }
        });

        if (row.externalIDs) {
            validateExternalIds(row.externalIDs, invalidRows, reportRowNumber)
        }

        if (row.ratings) {
            validateRatings(row.ratings, invalidRows, reportRowNumber)
        }

        if (!['register', 'registerVersion', 'registerPresentation', 'registerSeason', 'registerEpisode'].includes(row.operationType)) {
            invalidRows.push({type: "operationType is not one the valid values", columnName: "operationType", rowID: reportRowNumber});
        }
        if (row.parent.rowID) {
            parentRow = fetchParentRow(rows, row.parent.rowID);
            if (!parentRow) {
                invalidRows.push(
                    {type: "parent.rowID does not exist", columnName: "parent.rowID", rowID: reportRowNumber}
                );
            }
        } else {
            parentRow = false;
        }
        if (row.operationType === 'registerPresentation' && parentRow) {
            if (parentRow.operationType !== 'register' && parentRow.operationType !== 'registerVersion') {
                invalidRows.push({type: "operationType=registerPresentation and parent.rowID should have operationType=register OR registerVersion", columnName: "parent.rowID", rowID: reportRowNumber});
            }
        }
        if (row.operationType === 'registerVersion' && parentRow) {
            if (parentRow.operationType !== 'register') {
                invalidRows.push({type: "operationType=registerVersion and parent.rowID should have operationType=register", columnName: "parent.rowID", rowID: reportRowNumber});
            }
        }
        if (row.operationType === 'registerSeason' && parentRow) {
            if (parentRow.operationType !== 'register' && parentRow.operationType !== 'registerVersion' && parentRow.operationType !== 'registerPresentation') {
                invalidRows.push({type: "operationType=registerSeason and parent.rowID should have operationType=register OR registerVersion OR registerPresentation", columnName: "parent.rowID", rowID: reportRowNumber});
            }
        }
        if (row.operationType !== 'register' && (row.parent.rowID === '' && row.parent.GnID === '')) {
            invalidRows.push({type: "Must have a parent.rowID or parent.GnID when operationType is not 'register'", columnName: "parent.rowID", rowID: reportRowNumber});
        }
        if (row.operationType === 'registerEpisode' && parentRow) {
            if (parentRow.operationType !== 'registerSeason') {
                invalidRows.push({type: "operationType=registerEpisode and parent.rowID should have operationType=registerSeason", columnName: "parent.rowID", rowID: reportRowNumber});
            }
        }
        const thisRowDuplicates = duplicateRow(rows, rowNumber, row.rowID);
        if (thisRowDuplicates !== -1) {
            invalidRows.push({type: "RowId must be unique", columnName: "rowID", rowID: reportRowNumber});
        }
        if (row.programType !== '' && (row.programType?.toLowerCase() !== MOBIUS_PROGRAM_TYPES_VALUES.MOVIE && row.programType?.toLowerCase() !== MOBIUS_PROGRAM_TYPES_VALUES.SHOW)) {
            invalidRows.push({type: "'" + row.programType + "' is not a valid programType", columnName: "programType", rowID: reportRowNumber});
        }
        if (row.programType && !rowTypes.includes(row.programType?.toLowerCase())) {
            rowTypes.push(row.programType?.toLowerCase());
        }
        rowNumber++;
    });
    if (rowTypes.includes(MOBIUS_PROGRAM_TYPES_VALUES.MOVIE) && (rowTypes.includes(MOBIUS_PROGRAM_TYPES_VALUES.SHOW) || rowTypes.includes(MOBIUS_PROGRAM_TYPES_VALUES.SEASON) || rowTypes.includes(MOBIUS_PROGRAM_TYPES_VALUES.EPISODE))) {
        if (rowTypes[0] === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE) {
            let movieRowId = -1;
            movieRowId = rows.find(row => row.programType?.toLowerCase() !== MOBIUS_PROGRAM_TYPES_VALUES.MOVIE).rowID;
            invalidRows.push({type: "Movies can only be uploaded with other programs of type=movie", columnName: "rowID", rowID: movieRowId});
        } else {
            let showRowId = -1;
            showRowId = rows.find(row => row.programType?.toLowerCase() !== MOBIUS_PROGRAM_TYPES_VALUES.SHOW || row.programType?.toLowerCase() !== MOBIUS_PROGRAM_TYPES_VALUES.SEASON || row.programType?.toLowerCase() !== MOBIUS_PROGRAM_TYPES_VALUES.EPISODE).rowID;
            invalidRows.push({type: "Shows can only be uploaded with other programs of type=show,season,episode", columnName: "rowID", rowID: showRowId});
        }
    }
    return invalidRows;
}

export function validateRatings(ratings, invalidRows, reportRowNumber) {
    ratings.forEach((rating, index) => {
        // if the ratingExempt/notYetRated fields are specified, then make sure they are either true or false
        if (rating?.ratingExempt && !['true', 'false'].includes(rating?.ratingExempt?.toLowerCase())) {
            invalidRows.push({type: `Invalid value '${rating?.ratingExempt}' must be True or False`, columnName: `ratings.[${index + 1}].ratingExempt`, rowID: reportRowNumber});
        }
        if (rating?.notYetRated && !['true', 'false'].includes(rating?.notYetRated?.toLowerCase())) {
            invalidRows.push({type: `Invalid value '${rating?.notYetRated}' must be True or False`, columnName: `ratings.[${index + 1}].notYetRated`, rowID: reportRowNumber});
        }
    });
}

export function validateExternalIds(externalIDs, invalidRows, reportRowNumber) {
    externalIDs.forEach((externalId, index) => {
        if (externalId?.isPrimary && !['true', 'false'].includes(externalId?.isPrimary?.toLowerCase())) {
            invalidRows.push({type: `Invalid value '${externalId?.isPrimary}' must be True or False`, columnName: `externalIDs.[${index + 1}].isPrimary`, rowID: reportRowNumber});
        }
        if (externalId?.isProvider && !['true', 'false'].includes(externalId?.isProvider?.toLowerCase())) {
            invalidRows.push({type: `Invalid value '${externalId?.isProvider}' must be True or False`, columnName: `externalIDs.[${index + 1}].isProvider`, rowID: reportRowNumber});
        }
    });
}
export const ERROR_MODAL_COLUMNS = [
    {
        disableSortBy: true,
        id: 'error',
        accessor: 'type',
        Header: 'ERROR'
    },
    {
        disableSortBy: true,
        id: 'columnName',
        accessor: 'columnName',
        Header: 'COLUMN NAME'
    },
    {
        disableSortBy: true,
        id: 'row',
        accessor: 'rowID',
        Header: 'ROW ID'
    }
]

/**
 * Merges a list of objects based on 'type' and 'columnName' fields.
 * For objects with the same 'type' and 'columnName', it concatenates their 'rowID'.
 * Replaces empty or missing 'columnName' and 'rowID' with '-'.
 *
 * @param {Array} arr - The array of objects to merge.
 * @returns {Array} - The merged array of objects.
 */
export function mergeErrors(arr) {
    const totalErrors = Object.values(arr.reduce((acc, obj) => {
        const key = obj.type + "|" + (obj.columnName || '-');
        const rowIDToAdd = (obj.columnName === 'rowID' && obj.rowID) ? obj.rowID + 4 : obj.rowID || '-';

        if (!acc[key]) {
            acc[key] = {
                ...obj,
                columnName: obj.columnName || '-',
                rowID: new Set([rowIDToAdd])
            };
        } else {
            acc[key].rowID.add(rowIDToAdd);
        }

        return acc;
    }, {}));

    return totalErrors.map(err => ({
        ...err,
        type: (err.columnName === 'rowID' && [...err.rowID].join(', ') !== '-') ? `${err.type} (row numbers: ${[...err.rowID].join(', ')})` : err.type,
        rowID: (err.columnName === 'rowID') ? '-' : [...err.rowID].join(', ')
    }));
}

export const ERROR_FIELD_TO_JSON_HEADER_MAPPING = {
    "type": "ERROR",
    "columnName": "COLUMN NAME",
    "rowID": "ROW ID"
};
