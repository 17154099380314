import moment from 'moment';
import async from 'async';
import { API_ENDPOINTS } from '../config/Api';
import apiService from '../services/ApiService';
import {
    MOBIUS_ADD_GNVOCABULARY_IN_PROGRESS,
    MOBIUS_ADD_GNVOCABULARY_SUCCESS,
    MOBIUS_ADD_GNVOCABULARY_FAIL,
    MOBIUS_ADD_ENTITLEMENTS,
    MOBIUS_ADD_ENTITLEMENTS_FAIL,
    GNIDS_UPDATE_EPISODE,
    GNIDS_CLEAR_EPISODE,
    GNIDS_TABLE_POPOVER_TOGGLE,
    GNIDS_TABLE_POPOVER_TOGGLE_ALL,
    MOBIUS_ADD_CATALOGS_SUCCESS,
    MOBIUS_ADD_CATALOGS_FAIL
} from './ActionTypes';
import {
    MOBIUS_PROGRAM_TYPES_VALUES,
    MOBIUS_PROGRAM_SUBTYPES_LABELS,
    MOBIUS_PROGRAM_SUBTYPES_VALUES,
    MOBIUS_VOCABULARIES_LIST
} from '../constants/Mobius';
import { convertVocab, convertRegisterProgramToPost, convertProgramImageForMapping } from '../utils/MobiusUtils';
import { convertSeasonForPost } from '../utils/SeasonUtils';
import { transformImageData } from '../utils/ImageUtils';
import { transformCatalogsData } from '../utils/CatalogUtils';
import { MOBIUS_PROGRAM_FILTER_TYPES } from '../constants/MobiusListView';

export const mobiusGetAllPrograms = (
    allMobiusVocabularies,
    pageIndex,
    sortBy,
    startAfter,
    filters,
    programTitleSearch,
    sourceId,
    parentGNID,
    seasonIds,
    catalogFilter
) => {
    return () => {
        const subtypes =
            filters
                ?.filter((f) => f.category === MOBIUS_PROGRAM_FILTER_TYPES.SUBTYPE && f.selected)
                .map((f) =>
                    sourceId?.[0]?.length > 0
                        ? convertVocab(
                            allMobiusVocabularies,
                            f.subtype,
                            MOBIUS_VOCABULARIES_LIST.PROGRAM_TYPE,
                            false,
                            true,
                            f.type
                        )
                        : f.subtype
                ) || [];
        const statuses =
            filters
                ?.filter((f) => f.category === MOBIUS_PROGRAM_FILTER_TYPES.STATUS && f.selected)
                .map((f) => f.status) || [];

        if (sourceId?.[0]) {
            const headers = { SourceID: sourceId[0] };

            const types = [MOBIUS_PROGRAM_TYPES_VALUES.MOVIE, MOBIUS_PROGRAM_TYPES_VALUES.SHOW]

            if (catalogFilter?.length > 0) {
                types.push(MOBIUS_PROGRAM_TYPES_VALUES.EPISODE)
            }

            const params = {
                limit: 20,
                page: pageIndex + 1,
                // catalogselexted and feature for catalogs then add episode type
                type: types,
                ...(sortBy?.length > 0 && { sortField: sortBy[0]?.id }),
                ...(sortBy?.length > 0 && { sortDirection: sortBy[0]?.desc ? 'desc' : 'asc' }),
                ...(subtypes?.length > 0 && { subType: subtypes }),
                ...(statuses?.length > 0 && { publishStatus: statuses }),
                ...(catalogFilter?.length > 0 && { catalogGnIDs: [catalogFilter] }),
                ...(programTitleSearch?.length > 0 && { title: programTitleSearch })
            };
            return apiService
                .makeApiCall(
                    API_ENDPOINTS.GET_GNIDS_PROGRAM_SUMMARIES,
                    params,
                    'get',
                    false,
                    headers
                )
                .then((response) => {
                    if (catalogFilter?.length > 0) {
                        const shows = {}
                        // iterate over all the programs. First find all the shows and save them in a map
                        for (const program of response?.result?.data) {
                            if (program.type === 'show' && !(program.showPresentationGnID in shows)) {
                                shows[program.showPresentationGnID] = [];
                            }
                        }
                        // find all the episodes in the response that reside in the shows we found
                        for (const program of response?.result?.data) {
                            if (program.type === 'episode' && (program.showPresentationGnID in shows)) {
                                shows[program.showPresentationGnID].push(program.presentationGnID)
                            }
                        }

                        // of all the shows we found, if they don't have any episodes, then the expansion is not possible
                        response.result.data = response?.result?.data?.map((program) => ({
                            ...program,
                            canExpand: program.subType === MOBIUS_PROGRAM_SUBTYPES_VALUES.SERIES && (program.showPresentationGnID in shows)
                        }));

                        // filter out the episodes that have a showpresentationGNID present in shows, this means we don't need to show the flattened view of the episodes
                        response.result.data = response.result.data.filter((program) => {
                            return (!((program.type === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE && (program.showPresentationGnID in shows))))
                        })
                    } else {
                        response.result.data = response?.result?.data?.map((program) => ({
                            ...program,
                            canExpand: program.subType === MOBIUS_PROGRAM_SUBTYPES_VALUES.SERIES
                        }));
                    }
                    return response;
                });
        } else {
            const body = {
                source_id: sourceId?.[0] || null,
                limit: 20,
                page: pageIndex + 1,
                program_types: [MOBIUS_PROGRAM_TYPES_VALUES.MOVIE, MOBIUS_PROGRAM_TYPES_VALUES.SHOW],
                sort: {
                    field: sortBy[0]?.id,
                    direction: sortBy[0]?.desc ? 'desc' : 'asc'
                },
                searchString: programTitleSearch ? programTitleSearch : null,
                // For Mobius API
                parent_gn_id: parentGNID ? parentGNID : null,
                season_ids: seasonIds ? seasonIds : null,
                start_after: startAfter[pageIndex] ? startAfter[pageIndex] : null,
                program_subtypes: subtypes.length > 0 ? subtypes : null,
                statuses: statuses.length > 0 ? statuses : null
            };
            return apiService
                .makeApiCall(API_ENDPOINTS.GET_MOBIUS_MOCK_ALL_PROGRAMS, body, 'get')
                .then((response) => {
                    if (response?.result) {
                        response.result = response.result.map((program) => ({
                            ...program,
                            canExpand: program.subType === MOBIUS_PROGRAM_SUBTYPES_LABELS.SERIES
                        }));
                    }

                    return response;
                });
        }
    };
};

export const mobiusGetAllImages = (
    pageIndex,
    sourceId,
    filters,
    sortBy
) => {
    const aspectRatios =
        filters
            ?.filter((f) => f.selected)
            .filter(f => f.aspectRatio)
            .map((f) => f.aspectRatio) || [];

    const categories =
        filters
            ?.filter((f) => f.selected)
            .filter(f => f.category)
            .map((f) => f.category) || [];

    return async () => {
        if (sourceId?.[0]) {
            const headers = { SourceID: sourceId[0] };
            const params = {
                limit: 20,
                page: pageIndex + 1,
                ...(aspectRatios?.length > 0 && { aspectRatio: aspectRatios }),
                ...(categories?.length > 0 && { category: categories }),
                ...(sortBy?.length > 0 && { sortField: sortBy[0]?.id }),
                ...(sortBy?.length > 0 ? { sortDirection: sortBy[0]?.desc ? 'desc' : 'asc' } : { sortDirection: 'desc'})
            };
            const response = await apiService
                .makeApiCall(
                    API_ENDPOINTS.GET_MOBIUS_IMAGES,
                    params,
                    'get',
                    false,
                    headers
                );
            response.result.data = response?.result?.data?.map(transformImageData);
            return response;
        } else {
            const body = {
                source_id: null,
                limit: 20,
                page: pageIndex + 1
            };
            const response = await apiService
                .makeApiCall(API_ENDPOINTS.GET_MOBIUS_IMAGES, body, 'get');
            response.result.data = response?.result?.data?.map(transformImageData);
            return response;
        }
    };
};

export const mobiusGetAllCatalogs = (
    pageIndex,
    sourceId,
    sortBy,
    limit
) => {
    return async (dispatch) => {
        if (sourceId?.[0]) {
            const headers = { SourceID: sourceId[0] };
            const params = {
                limit,
                page: limit ? pageIndex + 1 : null,
                ...(sortBy?.length > 0 && { sortField: sortBy[0]?.id }),
                ...(sortBy?.length > 0 && { sortDirection: sortBy[0]?.desc ? 'desc' : 'asc' })
            };
            const response = await apiService
                .makeApiCall(
                    API_ENDPOINTS.GET_MOBIUS_CATALOGS,
                    params,
                    'get',
                    false,
                    headers
                );
            response.result.data = response?.result?.data?.map(transformCatalogsData);
            dispatch(mobiusAddCatalogsSuccess(response.result.data));
            return response;
        } else {
            const body = {
                source_id: null,
                limit: 20,
                page: pageIndex + 1,
                sort: {
                    field: sortBy[0]?.id,
                    direction: sortBy[0]?.desc ? 'desc' : 'asc'
                }
            };
            const response = await apiService
                .makeApiCall(API_ENDPOINTS.GET_MOBIUS_CATALOGS, body, 'get');
            response.result.data = response?.result?.data?.map(transformCatalogsData);
            dispatch(mobiusAddCatalogsSuccess(response.result.data));
            return response;
        }
    };
};

export const mobiusAddGNVocabularyInProgress = () => ({
    type: MOBIUS_ADD_GNVOCABULARY_IN_PROGRESS
});

export const gnidsPopoverModalToggle = (gnID, data) => ({
    type: GNIDS_TABLE_POPOVER_TOGGLE,
    payload: { gnID, data }
});

export const gnidsPopoverModalToggleAll = () => ({
    type: GNIDS_TABLE_POPOVER_TOGGLE_ALL
});

export const mobiusAddGNVocabularySuccess = (gnvocabulary) => ({
    type: MOBIUS_ADD_GNVOCABULARY_SUCCESS,
    payload: { gnvocabulary }
});

export const mobiusAddGNVocabularyFail = (data) => ({
    type: MOBIUS_ADD_GNVOCABULARY_FAIL,
    payload: { data }
});

export const mobiusAddCatalogsSuccess = (catalogs) => ({
    type: MOBIUS_ADD_CATALOGS_SUCCESS,
    payload: { catalogs }
});

export const mobiusAddCatalogsFail = (data) => ({
    type: MOBIUS_ADD_CATALOGS_FAIL,
    payload: { data }
});

export const mobiusAddEntitlements = (data) => ({
    type: MOBIUS_ADD_ENTITLEMENTS,
    payload: data
});

export const mobiusAddEntitlementsFail = (data) => ({
    type: MOBIUS_ADD_ENTITLEMENTS_FAIL,
    payload: data
});

export const mobiusGetGNVocabulary = (sourceId) => {
    return (dispatch) => {
        dispatch(mobiusAddGNVocabularyInProgress());
        const headers = { SourceID: sourceId?.[0] };
        return apiService
            .makeApiCall(`${API_ENDPOINTS.GET_GNIDS_GNVOCABULARY}`, {}, 'get', false, headers)
            .then(
                (response) => {
                    const gnvocabulary = response?.result?.data;
                    dispatch(mobiusAddGNVocabularySuccess(gnvocabulary));
                    return gnvocabulary;
                },
                (error) => {
                    const data = error?.response?.data || {};
                    dispatch(mobiusAddGNVocabularyFail(data));
                    throw error;
                }
            );
    };
};

export const mobiusGetEntitlements = (sourceId) => {
    return (dispatch) => {
        const id = sourceId?.[0];
        return apiService
            .makeApiCall(
                `${API_ENDPOINTS.ENTITLEMENTS_SOURCES}/${id}/${API_ENDPOINTS.ENTITLEMENTS_EXPRESSION}`,
                {},
                'get',
                false
            )
            .then(
                (response) => {
                    dispatch(mobiusAddEntitlements(response?.result?.entitlementExpression));
                    return response?.result?.entitlementExpression;
                },
                (error) => {
                    const data = error?.response?.data || {};
                    dispatch(mobiusAddEntitlementsFail(data));
                    throw error;
                }
            );
    };
};

export const mobiusGetProgram = (gnID, sourceId, programType, versionGnId) => {
    return async () => {
        const headers = { SourceID: sourceId?.[0] };
        const endpoint =
            programType === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE
                ? API_ENDPOINTS.GET_GNIDS_MOVIES
                : API_ENDPOINTS.GET_GNIDS_SHOWS;

        const versionsResponse = await apiService.makeApiCall(
            `${endpoint}/versions/${versionGnId}`,
            {},
            'get',
            false,
            headers
        );
        const presentationsResponse = await apiService.makeApiCall(
            `${endpoint}/versions/presentations/${gnID}`,
            {},
            'get',
            false,
            headers
        );
        const {
            result: {
                data: { color, duration, releaseDate, releaseYear, versionLabels }
            }
        } = versionsResponse;
        return {
            ...presentationsResponse?.result?.data,
            versions: {
                color,
                duration,
                releaseDate,
                releaseYear,
                versionLabels
            }
        };
    };
};


export const mobiusGetSeason = (seasonGnID, sourceId) => {
    return async () => {
        const headers = { SourceID: sourceId?.[0] };

        const seasonResponse = await apiService.makeApiCall(
            `${API_ENDPOINTS.GET_GNIDS_SHOWS}/versions/presentations/seasons/${seasonGnID}`,
            {},
            'get',
            false,
            headers
        );
        return seasonResponse?.result?.data;
    };
};

export const mobiusSaveSeason = (sourceId, season) => {
    return async () => {
        if (!sourceId?.length || !sourceId) {
            return new Promise((reject) => {
                reject('No sourceId provided');
            });
        }
        const convertedSeason = convertSeasonForPost(season);
        const headers = { SourceID: sourceId?.[0] };
        const endpoint = API_ENDPOINTS.GET_GNIDS_SHOWS;
        const seasonGnId = season.gnID;
        return apiService.makeApiCall(
            `${endpoint}/versions/presentations/seasons/${seasonGnId}`,
            convertedSeason,
            'put',
            false,
            headers
        );
    };
};

export const mobiusRegisterNewProgram = (sourceId, program, allMobiusVocabularies, seasonGnID = null) => {
    return async () => {
        if (!sourceId?.length || !sourceId) {
            return new Promise((reject) => {
                reject('No sourceId provided');
            });
        }
        const {
            programType,
            castType,
            color,
            crewType,
            countries
        } = allMobiusVocabularies;
        const convertedProgram = convertRegisterProgramToPost(
            program,
            programType,
            castType,
            color,
            crewType,
            countries
        );
        const headers = { SourceID: sourceId?.[0] };
        const baseUrl = convertedProgram.type === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE ? API_ENDPOINTS.GET_GNIDS_MOVIES : API_ENDPOINTS.GET_GNIDS_SHOWS;
        const callsToMake = [];
        const registerRootMovieOrShow = async (cb) => {
            const rootBody = {
                title: convertedProgram.title,
                subType: convertedProgram.subType
            };
            return apiService.makeApiCall(`${baseUrl}`, rootBody, 'post', false, headers)
                .then((res) => {
                    cb(null, res?.result?.data?.gnID);
                })
                .catch((error) => {
                    cb(error);
                });
        };
        const registerVersionCall = async (rootGnId, cb) => {
            return registerVersion(baseUrl, headers, rootGnId, convertedProgram, cb);
        };
        const registerPresentationCall = async (versionGnId, cb) => {
            return registerPresentation(baseUrl, headers, versionGnId, convertedProgram, cb);
        };
        const postEpisode = async (cb) => {
            const episodeBody = {
                subType: convertedProgram.subType,
                title: convertedProgram.title,
                cast: convertedProgram.cast,
                crew: convertedProgram.crew,
                descriptions: convertedProgram.descriptions,
                externalIDs: convertedProgram.externalIDs,
                genres: convertedProgram.genres,
                originalSource: convertedProgram.originalSource,
                productionCompanies: convertedProgram.productionCompanies,
                productionCountries: convertedProgram.productionCountries,
                ratings: convertedProgram.ratings,
                releaseDate: convertedProgram.presentationReleaseDate,
                targetAudience: convertedProgram.targetAudience,
                // Episode specific
                color: convertedProgram.color,
                duration: convertedProgram.duration,
                episodeNumber: convertedProgram.episodeNumber,
                industryNetworkNumber: convertedProgram.industryNetworkNumber,
                industryNetworkSyndicated: convertedProgram.industryNetworkSyndicated,
                partNumbers: convertedProgram.partNumbers
            };
            return apiService.makeApiCall(`${baseUrl}/versions/presentations/seasons/${seasonGnID}/episodes`, episodeBody, 'post', false, headers).then((res) => {
                cb(null, res?.result?.data);
            }).catch((error) => {
                cb(error);
            });
        };
        if (convertedProgram.type === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE || convertedProgram.type === MOBIUS_PROGRAM_TYPES_VALUES.SHOW) {
            callsToMake.push(registerRootMovieOrShow, registerVersionCall, registerPresentationCall)
        } else if (convertedProgram.type === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE && seasonGnID) {
            callsToMake.push(postEpisode)
        }
        return new Promise((resolve, reject) => {
            async.waterfall(callsToMake, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    };
};

export const gnidsUpdateEpisode = (showVersionGnID, showPresentationGnID, showTitle, seasonGnID, seasonTitle) => ({
    type: GNIDS_UPDATE_EPISODE,
    payload: {
        showVersionGnID,
        showPresentationGnID,
        showTitle,
        seasonGnID,
        seasonTitle
    }
});

export const gnidsClearEpisode = () => ({
    type: GNIDS_CLEAR_EPISODE
});

export const mobiusRegisterNewPresentation = (sourceId, program, allMobiusVocabularies) => {
    return async () => {
        if (!sourceId?.length || !sourceId) {
            return new Promise((reject) => {
                reject('No sourceId provided');
            });
        }
        const {
            programType,
            castType,
            color,
            crewType,
            countries
        } = allMobiusVocabularies;
        const convertedProgram = convertRegisterProgramToPost(
            program,
            programType,
            castType,
            color,
            crewType,
            countries
        );
        const headers = { SourceID: sourceId?.[0] };
        const baseUrl = convertedProgram.type === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE ? API_ENDPOINTS.GET_GNIDS_MOVIES : API_ENDPOINTS.GET_GNIDS_SHOWS;
        return registerPresentation(baseUrl, headers, program.versionGnID, convertedProgram);
    };
};

export const mobiusRegisterNewVersion = (sourceId, program, allMobiusVocabularies) => {
    return async () => {
        if (!sourceId?.length || !sourceId) {
            return new Promise((reject) => {
                reject('No sourceId provided');
            });
        }
        const {
            programType,
            castType,
            color,
            crewType,
            countries
        } = allMobiusVocabularies;
        const convertedProgram = convertRegisterProgramToPost(
            program,
            programType,
            castType,
            color,
            crewType,
            countries
        );
        const headers = { SourceID: sourceId?.[0] };
        const baseUrl = convertedProgram.type === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE ? API_ENDPOINTS.GET_GNIDS_MOVIES : API_ENDPOINTS.GET_GNIDS_SHOWS;
        const rootGnID = program.rootGnID;
        const registerVersionCall = async (cb) => {
            return registerVersion(baseUrl, headers, rootGnID, convertedProgram, cb);
        };
        const registerPresentationCall = async (versionGnId, cb) => {
            return registerPresentation(baseUrl, headers, versionGnId, convertedProgram, cb);
        };
        return new Promise((resolve, reject) => {
            async.waterfall([registerVersionCall, registerPresentationCall], (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    };
};

const registerVersion = async (baseUrl, headers, rootGnId, convertedProgram, cb) => {
    const versionBody = {
        subType: convertedProgram.subType,
        title: convertedProgram.title,
        duration: convertedProgram.duration,
        genres: convertedProgram.genres,
        versionLabels: convertedProgram.versionLabels,
        color: convertedProgram.color,
        releaseDate: convertedProgram.versionReleaseDate,
        ...(convertedProgram.type === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE && {releaseYear: convertedProgram.versionReleaseYear})
    };
    return apiService.makeApiCall(`${baseUrl}/${rootGnId}/versions`, versionBody, 'post', false, headers)
        .then((res) => {
            cb(null, res?.result?.data?.gnID);
        })
        .catch((error) => {
            cb(error);
        });
};

const registerPresentation = async (baseUrl, headers, versionGnId, convertedProgram, cb) => {
    const presentationBody = {
        subType: convertedProgram.subType,
        title: convertedProgram.title,
        cast: convertedProgram.cast,
        crew: convertedProgram.crew,
        descriptions: convertedProgram.descriptions,
        externalIDs: convertedProgram.externalIDs,
        genres: convertedProgram.genres,
        internalAttributes: convertedProgram.userMapping,
        originalSource: convertedProgram.originalSource,
        productionCompanies: convertedProgram.productionCompanies,
        productionCountries: convertedProgram.productionCountries,
        ratings: convertedProgram.ratings,
        targetAudience: convertedProgram.targetAudience,
        presentationLabels: convertedProgram.presentationLabels,
        releaseDate: convertedProgram.presentationReleaseDate,
        ...(convertedProgram.type === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE && {productionStatus: convertedProgram.productionStatus}),
        ...(convertedProgram.type === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE && {releaseYear: convertedProgram.presentationReleaseYear}),
        ...(convertedProgram.type === MOBIUS_PROGRAM_TYPES_VALUES.SHOW && {finaleDate: convertedProgram.finaleDate})
    };
    return apiService.makeApiCall(`${baseUrl}/versions/${versionGnId}/presentations`, presentationBody, 'post', false, headers)
        .then((res) => {
            if (cb) {
                cb(null, res?.result?.data);
            }
            return res?.result;
        })
        .catch((error) => {
            if (cb) {
                cb(error);
            }
            return error;
        })
};

export const mobiusSaveProgram = (sourceId, program, allMobiusVocabularies) => {
    return async () => {
        if (!sourceId?.length || !sourceId) {
            return new Promise((reject) => {
                reject('No sourceId provided');
            });
        }
        const gnID = program.gnID;
        const type = program.type;
        const versionGnId = program.versionGnID;
        const {
            programType,
            castType,
            color,
            crewType,
            countries
        } = allMobiusVocabularies;
        const callsToMake = [];
        const convertedProgram = convertRegisterProgramToPost(
            program,
            programType,
            castType,
            color,
            crewType,
            countries
        );
        const headers = { SourceID: sourceId?.[0] };
        const endpoint =
            type === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE
                ? API_ENDPOINTS.GET_GNIDS_MOVIES
                : API_ENDPOINTS.GET_GNIDS_SHOWS;

        const saveVersion = async (cb) => {
            const versionBody = {
                duration: convertedProgram.duration,
                genres: convertedProgram.genres,
                versionLabels: convertedProgram.versionLabels,
                title: convertedProgram.title,
                color: convertedProgram.color,
                releaseDate: convertedProgram.versionReleaseDate,
                releaseYear: convertedProgram.versionReleaseYear
            };
            return apiService.makeApiCall(`${endpoint}/versions/${versionGnId}`, versionBody, 'put', false, headers)
                .then((res) => {
                    cb(null, res?.result?.data)
                })
                .catch((error) => {
                    cb(error, null);
                });
        }

        const savePresentation = async (cb) => {
            const presentationBody = {
                cast: convertedProgram.cast,
                crew: convertedProgram.crew,
                descriptions: convertedProgram.descriptions,
                externalIDs: convertedProgram.externalIDs,
                finaleDate: convertedProgram.finaleDate,
                genres: convertedProgram.genres,
                internalAttributes: convertedProgram.userMapping,
                originalSource: convertedProgram.originalSource,
                productionCompanies: convertedProgram.productionCompanies,
                productionCountries: convertedProgram.productionCountries,
                ratings: convertedProgram.ratings,
                targetAudience: convertedProgram.targetAudience,
                title: convertedProgram.title,
                presentationLabels: convertedProgram.presentationLabels,
                productionStatus: convertedProgram.productionStatus,
                releaseDate: convertedProgram.presentationReleaseDate,
                releaseYear: convertedProgram.presentationReleaseYear
            };
            return apiService.makeApiCall(`${endpoint}/versions/presentations/${gnID}`, presentationBody, 'put', false, headers)
                .then((res) => {
                    cb(null, res?.result?.data);
                })
                .catch((error) => {
                    cb(error, null);
                })
        }

        const saveEpisode = async (cb) => {
            const episodeBody = {
                cast: convertedProgram.cast,
                crew: convertedProgram.crew,
                descriptions: convertedProgram.descriptions,
                externalIDs: convertedProgram.externalIDs,
                genres: convertedProgram.genres,
                originalSource: convertedProgram.originalSource,
                productionCompanies: convertedProgram.productionCompanies,
                productionCountries: convertedProgram.productionCountries,
                ratings: convertedProgram.ratings,
                releaseDate: convertedProgram.presentationReleaseDate,
                targetAudience: convertedProgram.targetAudience,
                title: convertedProgram.title,
                // Episode Specific
                color: convertedProgram.color,
                duration: convertedProgram.duration,
                episodeNumber: convertedProgram.episodeNumber,
                industryNetworkNumber: convertedProgram.industryNetworkNumber,
                industryNetworkSyndicated: convertedProgram.industryNetworkSyndicated,
                partNumbers: convertedProgram.partNumbers
            }
            return apiService.makeApiCall(`${endpoint}/versions/presentations/seasons/episodes/${gnID}`, episodeBody, 'put', false, headers)
                .then((res) => {
                    cb(null, res?.result?.data);
                })
                .catch((error) => {
                    cb(error, null);
                })
        }

        if (convertedProgram.type === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE || convertedProgram.type === MOBIUS_PROGRAM_TYPES_VALUES.SHOW) {
            callsToMake.push(saveVersion, savePresentation)
        } else if (convertedProgram.type === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE && gnID) {
            callsToMake.push(saveEpisode)
        }
        return new Promise((resolve, reject) => {
            async.series(callsToMake, (err, res) => {
                // TODO - should use the response to update the program/redux state
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    };
};

export const mobiusRequestPublishProgram = (gnID, sourceId, userMapping) => {
    return () => {
        if (!sourceId?.length || !sourceId) {
            return new Promise((reject) => {
                reject('No sourceId provided');
            });
        }
        const headers = { SourceID: sourceId?.[0] };
        const body = userMapping?.selfMappingID ? { gnID, mapping: {
            id: userMapping.selfMappingID,
            type: 'tmsID'
        } } : { gnID };
        return apiService.makeApiCall(
            `${API_ENDPOINTS.GNIDS_PUBLISH}`,
            body,
            'post',
            false,
            headers
        );
    }
};

export const mobiusGetProgramPresentations = (rootGnID, sourceId, programType, currentGNID) => {
    return async () => {
        const headers = { SourceID: sourceId?.[0] };
        const endpoint =
            programType === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE
                ? API_ENDPOINTS.GET_GNIDS_MOVIES
                : API_ENDPOINTS.GET_GNIDS_SHOWS;

        const versionsResponse = await apiService.makeApiCall(
            `${endpoint}/versions?rootGnID=${rootGnID}`,
            {},
            'get',
            false,
            headers
        );
        const presentationsResponse = await apiService.makeApiCall(
            `${endpoint}/versions/presentations?rootGnID=${rootGnID}`,
            {},
            'get',
            false,
            headers
        );
        const data = [];
        presentationsResponse.result.data.forEach((presentation) => {
            const versions =
                versionsResponse?.result?.data?.filter(
                    (v) => v.gnID === presentation.versionGnID
                ) || [];
            const addedVersionLabels = {
                ...presentation,
                versionLabels: versions.length > 0 ? versions[0].versionLabels : versions
            };
            if (presentation.gnID === currentGNID) {
                data.unshift(addedVersionLabels);
            } else {
                data.push(addedVersionLabels);
            }
        });
        return { result: { data } };
    };
};

export const mobiusGetSeasonsByPresentationId = (showPresentationGnID, sourceId) => {
    return () => {
        const headers = { SourceID: sourceId?.[0] };
        return apiService.makeApiCall(
            `${API_ENDPOINTS.GET_GNIDS_SHOWS}/versions/presentations/seasons?showPresentationGnID=${showPresentationGnID}`,
            {},
            'get',
            false,
            headers
        );
    };
};

export const mobiusGetEpisodesByPresentationIdAndSeasonId = (
    showPresentationGnID,
    seasonGnID,
    sourceId,
    seasonNumber,
    selectedCatalog
) => {
    return () => {
        let catalogGnIDsParam = '';
        if (selectedCatalog !== '') {
            catalogGnIDsParam = `&catalogGnIDs=${selectedCatalog}`
        }
        const headers = { SourceID: sourceId?.[0] };
        return apiService
            .makeApiCall(
                `${API_ENDPOINTS.GET_GNIDS_SHOWS}/versions/presentations/seasons/episodes?showPresentationGnID=${showPresentationGnID}&seasonGnID=${seasonGnID}${catalogGnIDsParam}`,
                {},
                'get',
                false,
                headers
            )
            .then((response) => {
                const addedSeasonNumberInData = response?.result?.data.map((episode) => ({
                    ...episode,
                    seasonNumber
                }));
                return {
                    result: {
                        meta: {
                            ...response?.result?.meta,
                            total: response?.result?.meta?.count
                        },
                        data: sortEpisodes(addedSeasonNumberInData)
                    }
                };
            });
    };
};

const sortEpisodes = (programs) => {
    const episodeNumberLettersOnly = [];
    const episodeNumberDigitsOnly = [];
    const episodeNumberLettersWithDigits = [];

    for (let i = 0; i < programs.length; i++) {
        const episodeNumber = programs[i].episodeNumber
        if (!isNaN(episodeNumber)) {
            episodeNumberDigitsOnly.push(programs[i])
        } else if (!/\d/.test(episodeNumber)) {
            episodeNumberLettersOnly.push(programs[i])
        } else {
            episodeNumberLettersWithDigits.push(programs[i])
        }
    }

    episodeNumberLettersOnly.sort(sortLettersOnly)
    episodeNumberDigitsOnly.sort(sortDigitsOnly)
    episodeNumberLettersWithDigits.sort(sortLettersWithDigits)

    return [...episodeNumberDigitsOnly, ...episodeNumberLettersWithDigits, ...episodeNumberLettersOnly].sort(sortReleaseDates)
}

const sortDigitsOnly = (a, b) => {
    return parseInt(b?.episodeNumber) - parseInt(a?.episodeNumber)
}

const sortLettersOnly = (a, b) => {
    const aEpisodeNum = a?.episodeNumber.toUpperCase();
    const bEpisodeNum = b?.episodeNumber.toUpperCase();
    return bEpisodeNum.localeCompare(aEpisodeNum, 'en')
}

const sortLettersWithDigits = (a, b) => {
    const aNum = a?.episodeNumber.replace(/\D/g, '');
    const bNum = b?.episodeNumber.replace(/\D/g, '');
    return bNum - aNum
}

const sortReleaseDates = (a, b) => {
    return moment(b?.releaseDate) - moment(a?.releaseDate)
}

export const mobiusGetEpisodeByEpisodeId = (episodeGNID, sourceId) => {
    return async () => {
        const headers = { SourceID: sourceId?.[0] };
        const episodeResponse = await apiService.makeApiCall(
            `${API_ENDPOINTS.GET_GNIDS_SHOWS}/versions/presentations/seasons/episodes/${episodeGNID}`,
            {},
            'get',
            false,
            headers
        );
        const {
            result: {
                data: { color, duration, releaseDate, releaseYear, versionLabels }
            }
        } = episodeResponse;
        return {
            ...episodeResponse?.result?.data,
            versions: {
                color,
                duration,
                releaseDate,
                releaseYear,
                versionLabels
            }
        };
    };
};

/**
 * DELETE /movies, /shows, /images - Delete an item (movie, show, episode, image)
 * @param {string} gnID - Movie/Show - PresentationGnId, Episode - GnId
 * @param {object} sourceId - GN IDS Source ID
 * @param {string} type - movie, show, episode, image
 * @returns {Promise}
 */
export const mobiusDeleteItemByGNId = (gnID, sourceId, type) => {
    return () => {
        const headers = { SourceID: sourceId?.[0] };
        let endPoint = '';

        if (type === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE) {
            endPoint += `${API_ENDPOINTS.GET_GNIDS_MOVIES}/versions/presentations/${gnID}`;
        } else if (type === MOBIUS_PROGRAM_TYPES_VALUES.SHOW) {
            endPoint += `${API_ENDPOINTS.GET_GNIDS_SHOWS}/versions/presentations/${gnID}`;
        } else if (type === MOBIUS_PROGRAM_TYPES_VALUES.SEASON) {
            endPoint += `${API_ENDPOINTS.GET_GNIDS_SHOWS}/versions/presentations/seasons/${gnID}`;
        } else if (type === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE) {
            endPoint += `${API_ENDPOINTS.GET_GNIDS_SHOWS}/versions/presentations/seasons/episodes/${gnID}`;
        } else if (type === MOBIUS_PROGRAM_TYPES_VALUES.CATALOG) {
            endPoint += `${API_ENDPOINTS.GET_MOBIUS_CATALOGS}/${gnID}`;
        } else if (type === MOBIUS_PROGRAM_TYPES_VALUES.IMAGE) {
            endPoint += `${API_ENDPOINTS.GET_MOBIUS_IMAGES}/${gnID}`;
        }

        return apiService.makeApiCall(endPoint, {}, 'delete', false, headers);
    };
};

export const mobiusGetImage = (gnID, sourceId) => {
    return async () => {
        const headers = { SourceID: sourceId?.[0] };
        const endpoint = API_ENDPOINTS.GET_MOBIUS_IMAGES;

        const imageResponse = await apiService.makeApiCall(
            `${endpoint}/${gnID}`,
            {},
            'get',
            false,
            headers
        );
        return imageResponse?.result?.data
    };
};

export const mobiusGetImageGNIdByPresentationGNId = (gnID, sourceId) => {
    return async () => {
        const headers = { SourceID: sourceId?.[0] };
        const endpoint = API_ENDPOINTS.GET_MOBIUS_PROGRAM_IMAGES;
        const params = {
            limit: 100,
            page: 1,
            presentationGnID: gnID
        };
        const response = await apiService
            .makeApiCall(
                endpoint,
                params,
                'get',
                false,
                headers
            );
        return response?.result?.data
    }
}

export const mobiusProgramImageMapping = (sourceId, presentationGNId, images, deletedImages) => {
    return async () => {
        const headers = { SourceID: sourceId?.[0] };
        const endpoint = API_ENDPOINTS.GET_MOBIUS_PROGRAM_IMAGES;
        const convertedAddPresentationImageMapping = convertProgramImageForMapping(presentationGNId, images, 'post')
        const convertedDeletePresentationImageMapping = convertProgramImageForMapping(presentationGNId, deletedImages, 'patch')
        const didAddImage = convertedAddPresentationImageMapping?.length
        const didDeleteImage = convertedDeletePresentationImageMapping?.length

        if (didAddImage && didDeleteImage) {
            const response = await Promise.all([
                apiService.makeApiCall(
                    endpoint,
                    convertedAddPresentationImageMapping,
                    'post',
                    false,
                    headers),
                apiService.makeApiCall(
                    endpoint,
                    convertedDeletePresentationImageMapping,
                    'patch',
                    false,
                    headers
                )
            ])
            return response
        } else if (didAddImage) {
            const response = await apiService.makeApiCall(
                endpoint,
                convertedAddPresentationImageMapping,
                'post',
                false,
                headers
            );
            return response
        } else if (didDeleteImage) {
            const response = await apiService.makeApiCall(
                endpoint,
                convertedDeletePresentationImageMapping,
                'patch',
                false,
                headers
            );
            return response
        }
        return null;
    }
}

export const mobiusGetCatalog = (gnID) => {
    return async () => {
        const catalogResponse = await apiService.makeApiCall(
            `${API_ENDPOINTS.GET_MOBIUS_CATALOGS}/${gnID}`,
            {},
            'get',
            false
        );
        return catalogResponse?.result?.data
    };
};