

import { convertExternalIDs, convertCast, convertCrew, convertDescriptions, convertRatings } from './FlattenedJSONToNestedUtils';
import { convertDurationIntoMillis } from './MobiusUtils';

const rowIDToIndexMap = {}

function addToPresentationArray (row, nestedStructure) {
    const presentation = {
        context: {
            rowID: row.rowID
        },
        versionGnID: row.parent.GnID,
        presentation: {
            ...row.subType && {subType: row.subType},
            ...row.title && {title: row.title},
            ...row.cast && {cast: convertCast(row.cast)},
            ...row.crew && {crew: convertCrew(row.crew)},
            ...row.descriptions && {descriptions: convertDescriptions(row.descriptions)},
            ...row.externalIDs && {externalIDs: convertExternalIDs(row.externalIDs)},
            ...row.genres && {genres: row.genres},
            ...row.selfMappedTmsId && {
                internalAttributes: {
                    selfMappingID: row.selfMappedTmsId,
                    selfMappingType: 'tmsID'
                }
            },
            ...row.originalSource && {originalSource: row.originalSource },
            ...row.productionCountries && {productionCountries: row.productionCountries},
            ...row.productionCompanies && {productionCompanies: row.productionCompanies},
            ...row.ratings && {ratings: convertRatings(row.ratings)},
            ...row.targetAudience && {targetAudience: row.targetAudience},
            ...row.presentationLabels && {presentationLabels: row.presentationLabels},
            ...row.productionStatus && {productionStatus: row.productionStatus},
            ...row.presentation?.releaseDate && {releaseDate: row.presentation.releaseDate},
            ...row.presentation?.releaseYear && {releaseYear: parseInt(row.presentation.releaseYear)}
        }
    }

    nestedStructure.presentations.push(presentation)
}
function addToVersionArray (row, nestedStructure) {
    rowIDToIndexMap[row.rowID] = nestedStructure.versions.length;
    const presentation = createPresentationObject(row, [], nestedStructure)
    const version = {
        context: {
            rowID: row.rowID
        },
        rootGnID: row.parent.GnID,
        version: {
            ...row.subType && {subType: row.subType},
            ...row.title && {title: row.title},
            ...row.duration && {duration: convertDurationIntoMillis(row.duration)},
            ...row.genres && {genres: row.genres},
            ...row.versionLabels && {versionLabels: row.versionLabels},
            ...row.color && {color: row.color},
            ...row.version?.releaseDate && {releaseDate: row.version.releaseDate},
            ...row.version?.releaseYear && {releaseYear: parseInt(row.version.releaseYear)}
        },
        presentations: [presentation]
    }
    nestedStructure.versions.push(version);
}
function createPresentationObject (row, rows, nestedStructure) {
    const presentation = {
        context: {
            rowID: row.rowID
        },
        presentation: {
            ...row.subType && {subType: row.subType},
            ...row.title && {title: row.title},
            ...row.cast && {cast: convertCast(row.cast)},
            ...row.crew && {crew: convertCrew(row.crew)},
            ...row.descriptions && {descriptions: convertDescriptions(row.descriptions)},
            ...row.externalIDs && {externalIDs: convertExternalIDs(row.externalIDs)},
            ...row.genres && {genres: row.genres},
            ...row.selfMappedTmsId && {
                internalAttributes: {
                    selfMappingID: row.selfMappedTmsId,
                    selfMappingType: 'tmsID'
                }
            },
            ...row.originalSource && {originalSource: row.originalSource },
            ...row.productionCountries && {productionCountries: row.productionCountries},
            ...row.productionCompanies && {productionCompanies: row.productionCompanies},
            ...row.ratings && {ratings: convertRatings(row.ratings)},
            ...row.targetAudience && {targetAudience: row.targetAudience},
            ...row.presentationLabels && {presentationLabels: row.presentationLabels},
            ...row.productionStatus && {productionStatus: row.productionStatus},
            ...row.presentation?.releaseDate && {releaseDate: row.presentation.releaseDate},
            ...row.presentation?.releaseYear && {releaseYear: parseInt(row.presentation.releaseYear)}
        }
    }
    switch (row.operationType) {
    case 'register':
    case 'registerVersion':
        return presentation;
    case 'registerPresentation': {
        // if we are registering a presentation, we need to know if the operationType of the parent was either 'register' or 'registerVersion' since those will tell us
        // where the version we need to add to is stored.
        const presParentRow = rows.find((prevRows) => prevRows.rowID === row.parent.rowID);
        if (presParentRow.parent.GnID === '') {
            // if the presentations parent had operationType 'register' then that means we need to add the presentation under the first version that was created under that root.
            if (presParentRow.operationType === 'register') {
                nestedStructure.roots[rowIDToIndexMap[presParentRow.rowID]].versions[0].presentations.push(presentation)
            }
            // if the presentations parent had operationType 'registerVersion' then that means we need to add the presentation under a specific root and version that was created in the template
            if (presParentRow.operationType === 'registerVersion') {
                nestedStructure.roots[rowIDToIndexMap[presParentRow.parent.rowID]].versions[rowIDToIndexMap[presParentRow.rowID]].presentations.push(presentation)
            }
        } else {
            // in this case, the presentations parent (version) GnID was not null, so we are adding it an existing root in the application but a version that was created in the template
            nestedStructure.versions[rowIDToIndexMap[presParentRow.rowID]].presentations.push(presentation)
        }
        break;
    }
    default:
        return presentation
    }
    return presentation;
}
function createVersionObject (row, nestedStructure) {
    const version = {
        context: {
            rowID: row.rowID
        },
        version: {
            ...row.subType && {subType: row.subType},
            ...row.title && {title: row.title},
            ...row.duration && {duration: convertDurationIntoMillis(row.duration)},
            ...row.genres && {genres: row.genres},
            ...row.versionLabels && {versionLabels: row.versionLabels},
            ...row.color && {color: row.color},
            ...row.version?.releaseDate && {releaseDate: row.version.releaseDate},
            ...row.version?.releaseYear && {releaseYear: parseInt(row.version.releaseYear)}
        },
        presentations: [createPresentationObject(row, [], nestedStructure)]
    }
    switch (row.operationType) {
    case 'register':
        return version;
    case 'registerVersion': {
        rowIDToIndexMap[row.rowID] = nestedStructure.roots[rowIDToIndexMap[row.parent.rowID]].versions.length
        // add the version under the specific root object
        nestedStructure.roots[rowIDToIndexMap[row.parent.rowID]].versions.push(version)
        break;
    }
    default:
        return version;
    }
    return version
}
function createRootObject (row, nestedStructure) {
    // create the root object
    rowIDToIndexMap[row.rowID] = nestedStructure.roots.length;
    const version = [createVersionObject(row)];
    const root = {
        context: {
            rowID: row.rowID
        },
        root: {
            ...row.subType && {subType: row.subType},
            ...row.title && {title: row.title}
        },
        versions: version
    }
    nestedStructure.roots.push(root)
}
export const convertFlattenedJSONToNested = (rows, file, rowCount) => {
    const nestedStructure = {
        context: {
            fileName: file.name,
            fileSize: file.size.toString(),
            rowCount: rowCount.toString()
        },
        roots: [],
        versions: [],
        presentations: []
    }
    for (const row of rows) {
        switch (row.operationType) {
        case 'register':
            createRootObject(row, nestedStructure);
            break;
        case 'registerVersion':
            if (row.parent.GnID === '') {
                createVersionObject(row, nestedStructure)
            } else {
                addToVersionArray(row, nestedStructure)
            }
            break;
        case 'registerPresentation':
            if (row.parent.GnID === '') {
                createPresentationObject(row, rows, nestedStructure);
            } else {
                addToPresentationArray(row, nestedStructure);
            }
            break;
        default:
            throw Error('Invalid operationType')
        }
    }

    return nestedStructure;
}