
import { convertCast, convertCrew, convertDescriptions, convertExternalIDs, convertRatings } from './FlattenedJSONToNestedUtils'
import { convertDurationIntoMillis } from './MobiusUtils'

const rowIDToIndexMap = {}

function createSeason(row) {
    const season = {
        context: {
            rowID: row.rowID
        },
        season: {
            ...row.subType && {subType: row.subType},
            ...row.title && {title: row.title},
            ...row.externalIDs && {externalIDs: convertExternalIDs(row.externalIDs)},
            ...row.descriptions && {descriptions: convertDescriptions(row.descriptions)},
            ...row.cast && {cast: convertCast(row.cast)},
            ...row.crew && {crew: convertCrew(row.crew)},
            ...row.finaleDate && {finaleDate: row.finaleDate},
            ...row.season?.nonSeason ? {nonSeason: JSON.parse(row.season.nonSeason.toLowerCase())} : {nonSeason: false},
            ...row.season?.ordinal && {ordinal: parseInt(row.season.ordinal)},
            ...row.orginalSource && {originalSource: row.originalSource},
            ...row.season?.releaseDate && {releaseDate: row.season.releaseDate},
            ...row.season?.seasonNumber && {seasonNumber: row.season.seasonNumber}
        }
    }

    return season
}

function createEpisode(row) {
    const episode = {
        episode: {
            ...row.subType && {subType: row.subType},
            ...row.title && {title: row.title},
            ...row.cast && {cast: convertCast(row.cast)},
            ...row.crew && {crew: convertCrew(row.crew)},
            ...row.externalIDs && {externalIDs: convertExternalIDs(row.externalIDs)},
            ...row.descriptions && {descriptions: convertDescriptions(row.descriptions)},
            ...row.genres && {genres: row.genres},
            ...row.orginalSource && {originalSource: row.originalSource},
            ...row.productionCountries && {productionCountries: row.productionCountries},
            ...row.productionCompanies && {productionCompanies: row.productionCompanies},
            ...row.ratings && {ratings: convertRatings(row.ratings)},
            ...row.targetAudience && {targetAudience: row.targetAudience},
            ...row.color && {color: row.color},
            ...row.duration && {duration: convertDurationIntoMillis(row.duration)},
            ...row.episode?.episodeNumber && {episodeNumber: row.episode.episodeNumber},
            ...row.episode?.industryNetworkNumber && {industryNetworkNumber: row.episode.industryNetworkNumber},
            ...row.episode?.industryNetworkSyndicated && {industryNetworkSyndicated: row.episode.industryNetworkSyndicated},
            ...row.episode?.ordinal && {ordinal: parseInt(row.episode.ordinal)},
            ...row.episode?.partNumbers && {partNumbers: row.episode.partNumbers},
            ...row.episode?.releaseDate && {releaseDate: row.episode.releaseDate}
        },
        context: {
            rowID: row.rowID
        }
    }
    return episode
}

function addToPresentationArray (row, nestedStructure) {
    rowIDToIndexMap[row.rowID] = nestedStructure.presentations.length;
    const presentation = {
        context: {
            rowID: row.rowID
        },
        versionGnID: row.parent.GnID,
        presentation: {
            ...row.subType && {subType: row.subType},
            ...row.title && {title: row.title},
            ...row.cast && {cast: convertCast(row.cast)},
            ...row.crew && {crew: convertCrew(row.crew)},
            ...row.externalIDs && {externalIDs: convertExternalIDs(row.externalIDs)},
            ...row.descriptions && {descriptions: convertDescriptions(row.descriptions)},
            ...row.genres && {genres: row.genres},
            ...row.selfMappedTmsId && {
                internalAttributes: {
                    selfMappingID: row.selfMappedTmsId,
                    selfMappingType: 'tmsID'
                }
            },
            ...row.originalSource && {originalSource: row.originalSource },
            ...row.finaleDate && {finaleDate: row.finaleDate},
            ...row.productionCompanies && {productionCompanies: row.productionCompanies},
            ...row.productionCountries && {productionCountries: row.productionCountries},
            ...row.ratings && {ratings: convertRatings(row.ratings)},
            ...row.targetAudience && {targetAudience: row.targetAudience},
            ...row.presentationLabels && {presentationLabels: row.presentationLabels},
            ...row.productionStatus && {productionStatus: row.productionStatus},
            ...row.presentation?.releaseDate && {releaseDate: row.presentation.releaseDate},
            ...row.presentation?.releaseYear && {releaseYear: row.presentation.releaseYear}
        },
        seasons: []
    }

    nestedStructure.presentations.push(presentation)
}
function addToVersionArray (row, nestedStructure) {
    rowIDToIndexMap[row.rowID] = nestedStructure.versions.length;
    const presentation = createPresentationObject(row, [], nestedStructure)
    const version = {
        context: {
            rowID: row.rowID
        },
        rootGnID: row.parent.GnID,
        version: {
            ...row.subType && {subType: row.subType},
            ...row.title && {title: row.title},
            ...row.duration && {duration: convertDurationIntoMillis(row.duration)},
            ...row.genres && {genres: row.genres},
            ...row.versionLabels && {versionLabels: row.versionLabels},
            ...row.color && {color: row.color},
            ...row.version?.releaseDate && {releaseDate: row.version.releaseDate},
            ...row.version?.releaseYear && {releaseYear: parseInt(row.version.releaseYear)}
        },
        presentations: [presentation]
    }
    nestedStructure.versions.push(version);
}

function addToSeasonsArray(row, nestedStructure) {
    rowIDToIndexMap[row.rowID] = nestedStructure.seasons.length;

    const season = {
        ...createSeason(row),
        episodes: [],
        presentationGnID: row.parent.GnID
    }
    nestedStructure.seasons.push(season)
}

function addToEpisodesArray (row, nestedStructure) {
    const episode = {
        ...createEpisode(row),
        seasonGnID: row.parent.GnID
    }
    nestedStructure.episodes.push(episode)
}

function createEpisodeObject(row, rows, nestedStructure) {
    const episode = {
        ...createEpisode(row)
    }

    // the operation of the parent will tell us where to store the season object
    const season = rows.find(prevRows => prevRows.rowID === row.parent.rowID)

    if (season.parent.GnID !== '') {
        nestedStructure.seasons[rowIDToIndexMap[season.rowID]].episodes.push(episode)
        return episode;
    }
    const seasonParentRow = rows.find(prevRows => prevRows.rowID === season.parent.rowID);

    switch (seasonParentRow.operationType) {
    case 'register': {
        // if the parent type was register, then we know we need to store the season under the first version and presentation under the specific root
        nestedStructure.roots[rowIDToIndexMap[seasonParentRow.rowID]].versions[0].presentations[0].seasons[rowIDToIndexMap[season.rowID]].episodes.push(episode)
        break;
    }
    case 'registerVersion': {
        if (seasonParentRow.parent.GnID === '') {
            // add the episode to
            nestedStructure.roots[rowIDToIndexMap[seasonParentRow.parent.rowID]].versions[rowIDToIndexMap[seasonParentRow.rowID]].presentations[0].seasons[rowIDToIndexMap[season.rowID]].episodes.push(episode)
        } else {
            nestedStructure.versions[rowIDToIndexMap[seasonParentRow.rowID]].presentations[0].seasons[rowIDToIndexMap[season.rowID]].episodes.push(episode)
        }
        break;
    }

    case 'registerPresentation': {
        // if the parents type is registerPresentation, then we know we must store the season under the index of where this presentation.
        // We need to traverse back up the hierarchy, respective of the parents operations, and locate where to use the index of the presentation.
        if (seasonParentRow.parent.GnID === '') {
            const version = rows.find(prevRows => prevRows.rowID === seasonParentRow.parent.rowID)
            // if the parent GnID of the version is empty, then we know that we are referencing a root that was created in the template
            if (version.parent.GnID === '') {
                if (version.operationType === 'register') {
                    // if the parent type of the version was register, then we know we need to store the season under a specific presentation of the first version within some root.
                    // We know it's the first version because the type was register.
                    nestedStructure.roots[rowIDToIndexMap[version.rowID]].versions[0].presentations[rowIDToIndexMap[seasonParentRow.rowID]].seasons[rowIDToIndexMap[season.rowID]].episodes.push(episode)
                } else if (version.operationType === 'registerVersion') {
                    // add the season to the specific root and version created in the template
                    nestedStructure.roots[rowIDToIndexMap[version.parent.rowID]].versions[rowIDToIndexMap[version.rowID]].presentations[rowIDToIndexMap[seasonParentRow.rowID]].seasons[rowIDToIndexMap[season.rowID]].episodes.push(episode)
                }
            } else {
                // in this case, the version had a parent GnID. So we need to add the season to the first presentation under a specific version.
                nestedStructure.versions[rowIDToIndexMap[version.rowID]].presentations[rowIDToIndexMap[seasonParentRow.rowID]].seasons[rowIDToIndexMap[season.rowID]].episodes.push(episode)
            }
        } else {
            // the seasons parent (presentation) had a parent.GnID. So we need to add the season to a specific presentation in the presentations array
            nestedStructure.presentations[rowIDToIndexMap[seasonParentRow.rowID]].seasons[rowIDToIndexMap[season.rowID]].episodes.push(episode)
        }
        break;
    }
    default:
        return episode;
    }
    return episode;
}

function createSeasonObject(row, rows, nestedStructure) {
    const season = {
        ...createSeason(row),
        episodes: []
    }

    // the operation type of the season parent must be either register, registerVersion, or registerPresentation. Based on these scenarios, we will know where to store the season
    const seasonParentRow = rows.find(prevRows => prevRows.rowID === row.parent.rowID);

    switch (seasonParentRow.operationType) {
    case 'register': {
        // if the parent type was register, then we know we need to store the season under the first version and presentation under the specific root
        rowIDToIndexMap[row.rowID] = nestedStructure.roots[rowIDToIndexMap[seasonParentRow.rowID]].versions[0].presentations[0].seasons.length
        nestedStructure.roots[rowIDToIndexMap[seasonParentRow.rowID]].versions[0].presentations[0].seasons.push(season)
        break;
    }
    case 'registerVersion': {
        if (seasonParentRow.parent.GnID === '') {
            // add the season to the specific root and version created in the template
            rowIDToIndexMap[row.rowID] = nestedStructure.roots[rowIDToIndexMap[seasonParentRow.parent.rowID]].versions[rowIDToIndexMap[seasonParentRow.rowID]].presentations[0].seasons.length
            nestedStructure.roots[rowIDToIndexMap[seasonParentRow.parent.rowID]].versions[rowIDToIndexMap[seasonParentRow.rowID]].presentations[0].seasons.push(season)
        } else {
            // the root we are adding to already exists, so need to add the season under the first presentation of a specific season
            rowIDToIndexMap[row.rowID] = nestedStructure.versions[rowIDToIndexMap[seasonParentRow.rowID]].presentations[0].seasons.length
            nestedStructure.versions[rowIDToIndexMap[seasonParentRow.rowID]].presentations[0].seasons.push(season)
        }
        break;
    }

    case 'registerPresentation': {
        // if the parents type is registerPresentation, then we know we must store the season under the index of where this presentation.
        // We need to traverse back up the hierarchy, respective of the parent operations, and locate where to use the index of the presentation.
        if (seasonParentRow.parent.GnID === '') {
            const version = rows.find(prevRows => prevRows.rowID === seasonParentRow.parent.rowID)
            // if the parent GnID of the version is empty, then we know that we are referencing a root that was created in the template
            if (version.parent.GnID === '') {
                if (version.operationType === 'register') {
                    // if the parent type of the version was register, then we know we need to store the season under a specific presentation of the first version within some root.
                    // We know it's the first version because the type was register.
                    rowIDToIndexMap[row.rowID] = nestedStructure.roots[rowIDToIndexMap[version.rowID]].versions[0].presentations[rowIDToIndexMap[seasonParentRow.rowID]].seasons.length
                    nestedStructure.roots[rowIDToIndexMap[version.rowID]].versions[0].presentations[rowIDToIndexMap[seasonParentRow.rowID]].seasons.push(season)
                } else if (version.operationType === 'registerVersion') {
                    // add the season to the specific root and version created in the template
                    rowIDToIndexMap[row.rowID] = nestedStructure.roots[rowIDToIndexMap[version.parent.rowID]].versions[rowIDToIndexMap[version.rowID]].presentations[rowIDToIndexMap[seasonParentRow.rowID]].seasons.length
                    nestedStructure.roots[rowIDToIndexMap[version.parent.rowID]].versions[rowIDToIndexMap[version.rowID]].presentations[rowIDToIndexMap[seasonParentRow.rowID]].seasons.push(season)
                }
            } else {
                // in this case, the version had a parent GnID. So we need to add the season to the first presentation under a specific version.
                rowIDToIndexMap[row.rowID] = nestedStructure.versions[rowIDToIndexMap[version.rowID]].presentations[rowIDToIndexMap[seasonParentRow.rowID]].seasons.length
                nestedStructure.versions[rowIDToIndexMap[version.rowID]].presentations[rowIDToIndexMap[seasonParentRow.rowID]].seasons.push(season)
            }
        } else {
            // the seasons parent (presentation) had a parent.GnID. So we need to add the season to a specific presentation in the presentations array
            rowIDToIndexMap[row.rowID] = nestedStructure.presentations[rowIDToIndexMap[seasonParentRow.rowID]].seasons.length
            nestedStructure.presentations[rowIDToIndexMap[seasonParentRow.rowID]].seasons.push(season)
        }
        break;
    }
    default:
        return season;
    }
    return season;
}
function createPresentationObject (row, rows, nestedStructure) {
    const presentation = {
        context: {
            rowID: row.rowID
        },
        presentation: {
            ...row.subType && {subType: row.subType},
            ...row.title && {title: row.title},
            ...row.cast && {cast: convertCast(row.cast)},
            ...row.crew && {crew: convertCrew(row.crew)},
            ...row.externalIDs && {externalIDs: convertExternalIDs(row.externalIDs)},
            ...row.descriptions && {descriptions: convertDescriptions(row.descriptions)},
            ...row.genres && {genres: row.genres},
            ...row.selfMappedTmsId && {
                internalAttributes: {
                    selfMappingID: row.selfMappedTmsId,
                    selfMappingType: 'tmsID'
                }
            },
            ...row.originalSource && {originalSource: row.originalSource },
            ...row.finaleDate && {finaleDate: row.finaleDate},
            ...row.productionCompanies && {productionCompanies: row.productionCompanies},
            ...row.productionCountries && {productionCountries: row.productionCountries},
            ...row.ratings && {ratings: convertRatings(row.ratings)},
            ...row.targetAudience && {targetAudience: row.targetAudience},
            ...row.presentationLabels && {presentationLabels: row.presentationLabels},
            ...row.productionStatus && {productionStatus: row.productionStatus},
            ...row.presentation?.releaseDate && {releaseDate: row.presentation.releaseDate},
            ...row.presentation?.releaseYear && {releaseYear: row.presentation.releaseYear}
        },
        seasons: []
    }
    switch (row.operationType) {
    case 'register':
    case 'registerVersion':
        return presentation;
    case 'registerPresentation': {
        // if we are registering a presentation, we need to know if the operationType of the parent was either 'register' or 'registerVersion' since those will tell us
        // where the version we need to add to is stored.
        const presParentRow = rows.find((prevRows) => prevRows.rowID === row.parent.rowID);
        if (presParentRow.parent.GnID === '') {
            // if the presentations parent had operationType 'register' then that means we need to add the presentation under the first version that was created under that root.
            if (presParentRow.operationType === 'register') {
                rowIDToIndexMap[row.rowID] = nestedStructure.roots[rowIDToIndexMap[presParentRow.rowID]].versions[0].presentations.length
                nestedStructure.roots[rowIDToIndexMap[presParentRow.rowID]].versions[0].presentations.push(presentation)
            }
            // if the presentations parent had operationType 'registerVersion' then that means we need to add the presentation under a specific root and version that was created in the template
            if (presParentRow.operationType === 'registerVersion') {
                rowIDToIndexMap[row.rowID] = nestedStructure.roots[rowIDToIndexMap[presParentRow.parent.rowID]].versions[rowIDToIndexMap[presParentRow.rowID]].presentations.length
                nestedStructure.roots[rowIDToIndexMap[presParentRow.parent.rowID]].versions[rowIDToIndexMap[presParentRow.rowID]].presentations.push(presentation)
            }
        } else {
            // in this case, the presentations parent (version) GnID was not null, so we are adding it an existing root in the application but a version that was created in the template
            rowIDToIndexMap[row.rowID] = nestedStructure.versions[rowIDToIndexMap[presParentRow.rowID]].presentations.length
            nestedStructure.versions[rowIDToIndexMap[presParentRow.rowID]].presentations.push(presentation)
        }
        break;
    }
    default:
        return presentation
    }
    return presentation;
}
function createVersionObject (row, nestedStructure) {
    const version = {
        context: {
            rowID: row.rowID
        },
        version: {
            ...row.subType && {subType: row.subType},
            ...row.title && {title: row.title},
            ...row.duration && {duration: convertDurationIntoMillis(row.duration)},
            ...row.genres && {genres: row.genres},
            ...row.versionLabels && {versionLabels: row.versionLabels},
            ...row.color && {color: row.color},
            ...row.version?.releaseDate && {releaseDate: row.version.releaseDate},
            ...row.version?.releaseYear && {releaseYear: parseInt(row.version.releaseYear)}
        },
        presentations: [createPresentationObject(row, [], nestedStructure)]
    }
    switch (row.operationType) {
    case 'register':
        return version;
    case 'registerVersion': {
        rowIDToIndexMap[row.rowID] = nestedStructure.roots[rowIDToIndexMap[row.parent.rowID]].versions.length
        // add the version under the specific root object
        nestedStructure.roots[rowIDToIndexMap[row.parent.rowID]].versions.push(version)
        break;
    }
    default:
        return version;
    }
    return version
}
function createRootObject (row, nestedStructure) {
    // create the root object
    rowIDToIndexMap[row.rowID] = nestedStructure.roots.length;
    const version = [createVersionObject(row)];
    const root = {
        context: {
            rowID: row.rowID
        },
        root: {
            ...row.subType && {subType: row.subType},
            ...row.title && {title: row.title}
        },
        versions: version
    }
    nestedStructure.roots.push(root)
}
export const convertFlattenedJSONToNested = (rows, file, rowCount) => {
    const nestedStructure = {
        context: {
            fileName: file.name,
            fileSize: file.size.toString(),
            rowCount: rowCount.toString()
        },
        roots: [],
        versions: [],
        presentations: [],
        seasons: [],
        episodes: []
    }
    for (const row of rows) {
        switch (row.operationType) {
        case 'register':
            createRootObject(row, nestedStructure);
            break;
        case 'registerVersion':
            if (row.parent.GnID === '') {
                createVersionObject(row, nestedStructure)
            } else {
                addToVersionArray(row, nestedStructure)
            }
            break;
        case 'registerPresentation':
            if (row.parent.GnID === '') {
                createPresentationObject(row, rows, nestedStructure);
            } else {
                addToPresentationArray(row, nestedStructure);
            }
            break;
        case 'registerSeason':
            if (row.parent.GnID === '') {
                createSeasonObject(row, rows, nestedStructure);
            } else {
                addToSeasonsArray(row, nestedStructure);
            }
            break;
        case 'registerEpisode':
            if (row.parent.GnID === '') {
                createEpisodeObject(row, rows, nestedStructure);
            } else {
                addToEpisodesArray(row, nestedStructure);
            }
            break;
        default:
            throw Error('Invalid operationType')
        }
    }

    return nestedStructure;
}