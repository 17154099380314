import React from 'react';
import PropTypes from 'prop-types';
import './ImportStatusIcon.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMPORT_STATUS_COLORS, IMPORT_STATUS_ICONS } from '../../../constants/ImportStatusIcon.js';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const ImportStatusIcon = ({status}) => {
    const icon = IMPORT_STATUS_ICONS[status] || 'inProgress';
    const color = IMPORT_STATUS_COLORS[status] || 'color-black';

    const getTooltipText = () => {
        const statusTooltips = {
            waiting: 'Waiting to process',
            inProgress: 'Registering in progress',
            succeeded: 'Registration completed',
            failed: 'Registration errors'
        };

        return statusTooltips[status];
    }

    return (
        <div className="import-icon circle">
            <span className="status-icon-container">
                <OverlayTrigger overlay={<Tooltip>{getTooltipText()}</Tooltip>} placement="right" trigger={['focus', 'hover']}>
                    <span className="fa-stack">
                        <FontAwesomeIcon className={`status-circle ${color} fa-stack-2x`} icon="circle" />
                        <FontAwesomeIcon className={`status-icon ${color} fa-stack-1x`} icon={icon} spin={status === 'inProgress' || status === 'waiting'}/>
                    </span>
                </OverlayTrigger>
            </span>
        </div>
    );
}

ImportStatusIcon.defaultProps = {
    status: "inProgress"
};

ImportStatusIcon.propTypes = {
    status: PropTypes.string
};

export default ImportStatusIcon;