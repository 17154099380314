import {
    GVAUTH_AUTH_UPDATE
} from '../actions/ActionTypes';
import { MOBIUS_ACCESS_TYPES, FEATURE_FLAGS } from '../constants/App';

export const GNVAUTH_STORE_NAME = 'gvauth';

/**
 * Initial State
 */
export const initialState = {
    fail: false,
    email: null,
    entitlements: [],
    errorMessage: null,
    token: null
};

/**
 * Reducer
 */
export const GNVAuthReducer = (state = initialState, action) => {
    switch (action.type) {
    case GVAUTH_AUTH_UPDATE:
        return {
            ...state,
            email: action.payload.email,
            entitlements: action.payload.entitlements,
            firstName: action.payload.firstName,
            group: action.payload.group,
            lastName: action.payload.lastName,
            token: action.payload.token
        };

    default:
        return state;
    }
};


/**
 * Selectors
 */

// GNView
export const gvauthSelToken = state => state[GNVAUTH_STORE_NAME].token;
export const gvauthIsBetaUser = state => state[GNVAUTH_STORE_NAME].entitlements?.global?.include?.roles?.includes('beta');

// Mobius
export const gvauthHasMobiusEntitlements = state => state[GNVAUTH_STORE_NAME].entitlements?.mobius?.include?.access?.length > 0 || state[GNVAUTH_STORE_NAME].entitlements?.mobius?.include?.sourceId?.length > 0;
export const gvauthSelMobiusSourceID = state => state[GNVAUTH_STORE_NAME].entitlements?.mobius?.include?.sourceId;
export const gvauthHasMobiusWriteAccess = state => state[GNVAUTH_STORE_NAME].entitlements?.mobius?.include?.access[0] === MOBIUS_ACCESS_TYPES.WRITE;
export const gvauthHasBulkUploadFeature = state => state[GNVAUTH_STORE_NAME].entitlements?.global?.include?.featureFlags?.includes(FEATURE_FLAGS.BULK_UPLOAD_MGMT);
export const gvauthHasUserSelectedMappingFeature = state => state[GNVAUTH_STORE_NAME].entitlements?.global?.include?.featureFlags?.includes(FEATURE_FLAGS.USER_SELECTED_MAPPING);

/**
 * Default export
 */
export default GNVAuthReducer;