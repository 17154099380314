import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convertLanguageFromCode } from '../../utils/GeneralUtils';
import './ProgramModalViewOnly.scss';
import GNClientTable from '../../components/common/gnClientTable/GNClientTable';
import GNNoDataMessage from '../../components/common/gnNoDataMessage/GNNoDataMessage';
import {
    MOBIUS_BUTTON_TEXT,
    MOBIUS_CAST_COLUMN_HEADERS,
    MOBIUS_CONFIRMATIONS,
    MOBIUS_CREW_COLUMN_HEADERS,
    MOBIUS_DESCRIPTIONS_COLUMN_HEADERS,
    MOBIUS_EXTERNAL_IDS_COLUMN_HEADERS,
    MOBIUS_LABELS,
    MOBIUS_NOTIFICATIONS,
    MOBIUS_RATINGS_COLUMN_HEADERS,
    PROGRAM_SIDE_MENU,
    MOBIUS_STATUS,
    MOBIUS_PROGRAM_TYPES_VALUES,
    LAST_UPDATED,
    MOBIUS_VALUES,
    MOBIUS_VOCABULARIES_LIST,
    MOBIUS_TOOLTIPS,
    MOBIUS_PROGRAM_READ_MODAL_IMAGE_HEADERS
} from '../../constants/Mobius';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { sortSeasons, NO_SEASON } from '../../utils/SeasonUtils';
import {
    mobiusGetEpisodesByPresentationIdAndSeasonId,
    mobiusGetSeasonsByPresentationId,
    mobiusRequestPublishProgram,
    gnidsUpdateEpisode
} from '../../actions/MobiusActions';
import { gnviewSendLogMessage } from '../../services/GeneralService';
import { PROGRAM_VIEW_MODAL_EPISODE_COLUMN_HEADERS } from '../../constants/MobiusListView';
import GNDropdown from '../../components/common/gnDropdown/GNDropdown';
import { gvauthSelMobiusSourceID, gvauthHasMobiusWriteAccess, gvauthHasUserSelectedMappingFeature } from '../../reducers/GNVAuthReducer';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import GNConfirmationModal from '../../components/common/gnConfirmationModal/GNConfirmationModal';
import GNNotification from '../../components/common/gnNotification/GNNotification';
import {
    mobiusVocabSelGnvocab
} from '../../reducers/MobiusVocabReducer';
import {
    convertVocab,
    formatDurationField
} from '../../utils/MobiusUtils';
import LoadingSpinner from '../../components/common/loadingSpinner/LoadingSpinner';
import GNButton from '../../components/common/gnButton/GNButton';
import PublishException from '../../components/mobius/publishException/PublishException';
import { gnidsProgram } from '../../reducers/GNIDSProgramModalReducer';
import { clearProgramModalData } from '../../actions/GNIDSProgramModalAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setGnidsImage, showImageModal } from '../../actions/GNIDSImageModalActions';
import { gnidsProgramImages } from '../../reducers/GNIDSProgramModalReducer';
import { getSelectedCatalogFromDropdown } from '../../reducers/GNIDSCatalogReducer';
import Mappings from './Mappings';
import classNames from 'classnames';

export const ProgramModalViewOnly = ({
    handleModal,
    handleNotification,
    setShowProgramModal,
    setIsLoading
}) => {
    const dispatch = useDispatch();
    const program = useSelector(gnidsProgram)
    const allDescriptionLangs = program?.descriptions?.map((desc) =>
        convertLanguageFromCode(desc.language)
    );
    const formattedExternalIds = program?.externalIDs?.map((id) => ({
        id: id.id,
        label: id.label,
        details: { isPrimary: id.isPrimary, isProvider: id.isProvider }
    }));
    const allMobiusVocabularies = useSelector(mobiusVocabSelGnvocab);
    const sourceId = useSelector(gvauthSelMobiusSourceID);
    const hasWriteAccess = useSelector(gvauthHasMobiusWriteAccess);
    const [showConfirmPublishModal, setShowConfirmPublishModal] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState(null);
    const [notificationIsSuccess, setNotificationIsSuccess] = useState(false);
    const [seasons, setSeasons] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState(seasons[0] || null);
    const [seasonNumber, setseasonNumber] = useState(seasons[0]?.value || null);
    const [seasonGnID, setSeasonGNID] = useState(seasons[0]?.gnID || null);
    const [isSeasonsLoading, setIsSeasonsLoading] = useState(true);
    const [episodes, setEpisodes] = useState([]);
    const [episodesLoading, setEpisodesLoading] = useState(true);
    const [publishLoading, setPublishLoading] = useState(false);
    const imagesData = useSelector(gnidsProgramImages);
    const isMovie = program?.type === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE;
    const selectedCatalog = useSelector(getSelectedCatalogFromDropdown);
    const userSelectedMappingFeatureEnabled = useSelector(gvauthHasUserSelectedMappingFeature);

    if (!userSelectedMappingFeatureEnabled) {
        delete PROGRAM_SIDE_MENU.MAPPINGS;
    }

    const disableRequestPublishButton =
        program?.publishPrereqs?.length > 0 ||
        program?.publishingStatus === MOBIUS_STATUS.REQUESTED ||
        program?.publishingStatus === MOBIUS_STATUS.PUBLISHING ||
        program?.publishingStatus === MOBIUS_STATUS.PUBLISHED;

    const formattedRatings = program?.ratings?.map((rating) => ({
        ratingBody: convertVocab(
            allMobiusVocabularies,
            rating.ratingBody,
            MOBIUS_VOCABULARIES_LIST.RATING_BODY
        ),
        advisories: rating.advisories,
        details: {
            notYetRated: rating.notYetRated,
            ratingExempt: rating.ratingExempt,
            rating: rating.rating
        }
    }));

    useEffect(() => {
        if (program?.type === MOBIUS_PROGRAM_TYPES_VALUES.SHOW && !seasonNumber && program && sourceId?.length > 0) {
            // Get all seasons for a Show program
            dispatch(mobiusGetSeasonsByPresentationId(program?.gnID, sourceId))
                .then((response) => {
                    if (response?.result?.data) {
                        const sortedSeasons = sortSeasons(response.result.data);
                        setSeasons(sortedSeasons);
                        const defaultSeason = sortedSeasons?.[0];
                        setSelectedSeason(defaultSeason || null)
                        setseasonNumber(defaultSeason ? defaultSeason?.seasonNumber || NO_SEASON : null);
                        setSeasonGNID(defaultSeason?.gnID || null);
                        setIsSeasonsLoading(false);
                    }
                })
                .catch((error) => {
                    dispatch(
                        gnviewSendLogMessage(
                            `mobiusGetSeasonsByPresentationId error: ${error.message}`,
                            error,
                            program.gnID
                        )
                    );
                    setIsSeasonsLoading(false);
                    setNotification(true, MOBIUS_NOTIFICATIONS.SEASONS_ERROR_GET, false);
                });
        }
        if (!program) {
            setIsSeasonsLoading(false);
        }
    }, [dispatch, isMovie, program, seasonNumber, sourceId]);

    useEffect(() => {
        if (program?.gnID?.length > 0 && seasonGnID && sourceId?.length > 0) {
            setEpisodesLoading(true);
            // Get all episodes for a Show program
            dispatch(
                mobiusGetEpisodesByPresentationIdAndSeasonId(
                    program.gnID,
                    seasonGnID,
                    sourceId,
                    seasonNumber,
                    selectedCatalog
                )
            )
                .then((response) => {
                    setEpisodes(response.result.data);
                    setEpisodesLoading(false);
                })
                .catch((error) => {
                    setEpisodesLoading(false);
                    dispatch(
                        gnviewSendLogMessage(
                            `mobiusGetEpisodesByPresentationIdAndSeasonId error: ${error.message}`,
                            error
                        )
                    );
                });
        }
    }, [dispatch, program, seasonGnID, seasonNumber, sourceId, selectedCatalog]);

    const handleSeasonChange = (seasonId) => {
        const newSeason = seasons.filter((season) => season.gnID === seasonId);
        const defaultSeason = newSeason?.[0];
        setseasonNumber(defaultSeason ? defaultSeason?.seasonNumber || NO_SEASON : null);
        setSelectedSeason(defaultSeason || null);
        setSeasonGNID(defaultSeason?.gnID || null);
    };

    const episodeColumns = [...PROGRAM_VIEW_MODAL_EPISODE_COLUMN_HEADERS];
    const titleIndex = episodeColumns.findIndex((col) => col.id === 'title');
    if (titleIndex !== -1) {
        episodeColumns[titleIndex] = {
            ...episodeColumns[titleIndex],
            /* eslint-disable react/display-name */
            /* eslint-disable react/prop-types */
            Cell: ({ row }) => (
                <span
                    className='episode-title'
                    onClick={() => {
                        setIsLoading(true);
                        dispatch(gnidsUpdateEpisode(row?.original?.showVersionGnID, row?.original?.showPresentationGnID, program?.title?.value, row?.original?.seasonGnID, seasonNumber))
                        handleModal(row?.original, true, row?.original?.showPresentationGnID, seasonNumber, false);
                    }}
                >
                    {row.values.title.value}
                </span>
            )
            /* eslint-enable react/display-name */
            /* eslint-enable react/prop-types */
        };
    }

    const imagesColumns = [...MOBIUS_PROGRAM_READ_MODAL_IMAGE_HEADERS];
    const imageTableTitleIndex = imagesColumns.findIndex((col) => col.id === 'title');
    if (imageTableTitleIndex !== -1) {
        imagesColumns[imageTableTitleIndex] = {
            ...imagesColumns[imageTableTitleIndex],
            /* eslint-disable react/display-name */
            /* eslint-disable react/prop-types */
            Cell: ({ row }) => (
                <span
                    className='image-title'
                    onClick={() => {
                        handleModal(null, false);
                        dispatch(showImageModal(true))
                        dispatch(setGnidsImage(row?.original))
                    }}
                >
                    {row?.original?.title}
                </span>
            )
            /* eslint-enable react/display-name */
            /* eslint-enable react/prop-types */
        };
    }

    const setNotification = (show, message, isSuccess) => {
        setShowNotification(show);
        setNotificationMsg(message);
        setNotificationIsSuccess(isSuccess);
    };

    const handleRequestPublishConfirmToggle = (showConfirm, showProgram) => {
        setShowConfirmPublishModal(showConfirm);
        setShowProgramModal(showProgram);
    };

    const handleRequestPublish = () => {
        setPublishLoading(true);
        dispatch(mobiusRequestPublishProgram(program.gnID, sourceId, program.internalAttributes))
            .then(() => {
                handleModal(program, false, null, null, false, true);
                handleNotification(true, MOBIUS_NOTIFICATIONS.PROGRAM_SUCCESS_PUBLISHED, true);
                setPublishLoading(false);
                handleRequestPublishConfirmToggle(false, false);
                dispatch(clearProgramModalData());
            })
            .catch((error) => {
                handleModal(program, true, null, null, false);
                dispatch(
                    gnviewSendLogMessage(
                        `mobiusRequestPublishProgram error: ${error.message}`,
                        error,
                        program.gnID
                    )
                );
                setNotification(true, MOBIUS_NOTIFICATIONS.PROGRAM_ERROR_PUBLISHED, false);
                setPublishLoading(false);
                handleRequestPublishConfirmToggle(false, true);
            });
    };

    const presentationReleaseDatesComponent = (
        <React.Fragment>
            <div className='release-details-item'>
                <div className='program-modal-value-header'>
                    {MOBIUS_LABELS.PRESENTATION_RELEASE_DATE}
                </div>
                <div className='program-modal-value-item'>
                    {program?.releaseDate ? moment(program?.releaseDate).format('MMM D, YYYY') : MOBIUS_VALUES.EMPTY_DATA}
                </div>
            </div>
            {isMovie && (
                <div className='release-details-item'>
                    <div className='program-modal-value-header'>
                        {MOBIUS_LABELS.PRESENTATION_RELEASE_YEAR}
                    </div>
                    <div className='program-modal-value-item'>{program?.releaseYear || MOBIUS_VALUES.EMPTY_DATA}</div>
                </div>
            )}
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <div className='program-modal-view-main-container' data-testid='program-modal-view-main-container'>
                {program?.publishExceptions &&
                    <PublishException
                        publishExceptions={program?.publishExceptions}
                    />
                }
                <div className='overview-section' id={PROGRAM_SIDE_MENU.OVERVIEW.id}>
                    <div className='program-modal-tab-header'>{MOBIUS_LABELS.OVERVIEW}</div>
                    {!program ? (
                        <p className='no-data-message'>{MOBIUS_LABELS.NO_DATA_ENTERED}</p>
                    ) : (
                        <div className='program-modal-tab-body'>
                            <div className='type-section'>
                                <div className='type-section-item'>
                                    <div className='program-modal-value-header'>
                                        {MOBIUS_LABELS.PROGRAM_TYPE}
                                    </div>
                                    <div className='program-modal-value-item'>
                                        {convertVocab(
                                            allMobiusVocabularies,
                                            program?.type,
                                            MOBIUS_VOCABULARIES_LIST.PROGRAM_TYPE
                                        ) || MOBIUS_VALUES.EMPTY_DATA}
                                    </div>
                                </div>
                                <div className='type-section-item'>
                                    <div className='program-modal-value-header'>
                                        {MOBIUS_LABELS.PROGRAM_SUBTYPE}
                                    </div>
                                    <div className='program-modal-value-item'>
                                        {convertVocab(
                                            allMobiusVocabularies,
                                            program?.subType,
                                            MOBIUS_VOCABULARIES_LIST.PROGRAM_TYPE,
                                            true,
                                            true,
                                            program?.type
                                        ) || MOBIUS_VALUES.EMPTY_DATA}
                                    </div>
                                </div>
                                <div className='type-section-item'>
                                    <div className='program-modal-value-header'>
                                        {MOBIUS_LABELS.VERSION_LABELS}
                                        {program?.type === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE && (
                                            <span className="inherited-text"> {MOBIUS_LABELS.INHERITED}</span>
                                        )}
                                    </div>
                                    {
                                        (program?.versions?.versionLabels?.[0]?.length > 0 || program?.versionLabels?.[0]?.length > 0) ? (
                                            <div className='program-modal-value-item'>
                                                {program?.versions?.versionLabels?.join(', ') || program?.versionLabels?.join(', ')}
                                            </div>
                                        ) : (
                                            <span>{MOBIUS_VALUES.EMPTY_DATA}</span>
                                        )
                                    }
                                </div>
                                <div className='type-section-item'>
                                    <div className='program-modal-value-header'>
                                        {MOBIUS_LABELS.PRESENTATION_LABELS}
                                        {program?.type === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE && (
                                            <span className="inherited-text"> {MOBIUS_LABELS.INHERITED}</span>
                                        )}
                                    </div>
                                    <div className='program-modal-value-item'>
                                        {program?.presentationLabels?.join(', ') || MOBIUS_VALUES.EMPTY_DATA}
                                    </div>
                                </div>
                            </div>
                            <div className='title-section'>
                                <div className='title-title-section'>
                                    <div className='program-modal-value-header'>
                                        {MOBIUS_LABELS.TITLE}
                                    </div>
                                    <div className='program-modal-value-item'>
                                        {program?.title?.value || MOBIUS_VALUES.EMPTY_DATA}
                                    </div>
                                </div>
                                <div className='title-language-section'>
                                    <div className='program-modal-value-header'>
                                        {MOBIUS_LABELS.TITLE_LANGUAGE}
                                    </div>
                                    <div className='program-modal-value-item'>
                                        {convertLanguageFromCode(program?.title?.language) || MOBIUS_VALUES.EMPTY_DATA}
                                    </div>
                                </div>
                            </div>
                            <div className='genres-section'>
                                <div className='program-modal-value-header'>
                                    {MOBIUS_LABELS.GENRES}
                                </div>
                                {program?.genres?.length > 0 ? (
                                    <div className='program-modal-value-item'>
                                        {program?.genres.join(', ')}
                                    </div>
                                ) : (
                                    <span>{MOBIUS_VALUES.EMPTY_DATA}</span>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {program?.type === MOBIUS_PROGRAM_TYPES_VALUES.SHOW && (
                    <div
                        className='program-modal-section seasons-and-episodes'
                        id={PROGRAM_SIDE_MENU.SEASONS_EPISODES.id}
                    >
                        <div className='program-modal-tab-header'>
                            {MOBIUS_LABELS.SEASONS_AND_EPISODES}
                        </div>
                        {isSeasonsLoading && <LoadingSpinner />}
                        {!isSeasonsLoading && !seasons?.length ? (
                            <div className='no-seasons-and-episodes-message'>
                                <GNNoDataMessage programType={MOBIUS_PROGRAM_TYPES_VALUES.SEASON} modal={true} />
                            </div>
                        ) : (
                            <div>
                                {seasons && seasonGnID && (
                                    <div className='program-modal-tab-body'>
                                        <div className='season-and-episode-header'>
                                            <GNDropdown
                                                label={MOBIUS_LABELS.SEASONS_DROPDOWN_LABEL}
                                                options={seasons}
                                                value={seasonGnID}
                                                handleChange={(seasonId) => handleSeasonChange(seasonId)}
                                            />
                                            <div className='season-subtype'>
                                                <div className='program-modal-value-header'>
                                                    {MOBIUS_LABELS.SEASON_SUBTYPE}
                                                </div>
                                                <div className='program-modal-value-item'>
                                                    {selectedSeason?.subType
                                                        ? convertVocab(
                                                            allMobiusVocabularies,
                                                            selectedSeason.subType,
                                                            MOBIUS_VOCABULARIES_LIST.PROGRAM_TYPE,
                                                            true,
                                                            true,
                                                            MOBIUS_PROGRAM_TYPES_VALUES.SEASON
                                                        )
                                                        : MOBIUS_VALUES.EMPTY_DATA}
                                                </div>
                                            </div>
                                            <div className='season-finale-date'>
                                                <div className='program-modal-value-header'>
                                                    {MOBIUS_LABELS.FINALE_DATE}
                                                </div>
                                                <div className='program-modal-value-item'>
                                                    {selectedSeason?.finaleDate ? (
                                                        moment(selectedSeason?.finaleDate).format(
                                                            'MMM D, YYYY'
                                                        )
                                                    ) : (
                                                        MOBIUS_VALUES.EMPTY_DATA
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='season-description-container'>
                                            <div className='season-description-title'>
                                                {MOBIUS_LABELS.SEASON_DESCRIPTION}
                                            </div>
                                            <div className='season-description-language'>
                                                <div className='program-modal-value-header'>
                                                    {MOBIUS_LABELS.DESCRIPTION_LANGUAGE}
                                                </div>
                                                <div className='program-modal-value-item'>
                                                    {selectedSeason?.descriptions?.[0]?.language ? (
                                                        convertLanguageFromCode(selectedSeason?.descriptions[0]?.language)
                                                    ) : (
                                                        MOBIUS_VALUES.EMPTY_DATA
                                                    )}
                                                </div>
                                            </div>
                                            {seasonGnID && selectedSeason?.descriptions?.length > 0 ? (
                                                <GNClientTable
                                                    className='season-description-table'
                                                    columns={MOBIUS_DESCRIPTIONS_COLUMN_HEADERS}
                                                    data={selectedSeason?.descriptions.sort((a, b) => b.length - a.length)}
                                                />
                                            ) : (
                                                <div className='season-description-no-data'>
                                                    <div className='program-modal-value-header'>{MOBIUS_LABELS.DESCRIPTIONS}</div>
                                                    <div className='program-modal-value-item'>{MOBIUS_VALUES.EMPTY_DATA}</div>
                                                </div>
                                            )}
                                        </div>
                                        {seasonGnID && (
                                            <GNClientTable
                                                className='season-and-episode-table'
                                                columns={episodeColumns}
                                                data={episodes}
                                                isLoading={episodesLoading}
                                                pagination={true}
                                                paginationBottom={true}
                                                noDataMessage={
                                                    <GNNoDataMessage programType={MOBIUS_PROGRAM_TYPES_VALUES.EPISODE} modal={true} />
                                                }
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
                <div
                    className='program-modal-section release-details'
                    id={PROGRAM_SIDE_MENU.RELEASE_DETAILS.id}
                >
                    <div className='program-modal-tab-header'>{MOBIUS_LABELS.RELEASE_DETAILS}</div>
                    {!program ? (
                        <p className='no-data-message'>{MOBIUS_LABELS.NO_DATA_ENTERED}</p>
                    ) : (
                        <div className='program-modal-tab-body'>
                            <div className='release-details-body'>
                                <div className='first-row'>
                                    <div className='release-details-item'>
                                        <div className='program-modal-value-header'>
                                            {MOBIUS_LABELS.DURATION}
                                        </div>
                                        <div className='program-modal-value-item'>
                                            {program?.versions?.duration || program?.duration ? formatDurationField(program?.versions?.duration || program?.duration) : MOBIUS_VALUES.EMPTY_DATA}
                                        </div>
                                    </div>
                                    <div className='release-details-item'>
                                        <div className='program-modal-value-header'>
                                            {MOBIUS_LABELS.ORIGINAL_SOURCE}
                                        </div>
                                        <div className='program-modal-value-item'>
                                            {program?.originalSource || MOBIUS_VALUES.EMPTY_DATA}
                                        </div>
                                    </div>
                                    <div className='release-details-item'>
                                        <div className='program-modal-value-header'>
                                            {MOBIUS_LABELS.COLOR}
                                        </div>
                                        <div className='program-modal-value-item'>
                                            {convertVocab(
                                                allMobiusVocabularies,
                                                program?.versions?.color || program?.color,
                                                MOBIUS_VOCABULARIES_LIST.COLOR
                                            ) || MOBIUS_VALUES.EMPTY_DATA}
                                        </div>
                                    </div>
                                </div>
                                <div className='second-row'>
                                    {program?.type !== MOBIUS_PROGRAM_TYPES_VALUES.EPISODE && (
                                        <div className='release-details-item'>
                                            <div className='program-modal-value-header'>
                                                {MOBIUS_LABELS.VERSION_RELEASE_DATE}
                                            </div>
                                            <div className='program-modal-value-item'>
                                                {program?.versions?.releaseDate ?
                                                    moment(program?.versions?.releaseDate).format(
                                                        'MMM D, YYYY'
                                                    ) : MOBIUS_VALUES.EMPTY_DATA}
                                            </div>
                                        </div>
                                    )}
                                    {isMovie && (
                                        <div className='release-details-item'>
                                            <div className='program-modal-value-header'>
                                                {MOBIUS_LABELS.VERSION_RELEASE_YEAR}
                                            </div>
                                            <div className='program-modal-value-item'>
                                                {program?.versions?.releaseYear || MOBIUS_VALUES.EMPTY_DATA}
                                            </div>
                                        </div>
                                    )}
                                    {!isMovie && presentationReleaseDatesComponent}
                                </div>
                                {isMovie && (
                                    <div className='third-row'>
                                        {presentationReleaseDatesComponent}
                                    </div>
                                )}
                                {program?.type === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE && (
                                    <div className='third-row'>
                                        <div className='release-details-item'>
                                            <div className='program-modal-value-header'>
                                                {MOBIUS_LABELS.PART_NUMBER}
                                            </div>
                                            <div className='program-modal-value-item'>
                                                {program?.partNumbers || MOBIUS_VALUES.EMPTY_DATA}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {program?.type === MOBIUS_PROGRAM_TYPES_VALUES.SHOW && (
                                    <div className='fourth-row'>
                                        <div className='release-details-item'>
                                            <div className='program-modal-value-header'>
                                                {MOBIUS_LABELS.FINALE_DATE}
                                            </div>
                                            <div className='program-modal-value-item'>
                                                {program?.finaleDate ? moment(program?.finaleDate).format('MMM D, YYYY') : MOBIUS_VALUES.EMPTY_DATA}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className='fifth-row'>
                                    <div className='release-details-item'>
                                        <div className='program-modal-value-header'>
                                            {MOBIUS_LABELS.TARGET_AUDIENCE}
                                        </div>
                                        <div className='program-modal-value-item'>
                                            {convertVocab(
                                                allMobiusVocabularies,
                                                program?.targetAudience,
                                                MOBIUS_VOCABULARIES_LIST.TARGET_AUDIENCE
                                            ) || MOBIUS_VALUES.EMPTY_DATA}
                                        </div>
                                    </div>
                                </div>
                                {program?.type === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE && (
                                    <div className='sixth-row'>
                                        <div className='release-details-item'>
                                            <div className='program-modal-value-header'>
                                                {MOBIUS_LABELS.EPISODE_NUMBER}
                                            </div>
                                            <div className='program-modal-value-item'>
                                                {program?.episodeNumber || MOBIUS_VALUES.EMPTY_DATA}
                                            </div>
                                        </div>
                                        <div className='release-details-item'>
                                            <div className='program-modal-value-header'>
                                                {MOBIUS_LABELS.INDUSTRY_NETWORK_NUMBER}
                                            </div>
                                            <div className='program-modal-value-item'>
                                                {program?.industryNetworkNumber || MOBIUS_VALUES.EMPTY_DATA}
                                            </div>
                                        </div>
                                        <div className='release-details-item'>
                                            <div className='program-modal-value-header'>
                                                {MOBIUS_LABELS.INDUSTRY_SYNDICATED_NUMBER}
                                            </div>
                                            <div className='program-modal-value-item'>
                                                {program?.industryNetworkSyndicated || MOBIUS_VALUES.EMPTY_DATA}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div
                    className='program-modal-section external-ids'
                    id={PROGRAM_SIDE_MENU.EXTERNAL_IDS.id}
                >
                    <div className='program-modal-tab-header'>{MOBIUS_LABELS.EXTERNAL_IDS}</div>
                    {!program?.externalIDs?.length > 0 && !formattedExternalIds?.length > 0 ? (
                        <p className='no-data-message'>{MOBIUS_LABELS.NO_DATA_ENTERED}</p>
                    ) : (
                        <div className='program-modal-tab-body'>
                            <GNClientTable
                                className='external-ids-table'
                                columns={MOBIUS_EXTERNAL_IDS_COLUMN_HEADERS}
                                data={formattedExternalIds}
                                pagination={true}
                                paginationBottom={true}
                            />
                        </div>
                    )}
                </div>
                {userSelectedMappingFeatureEnabled && <div className={classNames('program-modal-section')} id={PROGRAM_SIDE_MENU.MAPPINGS?.id}>
                    <div className='program-modal-tab-header'>
                        {MOBIUS_LABELS.MAPPINGS}
                        <OverlayTrigger overlay={<Tooltip>{MOBIUS_TOOLTIPS.MAPPINGS_INFO}</Tooltip>} placement="right">
                            <FontAwesomeIcon icon='circle-info' />
                        </OverlayTrigger>
                    </div>
                    <div className='program-modal-tab-body'>
                        <Mappings values={program} viewOnly />
                    </div>
                </div>}
                <div className='program-modal-section ratings' id={PROGRAM_SIDE_MENU.RATINGS.id}>
                    <div className='program-modal-tab-header'>{MOBIUS_LABELS.RATINGS}</div>
                    {!program?.ratings?.length > 0 ? (
                        <p className='no-data-message'>{MOBIUS_LABELS.NO_DATA_ENTERED}</p>
                    ) : (
                        <div className='program-modal-tab-body'>
                            <GNClientTable
                                className='ratings-table'
                                columns={MOBIUS_RATINGS_COLUMN_HEADERS}
                                data={formattedRatings}
                                pagination={true}
                                paginationBottom={true}
                            />
                        </div>
                    )}
                </div>
                <div
                    className='program-modal-section descriptions'
                    id={PROGRAM_SIDE_MENU.DESCRIPTIONS.id}
                >
                    <div className='program-modal-tab-header'>{MOBIUS_LABELS.DESCRIPTIONS}</div>
                    {!program?.descriptions?.length > 0 ? (
                        <p className='no-data-message'>{MOBIUS_LABELS.NO_DATA_ENTERED}</p>
                    ) : (
                        <div className='program-modal-tab-body'>
                            <div className='program-modal-value-header'>
                                {MOBIUS_LABELS.DESCRIPTION_LANGUAGE}
                            </div>
                            <div className='program-modal-value-item'>
                                {allDescriptionLangs
                                    .filter(
                                        (item, idx) => allDescriptionLangs.indexOf(item) === idx
                                    )
                                    .join(', ')}
                            </div>
                            <GNClientTable
                                className='descriptions-table'
                                columns={MOBIUS_DESCRIPTIONS_COLUMN_HEADERS}
                                data={program?.descriptions.sort((a, b) => b.length - a.length)}
                            />
                        </div>
                    )}
                </div>
                <div
                    className='program-modal-section production'
                    id={PROGRAM_SIDE_MENU.PRODUCTION.id}
                >
                    <div className='program-modal-tab-header'>{MOBIUS_LABELS.PRODUCTION}</div>
                    {!(
                        program?.productionCompanies?.length > 0 ||
                        program?.productionCountries?.length > 0
                    ) ? (
                            <p className='no-data-message'>{MOBIUS_LABELS.NO_DATA_ENTERED}</p>
                        ) : (
                            <div className='program-modal-tab-body'>
                                <div className='production-body'>
                                    {program.type === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE && <div className='production-status'>
                                        <div className='program-modal-value-header'>
                                            {MOBIUS_LABELS.PRODUCTION_STATUS}
                                        </div>
                                        <div className='program-modal-value-item'>
                                            {convertVocab(
                                                allMobiusVocabularies,
                                                program?.productionStatus,
                                                MOBIUS_VOCABULARIES_LIST.PRODUCTION_STATUS
                                            ) || MOBIUS_VALUES.EMPTY_DATA}
                                        </div>
                                    </div>
                                    }
                                    <div className='production-countries'>
                                        <div className='program-modal-value-header'>
                                            {MOBIUS_LABELS.PRODUCTION_COUNTRIES}
                                        </div>
                                        <div className='program-modal-value-item'>
                                            {program?.productionCountries
                                                ?.map((country) =>
                                                    convertVocab(
                                                        allMobiusVocabularies,
                                                        country,
                                                        MOBIUS_VOCABULARIES_LIST.COUNTRIES
                                                    )
                                                )
                                                .join(', ') || MOBIUS_VALUES.EMPTY_DATA}
                                        </div>
                                    </div>
                                    <div className='production-companies'>
                                        <div className='program-modal-value-header'>
                                            {MOBIUS_LABELS.PRODUCTION_COMPANIES}
                                        </div>
                                        <div className='program-modal-value-item'>
                                            {program?.productionCompanies?.join(', ') || MOBIUS_VALUES.EMPTY_DATA}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
                <div className='program-modal-section cast-crew' id={PROGRAM_SIDE_MENU.CAST.id}>
                    <div className='program-modal-tab-header'>{MOBIUS_LABELS.CAST}</div>
                    {!program?.cast?.length ? (
                        <p className='no-data-message'>{MOBIUS_LABELS.NO_DATA_ENTERED}</p>
                    ) : (
                        <div className='program-modal-tab-body'>
                            <GNClientTable
                                className='cast-table'
                                columns={MOBIUS_CAST_COLUMN_HEADERS}
                                data={program.cast.map((castMember) => {
                                    const role = convertVocab(
                                        allMobiusVocabularies,
                                        castMember.role,
                                        MOBIUS_VOCABULARIES_LIST.CAST_TYPE
                                    );
                                    return { ...castMember, role };
                                })}
                                pagination={true}
                                paginationBottom={true}
                            />
                        </div>
                    )}
                </div>
                <div className='program-modal-section cast-crew' id={PROGRAM_SIDE_MENU.CREW.id}>
                    <div className='program-modal-tab-header'>{MOBIUS_LABELS.CREW}</div>
                    {!program?.crew?.length ? (
                        <p className='no-data-message'>{MOBIUS_LABELS.NO_DATA_ENTERED}</p>
                    ) : (
                        <div className='program-modal-tab-body'>
                            <GNClientTable
                                className='crew-table'
                                columns={MOBIUS_CREW_COLUMN_HEADERS}
                                data={program.crew.map((crewMember) => {
                                    const type = convertVocab(
                                        allMobiusVocabularies,
                                        crewMember.type,
                                        MOBIUS_VOCABULARIES_LIST.CREW_TYPE
                                    );
                                    return { ...crewMember, type };
                                })}
                                pagination={true}
                                paginationBottom={true}
                            />
                        </div>
                    )}
                </div>
                <div className={classNames('program-modal-section images', 'last-section')} id={PROGRAM_SIDE_MENU.IMAGES.id}>
                    <div className='program-modal-tab-header'>
                        {MOBIUS_LABELS.IMAGES}
                        <OverlayTrigger overlay={<Tooltip>{MOBIUS_TOOLTIPS.IMAGE_SECTION}</Tooltip>} placement="right">
                            <FontAwesomeIcon icon='circle-info' />
                        </OverlayTrigger>
                    </div>
                    {!imagesData?.length ? (
                        <p className='no-data-message'>{MOBIUS_LABELS.NO_DATA_ENTERED}</p>
                    ) : (
                        <div className='program-modal-tab-body'>
                            <GNClientTable
                                className='image-table'
                                columns={imagesColumns}
                                data={imagesData}
                                pagination={true}
                                settings={{
                                    pageSize: 10
                                }}
                                paginationBottom={true}
                            />
                        </div>
                    )}
                </div>
                <div className='bottom-section'>
                    {program && (
                        <div className='button-container'>
                            <GNButton tooltip={<Tooltip id="request-publish-button-tooltip"> {MOBIUS_TOOLTIPS.REQUIRES_WRITE_ACCESS}</Tooltip>}
                                trigger={!hasWriteAccess ? ['hover'] : null}
                                onClick={() => handleRequestPublishConfirmToggle(true, false)}
                                disabled={!hasWriteAccess || disableRequestPublishButton}
                                buttonClass='request-publish-button'
                                tooltipPlacement='top'
                                hasWriteAccess={hasWriteAccess}
                            >
                                {MOBIUS_BUTTON_TEXT.REQUEST_PUBLISH}
                            </GNButton>
                        </div>
                    )}
                    <div className='bottom-section__last-update'>
                        {program?.updated &&
                            (<span>{LAST_UPDATED}: {moment(program?.updated).format('MMM D, YYYY h:mma')}</span>)
                        }
                    </div>
                </div>
            </div>
            <GNConfirmationModal
                cancelButtonHandler={() => handleRequestPublishConfirmToggle(false, true)}
                cancelButtonText={MOBIUS_BUTTON_TEXT.CANCEL}
                isLoading={publishLoading}
                message={MOBIUS_CONFIRMATIONS.REQUEST_PUBLISH_CONFIRMATION_MESSAGE}
                show={showConfirmPublishModal}
                submitButtonHandler={() => handleRequestPublish()}
                submitButtonText={MOBIUS_BUTTON_TEXT.REQUEST_PUBLISH}
                title={MOBIUS_CONFIRMATIONS.REQUEST_PUBLISH_CONFIRMATION_TITLE}
            />
            {showNotification && <GNNotification
                handleShow={setShowNotification}
                message={notificationMsg}
                milliseconds={5000}
                show={showNotification}
                success={notificationIsSuccess}
            />}
        </React.Fragment>
    );
};

ProgramModalViewOnly.propTypes = {
    handleModal: PropTypes.func,
    handleNotification: PropTypes.func.isRequired,
    setShowProgramModal: PropTypes.func,
    setIsLoading: PropTypes.func
};

export default ProgramModalViewOnly;