import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './GNIDDistribution.scss';
import { MOBIUS_BUTTON_TEXT, MOBIUS_TOOLTIPS, MOBIUS_PROGRAM_TYPES_VALUES, MOBIUS_VOCABULARIES_LIST, MOBIUS_MOMENT_DATE_FORMAT, MOBIUS_CONFIRMATIONS, MOBIUS_NOTIFICATIONS} from '../constants/Mobius';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import GNServerTable from '../components/common/gnServerTable/GNServerTable';
import GNNoDataMessage from '../components/common/gnNoDataMessage/GNNoDataMessage';
import {
    PROGRAM_LIST_VIEW_COLUMN_HEADERS,
    PROGRAM_LIST_VIEW_FILTERS,
    MOBIUS_PROGRAM_FILTER_TYPES,
    LIST_VIEW_PAGE_LAST_UPDATED
} from '../constants/MobiusListView';
import { mobiusGetAllPrograms, mobiusGetGNVocabulary, mobiusGetEntitlements, mobiusGetAllCatalogs, gnidsUpdateEpisode } from '../actions/MobiusActions';
import { gnviewSendLogMessage } from '../services/GeneralService';
import ProgramModal from './programModal/ProgramModal';
import ErrorBoundary from '../components/common/errorBoundary/ErrorBoundary';
import MobiusStatusIcon from '../components/mobius/mobiusStatusIcon/MobiusStatusIcon';
import GNBadge from '../components/common/gnBadge/GNBadge';
import GNInput from '../components/common/gnInput/GNInput';
import GNButton from '../components/common/gnButton/GNButton';
import moment from 'moment-timezone';
import SeasonAndEpisodeExpand from './SeasonAndEpisodeExpand';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gvauthSelMobiusSourceID, gvauthHasMobiusWriteAccess } from '../reducers/GNVAuthReducer';
import { mobiusVocabHasTMSEntitlements, mobiusVocabSelGnvocab } from '../reducers/MobiusVocabReducer';
import { convertVocab } from '../utils/MobiusUtils';
import GNNotification from '../components/common/gnNotification/GNNotification';
import SeasonModal from './programModal/SeasonModal';
import { gnidsSeasonModalShow } from '../reducers/GNIDSSeasonModalReducer';
import TablePopover from '../components/common/popover/TablePopover';
import { setGnidsProgramRelatedData } from '../actions/GNIDSProgramModalAction';
import ViewSwitcher from '../components/common/viewSwitcher/ViewSwitcher';
import { isImageModalOpen, gnidsImage } from '../reducers/GNIDSImageModalReducer';
import ImageModal from './imageModal/ImageModal';
import {gnidsProgramModalProgram, gnidsSelectBreadcrumbToEditMode } from '../reducers/GNIDSProgramModalReducer';
import { showImageModal } from '../actions/GNIDSImageModalActions';
import GNRowCheckbox from '../components/common/gnRowCheckbox/GNRowCheckbox';
import GNHeaderCheckbox from '../components/common/gnHeaderCheckbox/GNHeaderCheckbox';
import GNDropdown from '../components/common/gnDropdown/GNDropdown';
import { getGNIDSAllCatalogs, getSelectedCatalogFromDropdown } from '../reducers/GNIDSCatalogReducer';
import GNDropdownWithButton from '../components/common/gnDropdownWithButton/GNDropdownWithButton';
import { ADD, ALL_CATALOGS, CATALOG_PROGRAM_ERRORS_CSV_NAME, ERROR_FIELD_TO_JSON_HEADER_MAPPING, NAME, SEARCH_CATALOGS } from '../constants/Program';
import GNConfirmationModal from '../components/common/gnConfirmationModal/GNConfirmationModal';
import { gnidsAddProgramsToCatalogs, gnidsRemoveProgramsFromCatalog } from '../actions/GNIDSCatalogProgramMappingActions';
import { gnidsCurrentPrograms } from '../reducers/GNIDSTableReducer';
import GNErrorModal from '../components/common/gnErrorModal/GNErrorModal';
import { setSelectedCatalogFromDropdown } from '../actions/GNIDSCatalogModalActions';
import { parseJSONtoCSV } from '../utils/JSONtoCSVUtils';
import { CSVDownload } from '../utils/CSVDownloadUtils';
import RegisterProgramModal from './bulkUploadMultiForm/RegisterProgramModal';
import { gnidsClearProgramFiles } from '../actions/GNIDSProgramFilesActions';
import { GNGetHelp } from '../components/common/gnGetHelp/GNGetHelp';
import { PROGRAM_BULK_UPLOAD } from '../constants/BulkUpload';
import { REFRESH_BUTTON_TEXT } from '../constants/Imports';

export const GNIDDistribution = () => {
    const dispatch = useDispatch();
    const [vocabIsLoading, setVocabIsLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [refreshState, setRefreshState] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [filters, setFilters] = useState([]);
    // Current program title search
    const [programTitleSearch, setProgramTitleSearch] = useState('');
    // A separate variable, that we set only after pressing 'Search' button that we can watch for changes, otherwise we will fire the API call on every key press
    const [programTitleSearchSubmit, setProgramTitleSearchSubmit] = useState('');
    const [pageLastUpdated, setPageLastUpdated] = useState(moment());
    const [isRegisterModal, setIsRegisterModal] = useState(false);
    const sourceId = useSelector(gvauthSelMobiusSourceID);
    const allMobiusVocabularies = useSelector(mobiusVocabSelGnvocab);
    const hasTMSEntitlements = useSelector(mobiusVocabHasTMSEntitlements);
    const hasWriteAccess = useSelector(gvauthHasMobiusWriteAccess);
    // Flag used to trigger unmounting the GNServerTable when filters are clicked
    const [shouldResetTable, setShouldResetTable] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState(null);
    const [notificationIsSuccess, setNotificationIsSuccess] = useState(false);
    const [registerPresentationMode, setRegisterPresentationMode] = useState(false);
    const [registerVersionMode, setRegisterVersionMode] = useState(false);
    const [refreshRowSubComponent, setRefreshRowSubComponent] = useState(false);
    const showSeasonModal = useSelector(gnidsSeasonModalShow);
    const shouldShowImageModal = useSelector(isImageModalOpen);
    const selectedProgram = useSelector(gnidsProgramModalProgram);
    const selectedImageModalData = useSelector(gnidsImage);
    const [programModalEditMode, setProgramModalEditMode] = useState(false);
    const breadcrumbToEditMode = useSelector(gnidsSelectBreadcrumbToEditMode);
    const currentPrograms = useSelector(gnidsCurrentPrograms)
    const [selectedProgramIds, setSelectedProgramIds] = useState([])
    const [selectedEpisodeIds, setSelectedEpisodeIds] = useState([])
    const [allExpandedEpisodes, setAllExpandedEpisodes] = useState([]);
    const [shouldSelectAllEpisodes, setShouldSelectAllEpisodes] = useState(false)
    const allCatalogs = useSelector(getGNIDSAllCatalogs);
    const selectedCatalog = useSelector(getSelectedCatalogFromDropdown);

    const [showRemoveFromCatalogButton, setShowRemoveFromCatalogButton] = useState(false);
    const [showRemoveProgramCatalogMappingsConfirmationModal, setShowRemoveProgramCatalogMappingsConfirmationModal] = useState(false);
    const [showAddToCatalogErrorConfirmationModal, setShowAddToCatalogErrorConfirmationModal] = useState(false);
    const [showRemoveFromCatalogErrorConfirmationModal, setShowRemoveFromCatalogErrorConfirmationModal] = useState(false);
    const [catalogProgramError, setCatalogProgramError] = useState(null);
    // Bulk Upload
    const [showBulkUploadMultiForm, setShowBulkUploadMultiForm] = useState(false);
    const [isBulkUploadInProgress, setIsBulkUploadInProgress] = useState(false);

    useEffect(() => {
        if (sourceId && vocabIsLoading) {
            dispatch(mobiusGetGNVocabulary(sourceId))
                .then((response) => {
                    const statusFilters = response?.publishStatus?.length > 0 ? response?.publishStatus.map((status) => ({ category: MOBIUS_PROGRAM_FILTER_TYPES.STATUS, status: status?.value })) : [];
                    const initialFilters = PROGRAM_LIST_VIEW_FILTERS.concat(statusFilters).map((filter) => ({ ...filter, selected: false }));
                    setFilters(initialFilters);
                    setVocabIsLoading(false);
                })
                .catch((error) => {
                    setVocabIsLoading(false);
                    dispatch(
                        gnviewSendLogMessage(`mobiusGetGNVocabulary error: ${error.message}`, error)
                    );
                });
            dispatch(mobiusGetEntitlements(sourceId))
                .catch((error) => {
                    dispatch(
                        gnviewSendLogMessage(`mobiusGetEntitlements error: ${error.message}`, error)
                    );
                })
        }
    }, [dispatch, sourceId, vocabIsLoading]);

    const fetchData = useCallback(
        (pageIndex, sortBy, startAfter) => {
            setPageLastUpdated(moment());
            return mobiusGetAllPrograms(
                allMobiusVocabularies,
                pageIndex,
                sortBy,
                startAfter,
                filters,
                programTitleSearchSubmit,
                sourceId,
                null,
                null,
                selectedCatalog
            )
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [allMobiusVocabularies, filters, programTitleSearchSubmit, sourceId, selectedCatalog, refreshState]);
    useEffect(() => {
        if (sourceId) {
            const pageIndex = 0;
            dispatch(mobiusGetAllCatalogs(pageIndex, sourceId))
        }
    }, [sourceId, dispatch])

    // In the view modal, we'll want to keep the modal open when we open an episode from a Series
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleModal = (program, keepOpen, seriesGnId, seasonNumber, registerModal = false, refreshTable = false) => {
        if (!keepOpen) {
            setModalShow((prevModalShow) => !prevModalShow);
        }
        if (refreshTable && (program?.type === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE || program?.type === MOBIUS_PROGRAM_TYPES_VALUES.SEASON)) {
            setRefreshRowSubComponent(true);
            setShouldResetTable(false);
        } else {
            setShouldResetTable(refreshTable);
        }
        setIsRegisterModal(registerModal);
        dispatch(setGnidsProgramRelatedData({program, seasonNumber, seriesGnId}))
        setShowBulkUploadMultiForm(false);
    };

    const handleNotification = (show, message, isSuccess) => {
        setShowNotification(show);
        setNotificationMsg(message);
        setNotificationIsSuccess(isSuccess);
    };

    const renderRowSubComponent = useCallback(
        ({ row }) => (
            <ErrorBoundary>
                <SeasonAndEpisodeExpand handleModal={handleModal} handleNotification={handleNotification} row={row} setRegisterPresentationMode={setRegisterPresentationMode} selectedEpisodeIds={selectedEpisodeIds} setSelectedEpisodeIds={setSelectedEpisodeIds} setAllExpandedEpisodes={setAllExpandedEpisodes} shouldSelectAllEpisodes={shouldSelectAllEpisodes} />
            </ErrorBoundary>
        ),
        [handleModal, selectedEpisodeIds, shouldSelectAllEpisodes]
    );

    const addSelectedProgram = useCallback((newProgram) => {
        setSelectedProgramIds([...selectedProgramIds, newProgram.presentationGnID])
    }, [selectedProgramIds])

    const removeSelectedProgram = useCallback((objectToRemove) => {
        setSelectedProgramIds(selectedProgramIds.filter(id => id !== objectToRemove.presentationGnID))
    }, [selectedProgramIds])

    const handleSelectAllRows = useCallback(() => {
        const currentProgramIds = currentPrograms.map(program => program.presentationGnID)
        setSelectedProgramIds(currentProgramIds)
        setShouldSelectAllEpisodes(true)
    }, [currentPrograms])

    const handleClearAllRows = useCallback(() => {
        setSelectedProgramIds([])
        setSelectedEpisodeIds([])
        setShouldSelectAllEpisodes(false)
    }, [])

    // Filtering out the checkbox column for read-only users
    const listViewColumns = hasWriteAccess ? [...PROGRAM_LIST_VIEW_COLUMN_HEADERS] : [...PROGRAM_LIST_VIEW_COLUMN_HEADERS].filter((col) => col.id !== 'checkBox');
    const checkBoxesIndex = listViewColumns.findIndex((col) => col.id === 'checkBox');
    if (checkBoxesIndex !== -1) {
        listViewColumns[checkBoxesIndex] = {
            ...listViewColumns[checkBoxesIndex],
            /* eslint-disable react/display-name */
            /* eslint-disable react/prop-types */
            Header: () =>
                <div className="table-checkbox-header">
                    <GNHeaderCheckbox
                        isIndeterminate={selectedProgramIds.length > 0 || selectedEpisodeIds.length > 0}
                        handleClick={() => {
                            if (selectedProgramIds.length > 0 || selectedEpisodeIds.length > 0) {
                                handleClearAllRows()
                            } else {
                                handleSelectAllRows()
                            }
                        }}
                        label={(selectedProgramIds.length > 0 || selectedEpisodeIds.length > 0) && `(${selectedProgramIds.length + selectedEpisodeIds.length})`}
                    />
                </div>,
            Cell: ({ row }) =>
                <GNRowCheckbox
                    checked={selectedProgramIds.includes(row?.original.presentationGnID)}
                    row={row?.original}
                    addSelectedProgram={addSelectedProgram}
                    removeSelectedProgram={removeSelectedProgram}
                />

            /* eslint-enable react/display-name */
            /* eslint-enable react/prop-types */
        };
    }
    const titleIndex = listViewColumns.findIndex((col) => col.id === 'title');
    if (titleIndex !== -1) {
        listViewColumns[titleIndex] = {
            ...listViewColumns[titleIndex],
            /* eslint-disable react/display-name */
            /* eslint-disable react/prop-types */
            // Calling event.stopPropagation() to prevent the tr onClick from handling a row expansion since in this case, we only want the modal to open
            Cell: ({ row }) => (
                <>
                    {row.original.type === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE && (selectedCatalog !== '') &&
                     <OverlayTrigger
                         overlay={<Tooltip id="program-title-tooltip">{row.original.showTitle }</Tooltip>}
                         trigger={(row.original.showTitle.length > 36) ? ['hover'] : null}
                     >
                         <span
                             className='episode-show-program-title'
                         >
                             {row.original.showTitle}
                         </span>
                     </OverlayTrigger>
                    }
                    <OverlayTrigger
                        overlay={<Tooltip id="program-title-tooltip">{row.original.title || row.original.showTitle }</Tooltip>}
                        trigger={(row?.original?.title?.length > 36 || row?.original?.showTitle?.length > 36) ? ['hover'] : null}
                    >
                        <span
                            className='program-title'
                            onClick={(event) => {
                                event.stopPropagation();
                                if (row.original.type === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE || row.original.type === MOBIUS_PROGRAM_TYPES_VALUES.SHOW) {
                                    handleModal(row.original);
                                } else {
                                    // We don't need show version GNID here (it's fine if it's null) - because we're loading an episode
                                    dispatch(gnidsUpdateEpisode(null, row?.original?.showPresentationGnID, row?.original?.showTitle, row?.original?.seasonGnID, row?.original?.seasonNumber))
                                    handleModal(row?.original, false, row?.original?.seasonGnID, row?.original?.seasonNumber)
                                }
                            }}
                        >
                            {row.original.type === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE && row.original.title}
                            {row.original.type === MOBIUS_PROGRAM_TYPES_VALUES.SHOW && row.original.showTitle}
                            {row.original.type === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE && `S${row.original.seasonNumber}E${row.original.episodeNumber}: ${row.original.title}`}
                        </span>
                    </OverlayTrigger>
                </>
            )
            /* eslint-enable react/display-name */
            /* eslint-enable react/prop-types */
        };
    }
    const subtypeIndex = listViewColumns.findIndex((col) => col.id === 'subType');
    if (subtypeIndex !== -1) {
        listViewColumns[subtypeIndex] = {
            ...listViewColumns[subtypeIndex],
            /* eslint-disable react/display-name */
            /* eslint-disable react/prop-types */
            // Convert subtype value to label
            Cell: ({ row }) => {
                if (allMobiusVocabularies?.[MOBIUS_VOCABULARIES_LIST.PROGRAM_TYPE]?.length > 0) {
                    const convertedSubtype = convertVocab(
                        allMobiusVocabularies,
                        row.original.subType,
                        MOBIUS_VOCABULARIES_LIST.PROGRAM_TYPE,
                        true,
                        true,
                        row.original.type
                    );
                    return row?.original?.type && row?.original?.subType ? (
                        <GNBadge
                            viewSelector='programs'
                            type={row.original.type}
                            subtype={convertedSubtype}
                            gnview={false}
                        />
                    ) : null;
                }
                return null;
            }
            /* eslint-enable react/display-name */
            /* eslint-enable react/prop-types */
        };
    }
    const TablePopoverIndex = listViewColumns.findIndex((col) => col.id === 'TablePopover');
    if (TablePopoverIndex !== -1) {
        listViewColumns[TablePopoverIndex] = {
            ...listViewColumns[TablePopoverIndex],
            /* eslint-disable react/prop-types */
            Cell: ({ row }) => {
                return hasWriteAccess ? (
                    <TablePopover
                        program={row?.original}
                        // Movie, Show are from /programSummaries. So, presentationGnID is used for gnID
                        gnID={row?.original?.presentationGnID}
                        category={MOBIUS_PROGRAM_TYPES_VALUES.PROGRAM}
                        handleModal={handleModal}
                        handleNotification={handleNotification}
                        setRegisterPresentationMode={setRegisterPresentationMode}
                        setRegisterVersionMode={setRegisterVersionMode}
                    />
                ) : null;
            }
            /* eslint-enable react/prop-types */
        };
    }

    // Do not display GNView Page column if entitilements does not include tmsID
    if (!hasTMSEntitlements) {
        const tmsIdColumnIdx = listViewColumns.findIndex(header => header.id === 'tms_id');
        listViewColumns.splice(tmsIdColumnIdx, 1);
    }

    const handleFilterClick = (filter) => {
        const filterIndex = filters.findIndex((f) =>
            f.category === MOBIUS_PROGRAM_FILTER_TYPES.SUBTYPE
                ? f.subtype === filter.subtype
                : f.status === filter.status
        );
        if (filterIndex !== -1) {
            // Unmount the table
            setShouldResetTable(true);
            handleClearAllRows();
            const filtersCopy = [...filters];
            filtersCopy[filterIndex].selected = !filtersCopy[filterIndex].selected;
            setFilters(filtersCopy);
        }
    };

    useEffect(() => {
        if (refreshRowSubComponent) {
            // Reset this value back to false
            setRefreshRowSubComponent(false);
        }
        if (shouldResetTable) {
            // Reset this value back to false
            setShouldResetTable(false);
        }
    }, [refreshRowSubComponent, setRefreshRowSubComponent, shouldResetTable, setShouldResetTable]);

    useEffect(() => {
        if (!modalShow) {
            setRegisterPresentationMode(false);
            setRegisterVersionMode(false);
        }
    }, [modalShow, setRegisterPresentationMode, setRegisterVersionMode]);


    const showClearAllFilters = () => {
        return filters.filter((f) => f.selected).length > 0;
    };

    const clearAllFilters = () => {
        const filtersCleared = [...filters].map((f) => ({ ...f, selected: false }));
        setFilters(filtersCleared);
    };

    const handleProgramTitleSearchChange = (item, e) => {
        setProgramTitleSearch(item);
        if (e?.type === 'click') {
            setShouldResetTable(true);
            setProgramTitleSearchSubmit('');
        }
    };

    const handleProgramTitleSearchButtonClick = () => {
        setProgramTitleSearchSubmit(programTitleSearch);
        setShouldResetTable(true);
    };

    const getImageModalTitle = () => {
        return <><a className='show-title-breadcrumb' onClick={() => {
            dispatch(showImageModal(false));
            handleModal(selectedProgram);
            setModalShow(true)
            setProgramModalEditMode(breadcrumbToEditMode)
        }}>{selectedProgram?.title?.value}</a><FontAwesomeIcon icon='angle-right' />{selectedImageModalData?.title}</>
    }

    const handleCatalogChange = (catalog) => {
        setShouldResetTable(true); // rest to page to first if paginated

        dispatch(setSelectedCatalogFromDropdown(catalog))
        // If the user selects 'All Catalogs', then the 'remove from this catalog' button is not shown.
        setShowRemoveFromCatalogButton(catalog !== '');

        handleClearAllRows()
    }

    const removeCatalogProgramMapping = () => {
        dispatch(gnidsRemoveProgramsFromCatalog(selectedCatalog, [...selectedProgramIds, ...selectedEpisodeIds], sourceId))
            .then(() => {
                setShowRemoveProgramCatalogMappingsConfirmationModal(false)
                handleClearAllRows()
                setShouldResetTable(true)
                handleNotification(true, MOBIUS_NOTIFICATIONS.CATALOG_PROGRAM_MAPPING_REMOVED_SUCCESS, true)
            }).catch((error) => {
                dispatch(gnviewSendLogMessage(`gnidsRemoveCatalogMappings error ${error.message}`, error))
                setCatalogProgramError(error);
                handleClearAllRows()
                setShowRemoveProgramCatalogMappingsConfirmationModal(false)
                setShowRemoveFromCatalogErrorConfirmationModal(true)
            })
    }

    const addCatalogProgramMapping = (catalog) => {
        dispatch(gnidsAddProgramsToCatalogs(catalog, [...selectedProgramIds, ...selectedEpisodeIds], sourceId))
            .then(() => {
                handleClearAllRows()
                setShouldResetTable(true)
                handleNotification(true, MOBIUS_NOTIFICATIONS.CATALOG_PROGRAM_MAPPING_ADD_SUCCESS, true)
            })
            .catch((error) => {
                dispatch(gnviewSendLogMessage(`gnidsAddCatalogsMapping error ${error.message}`, error))
                setCatalogProgramError(error);
                handleClearAllRows()
                setShowAddToCatalogErrorConfirmationModal(true)
            })
    }

    const handleDownloadErrorList = async (errorList, fieldsToHeaderMapping) => {
        const jsonObj = parseJSONtoCSV(fieldsToHeaderMapping, errorList?.descriptionDetails);
        CSVDownload(jsonObj, CATALOG_PROGRAM_ERRORS_CSV_NAME)
    }

    const onClickRegisterNewProgram = () => {
        dispatch(gnidsClearProgramFiles());
        setShowBulkUploadMultiForm(true);
    }

    const handleBulkUploadMultiFormClose = () => {
        setShowBulkUploadMultiForm(false);
    }

    const handlePostBulkUpload = () => {
        setIsBulkUploadInProgress(true);
        handleBulkUploadMultiFormClose();

        setNotificationMsg(
            <div className="success-notification-content">
                <div className="first-line">
                    {PROGRAM_BULK_UPLOAD.SUCCESS_FIRST_LINE}
                </div>
                <div className="second-line">
                    {PROGRAM_BULK_UPLOAD.SUCCESS_SECOND_LINE}
                    <a className="import-status" href="/gnid-distribution/import-status">
                        {PROGRAM_BULK_UPLOAD.IMPORT_STATUS}
                    </a>{" "}
            page.
                </div>
            </div>
        );

        setNotificationIsSuccess(false);
        setShowNotification(true);
    };

    const handleRefresh = () => {
        setRefreshState(!refreshState);
    }

    return (
        <ErrorBoundary>
            {!vocabIsLoading && (
                <div className='gnid-distribution-main-container'>
                    <div className='gnid-distribution-top-section'>
                        <span className='gnids-last-updated'>
                            {LIST_VIEW_PAGE_LAST_UPDATED}:{' '}
                            {pageLastUpdated.format(MOBIUS_MOMENT_DATE_FORMAT)}
                        </span>
                        {modalShow && (
                            <ProgramModal
                                handleModal={handleModal}
                                isRegisterModal={isRegisterModal}
                                handleNotification={handleNotification}
                                programModalEditMode = {programModalEditMode}
                                setProgramModalEditMode = {setProgramModalEditMode}
                                registerPresentationMode={registerPresentationMode}
                                registerVersionMode={registerVersionMode}
                            />
                        )}
                        {shouldShowImageModal && <ImageModal imageModalTitle={getImageModalTitle()} setModalShow={(show) => dispatch(showImageModal(show))} handleNotification={handleNotification} />}
                        {showSeasonModal && (
                            <SeasonModal
                                handleNotification={handleNotification}
                                setRefreshRowSubComponent={setRefreshRowSubComponent}/>
                        )}
                        <div className='program-title-search-container'>
                            <div className='program-container-left'>
                                <ViewSwitcher className='program-view-switcher' value='Programs'/>
                                <div className='select-catalog-dropdown'>
                                    <GNDropdown
                                        options={allCatalogs}
                                        value={selectedCatalog}
                                        handleChange={handleCatalogChange}
                                    />
                                </div>
                                <span className='program-title-search'>
                                    <GNInput
                                        placeholder={'Program Title'}
                                        value={programTitleSearch}
                                        // Any text is fine here
                                        validateInput={() => true}
                                        handleChange={(item, e) => handleProgramTitleSearchChange(item, e)}
                                    />
                                    <Button
                                        aria-label='Search'
                                        id="program-title-search"
                                        className='program-title-search-button'
                                        onClick={() => handleProgramTitleSearchButtonClick()}
                                    >
                                        {MOBIUS_BUTTON_TEXT.SEARCH}
                                    </Button>
                                </span>
                            </div>
                            <div>
                                <GNButton tooltip={<Tooltip id="request-publish-button-tooltip">{MOBIUS_TOOLTIPS.REQUIRES_WRITE_ACCESS}</Tooltip>}
                                    trigger={!hasWriteAccess ? ['hover'] : null}
                                    onClick={onClickRegisterNewProgram}
                                    disabled={!hasWriteAccess}
                                    buttonClass='register-button'
                                    hasWriteAccess={hasWriteAccess}
                                >
                                    <FontAwesomeIcon icon='plus' />
                                    {MOBIUS_BUTTON_TEXT.REGISTER_NEW_PROGRAM}
                                </GNButton>
                            </div>

                        </div>

                        <div className='gnid-distribution-filters'>
                            <span className='filters-label'>Filters:</span>
                            <div>
                                {!vocabIsLoading && filters?.length > 0 && filters.map((filter, idx) => {
                                    if (filter.category === MOBIUS_PROGRAM_FILTER_TYPES.SUBTYPE) {
                                        return (
                                            <GNBadge
                                                viewSelector='programs'
                                                type={filter.selected ? filter.type : null}
                                                subtype={filter.subtype}
                                                gnview={false}
                                                onClick={() => handleFilterClick(filter)}
                                                key={idx}
                                            />
                                        );
                                    }
                                    return (
                                        <MobiusStatusIcon
                                            selected={filter.selected}
                                            status={filter.status}
                                            onClick={() => handleFilterClick(filter)}
                                            key={idx}
                                        />
                                    );
                                })}
                                {showClearAllFilters() && (
                                    <span className='clear-all-filters' onClick={() => {
                                        clearAllFilters();
                                        handleClearAllRows();
                                    }}>
                                        {MOBIUS_BUTTON_TEXT.CLEAR_ALL}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className='gnid-distribution-catalog-buttons'>

                            <div className="left">
                                <GNDropdownWithButton
                                    buttonText = 'Add Programs to Catalogs'
                                    buttonIcon = 'fa-folder-plus'
                                    dropdownDisabled = {!(selectedProgramIds.length > 0 || selectedEpisodeIds.length > 0)}
                                    options = {allCatalogs.filter(obj => obj[NAME] !== ALL_CATALOGS)}
                                    searchPlaceHolder = {SEARCH_CATALOGS}
                                    actionButtonLabel = {ADD}
                                    actionButtonHandler = {addCatalogProgramMapping}
                                    tooltip = {MOBIUS_TOOLTIPS.MUST_SELECT_A_CATALOG}
                                />
                                {showRemoveFromCatalogButton &&
                                    <GNButton
                                        trigger={!hasWriteAccess ? ['hover'] : null}
                                        onClick={() => {
                                            setShowRemoveProgramCatalogMappingsConfirmationModal(true)
                                        }}
                                        disabled={!(selectedProgramIds.length > 0 || selectedEpisodeIds.length > 0)}
                                        buttonClass='remove-from-catalog'
                                    >
                                        <FontAwesomeIcon icon='fa-solid fa-folder-minus' />
                                        {MOBIUS_BUTTON_TEXT.REMOVE_FROM_CATALOG}
                                    </GNButton>
                                }
                            </div>
                            <div className="right">
                                <button className="import-status-refresh-btn gn_button btn" onClick={handleRefresh} disabled={isLoading}><FontAwesomeIcon icon='refresh' />{ REFRESH_BUTTON_TEXT }</button>
                            </div>
                        </div>
                        {showBulkUploadMultiForm && <RegisterProgramModal show={showBulkUploadMultiForm} onClose={handleBulkUploadMultiFormClose} openRegisterNewProgramModal={() => handleModal(null, false, null, null, true)} handlePostBulkUpload={handlePostBulkUpload} />}
                        <div>
                            {showRemoveProgramCatalogMappingsConfirmationModal &&
                             <GNConfirmationModal
                                 cancelButtonHandler={() => setShowRemoveProgramCatalogMappingsConfirmationModal(false)}
                                 cancelButtonText={MOBIUS_BUTTON_TEXT.CANCEL}
                                 message={MOBIUS_CONFIRMATIONS.REMOVE_PROGRAMS_FROM_CATALOG_MESSAGE}
                                 show={true}
                                 submitButtonHandler={removeCatalogProgramMapping}
                                 submitButtonText={MOBIUS_BUTTON_TEXT.REMOVE_FROM_CATALOG}
                                 title={MOBIUS_CONFIRMATIONS.REMOVE_PROGRAMS_FROM_CATALOG_TITLE}
                             />
                            }
                        </div>
                        <div>
                            {(showAddToCatalogErrorConfirmationModal || showRemoveFromCatalogErrorConfirmationModal) &&
                                <GNErrorModal
                                    cancelButtonHandler={() => showAddToCatalogErrorConfirmationModal ? setShowAddToCatalogErrorConfirmationModal(false) : setShowRemoveFromCatalogErrorConfirmationModal(false)}
                                    cancelButtonText={MOBIUS_BUTTON_TEXT.CANCEL}
                                    isAddError={ showAddToCatalogErrorConfirmationModal }
                                    error={catalogProgramError}
                                    show={showAddToCatalogErrorConfirmationModal || showRemoveFromCatalogErrorConfirmationModal}
                                    submitButtonHandler={() => handleDownloadErrorList(catalogProgramError, ERROR_FIELD_TO_JSON_HEADER_MAPPING)}
                                    submitButtonText={MOBIUS_BUTTON_TEXT.DOWNLOAD_ERROR_LIST}
                                    modalTitle={showAddToCatalogErrorConfirmationModal ? MOBIUS_CONFIRMATIONS.ERROR_ADDING_TO_CATALOG : MOBIUS_CONFIRMATIONS.ERROR_REMOVING_FROM_CATALOG}
                                    allPrograms={currentPrograms}
                                    allCatalogs={allCatalogs}
                                    allExpandedEpisodes={allExpandedEpisodes}
                                />
                            }
                        </div>

                    </div>
                    <div className='gnid-distribution-bottom-section'>
                        {!shouldResetTable && <ErrorBoundary>
                            <GNServerTable
                                className='program-list-view-table'
                                columns={listViewColumns}
                                fetchData={fetchData}
                                noDataMessage={
                                    <GNNoDataMessage programType={MOBIUS_PROGRAM_TYPES_VALUES.PROGRAM} hasWriteAccess={hasWriteAccess}/>
                                }
                                refreshRowSubComponent={refreshRowSubComponent}
                                renderRowSubComponent={renderRowSubComponent}
                                showTableHeaderWithNoData={false}
                                onPageUpdate={handleClearAllRows}
                                selectedIds={selectedProgramIds}
                                setIsTableLoading={setIsLoading}
                            />
                        </ErrorBoundary>}
                    </div>
                    {showNotification && (
                        <GNNotification
                            handleShow={setShowNotification}
                            message={notificationMsg}
                            milliseconds={10000}
                            show={showNotification}
                            success={notificationIsSuccess}
                            isLoading={isBulkUploadInProgress}
                        />
                    )}
                    <GNGetHelp />
                </div>
            )}
        </ErrorBoundary>
    );
};

export default GNIDDistribution;
