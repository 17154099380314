export const APP_TYPES = {
    GNVIEW: 'Gracenote View',
    GNID: 'ID Distribution System'
};

export const MOBIUS_ACCESS_TYPES = {
    WRITE: 'WRITE',
    READ_ONLY: 'READ_ONLY'
};

export const FEATURE_FLAGS = {
    EXPORT: 'export',
    REPORT_ISSUE: 'report-an-issue',
    BULK_UPLOAD_MGMT: 'dev-gnids-bulk-upload',
    USER_SELECTED_MAPPING: 'user-selected-mapping',
    BULK_PUBLISH: 'bulk-publish'
};

export const MOBIUS_ACCESS_TYPES_IMAGES = {
    REGISTER: 'register',
    CERTIFY: 'certify',
    DELIVER: 'deliver'
};

export const CLIENT_TABLE_PAGE_SIZE = 20;
export const CLIENT_TABLE_LARGE_PAGE_SIZE = 50;
export const SERVER_TABLE_DEFAULT_PAGE_SIZE = 100;
export const EPISODES_TABLE_DEFAULT_PAGE_SIZE = 100;

export const REQUIRED = '*Required ';