import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { MobiusVocabReducer, MOBIUS_VOCAB_STORE } from './MobiusVocabReducer';
import { GNVAuthReducer, GNVAUTH_STORE_NAME } from './GNVAuthReducer';
import { GNIDSSeasonModalReducer, GNIDS_SEASON_MODAL_STORE } from './GNIDSSeasonModalReducer';
import { GNIDSEpisodeReducer, GNIDS_EPISODE_STORE_NAME } from './GNIDSEpisodeReducer';
import { PROGRAM_MODAL_STORE_NAME, GNIDSProgramModalReducer } from './GNIDSProgramModalReducer';
import { GNIDS_TABLE_STORE_NAME, GNIDSTableReducer } from './GNIDSTableReducer';
import { IMAGE_MODAL_STORE_NAME, GNIDSImageModalReducer } from './GNIDSImageModalReducer'
import { GNIDSCatalogReducer, CATALOG_STORE_NAME } from './GNIDSCatalogReducer';
import {GNIDSProgramFilesReducer, GNIDS_PROGRAM_FILES_STORE_NAME } from './GNIDSProgramFilesReducer';

const createRootReducer = (history) => {
    return combineReducers({
        router: connectRouter(history),
        [GNVAUTH_STORE_NAME]: GNVAuthReducer,
        [MOBIUS_VOCAB_STORE]: MobiusVocabReducer,
        [GNIDS_SEASON_MODAL_STORE]: GNIDSSeasonModalReducer,
        [GNIDS_EPISODE_STORE_NAME]: GNIDSEpisodeReducer,
        [PROGRAM_MODAL_STORE_NAME]: GNIDSProgramModalReducer,
        [GNIDS_TABLE_STORE_NAME]: GNIDSTableReducer,
        [IMAGE_MODAL_STORE_NAME]: GNIDSImageModalReducer,
        [CATALOG_STORE_NAME]: GNIDSCatalogReducer,
        [GNIDS_PROGRAM_FILES_STORE_NAME]: GNIDSProgramFilesReducer
    })
};

export default createRootReducer;