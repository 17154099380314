import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    mobiusGetEpisodesByPresentationIdAndSeasonId,
    mobiusGetSeasonsByPresentationId,
    gnidsUpdateEpisode,
    mobiusDeleteItemByGNId
} from '../actions/MobiusActions';
import { MOBIUS_CONFIRMATIONS, MOBIUS_NOTIFICATIONS, MOBIUS_PROGRAM_TYPES_VALUES, MOBIUS_TOOLTIPS } from '../constants/Mobius';
import GNClientTable from '../components/common/gnClientTable/GNClientTable';
import GNNoDataMessage from '../components/common/gnNoDataMessage/GNNoDataMessage';
import { PROGRAM_LIST_VIEW_EPISODE_COLUMN_HEADERS } from '../constants/MobiusListView';
import './SeasonAndEpisodeExpand.scss';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { MOBIUS_BUTTON_TEXT, MOBIUS_LABELS } from '../constants/Mobius';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GNDropdown from '../components/common/gnDropdown/GNDropdown';
import GNButton from '../components/common/gnButton/GNButton';
import { sortSeasons } from '../utils/SeasonUtils';
import PropTypes from 'prop-types';
import ErrorBoundary from '../components/common/errorBoundary/ErrorBoundary';
import { gvauthSelMobiusSourceID, gvauthHasMobiusWriteAccess } from '../reducers/GNVAuthReducer';
import { gnviewSendLogMessage } from '../services/GeneralService';
import { gnidsSeasonModalToggle, gnidsSetSeasonGNID, gnidsSetSeasonModalTitle, gnidsSetSeasonNumber } from '../actions/GNIDSSeasonModalActions';
import TablePopover from '../components/common/popover/TablePopover';
import GNConfirmationModal from '../components/common/gnConfirmationModal/GNConfirmationModal';
import { gnidsSeasonNumber } from '../reducers/GNIDSSeasonModalReducer';
import { getSelectedCatalogFromDropdown } from '../reducers/GNIDSCatalogReducer';
import GNHeaderCheckbox from '../components/common/gnHeaderCheckbox/GNHeaderCheckbox';
import GNRowCheckbox from '../components/common/gnRowCheckbox/GNRowCheckbox';
import { mobiusVocabHasTMSEntitlements } from '../reducers/MobiusVocabReducer';

export const SeasonAndEpisodeExpand = ({ handleModal, handleNotification, row: series, setRegisterPresentationMode, selectedEpisodeIds, setSelectedEpisodeIds, setAllExpandedEpisodes, shouldSelectAllEpisodes }) => {
    const dispatch = useDispatch();
    const { original: { showPresentationGnID, showTitle } } = series;
    const [seasons, setSeasons] = useState(null);
    const [episodes, setEpisodes] = useState([]);
    const [episodesLoading, setEpisodesLoading] = useState(true);
    const seasonNumber = useSelector(gnidsSeasonNumber)
    const [seasonGnID, setSeasonGNID] = useState(null);
    const sourceId = useSelector(gvauthSelMobiusSourceID);
    const hasWriteAccess = useSelector(gvauthHasMobiusWriteAccess);
    const [seasonsIsLoading, setSeasonsIsLoading] = useState(true);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [isModalLoading, setIsModalLoading] = useState(false);
    const selectedCatalog = useSelector(getSelectedCatalogFromDropdown);
    const hasTMSEntitlements = useSelector(mobiusVocabHasTMSEntitlements);

    useEffect(() => {
        const findSeasonIndex = (sortedSeasons) => {
            if (seasonNumber) {
                return sortedSeasons.findIndex((a) => a.seasonNumber === seasonNumber)
            } else {
                return 0
            }
        }

        if (showPresentationGnID && sourceId?.length > 0 && seasonsIsLoading) {
            dispatch(
                mobiusGetSeasonsByPresentationId(showPresentationGnID, sourceId)
            )
                .then((response) => {
                    if (response?.result?.data) {
                        const sortedSeasons = sortSeasons(response.result.data);
                        setSeasons(sortedSeasons);
                        const index = findSeasonIndex(sortedSeasons)
                        dispatch(gnidsSetSeasonNumber(sortedSeasons?.[index]?.seasonNumber || null));
                        setSeasonGNID(sortedSeasons?.[index]?.gnID || null);
                        setSeasonsIsLoading(false);
                    }
                })
                .catch((error) => {
                    dispatch(
                        gnviewSendLogMessage(
                            `mobiusGetSeasonsByPresentationId error: ${error.message}`,
                            error
                        )
                    );
                });
        }
    }, [dispatch, seasonNumber, seasonsIsLoading, showPresentationGnID, sourceId]);

    useEffect(() => {
        if (showPresentationGnID && seasonGnID && sourceId?.length > 0) {
            setEpisodesLoading(true);
            dispatch(
                mobiusGetEpisodesByPresentationIdAndSeasonId(
                    showPresentationGnID,
                    seasonGnID,
                    sourceId,
                    seasonNumber,
                    selectedCatalog
                )
            )
                .then((response) => {
                    setEpisodes(response.result.data);
                    setAllExpandedEpisodes(response.result.data)
                    setEpisodesLoading(false);
                })
                .catch((error) => {
                    setEpisodesLoading(false);
                    dispatch(
                        gnviewSendLogMessage(
                            `mobiusGetEpisodesByPresentationIdAndSeasonId error: ${error.message}`,
                            error
                        )
                    );
                });
        }
        handleClearAllEpisodes();
    }, [dispatch, seasonNumber, seasonGnID, showPresentationGnID, sourceId, selectedCatalog, handleClearAllEpisodes, setAllExpandedEpisodes]);

    useEffect(() => {
        if (series.isExpanded && shouldSelectAllEpisodes) {
            handleSelectAllEpisodes();
        } else {
            handleClearAllEpisodes();
        }
        // clearing the selections during unmount (on collapsing)
        return () => handleClearAllEpisodes();
    }, [series.isExpanded, shouldSelectAllEpisodes, handleClearAllEpisodes, handleSelectAllEpisodes])

    const handleSeasonChange = (seasonId) => {
        const selectedSeason = seasons.filter((season) => season.gnID === seasonId);
        setSeasonGNID(selectedSeason?.[0]?.gnID || null);
        dispatch(gnidsSetSeasonNumber(selectedSeason?.[0]?.seasonNumber))
    };

    const handleEditSeason = () => {
        dispatch(gnidsSeasonModalToggle(true, showPresentationGnID))
        dispatch(gnidsSetSeasonModalTitle(showTitle))
        dispatch(gnidsSetSeasonGNID(seasonGnID));
    }

    const handleSeasonDelete = () => {
        setIsModalLoading(true);
        if (seasonGnID && sourceId) {
            dispatch(mobiusDeleteItemByGNId(seasonGnID, sourceId, MOBIUS_PROGRAM_TYPES_VALUES.SEASON))
                .then(() => {
                    setShowConfirmationModal(false);
                    setIsModalLoading(false);
                    handleModal({type: MOBIUS_PROGRAM_TYPES_VALUES.SEASON}, true, null, null, false, true);
                    handleNotification(true, MOBIUS_NOTIFICATIONS.PROGRAM_SUCCESS_DELETE, true)
                    dispatch(gnidsSetSeasonNumber(null))
                })
                .catch(() => {
                    setShowConfirmationModal(false);
                    setIsModalLoading(false);
                    handleNotification(true, MOBIUS_NOTIFICATIONS.PROGRAM_ERROR_DELETE, false);
                })
        }
    }

    const addSelectedEpisode = useCallback((newEpisode) => {
        setSelectedEpisodeIds([...selectedEpisodeIds, newEpisode.gnID])
    }, [selectedEpisodeIds, setSelectedEpisodeIds])

    const removedSelectedEpisode = useCallback((objectToRemove) => {
        setSelectedEpisodeIds(selectedEpisodeIds.filter(id => id !== objectToRemove.gnID))
    }, [selectedEpisodeIds, setSelectedEpisodeIds])

    const handleClearAllEpisodes = useCallback(() => {
        setSelectedEpisodeIds([])
    }, [setSelectedEpisodeIds])

    const handleSelectAllEpisodes = useCallback(() => {
        const allEpisodeIds = episodes.map(episode => episode.gnID)
        setSelectedEpisodeIds(allEpisodeIds)
    }, [episodes, setSelectedEpisodeIds])

    const episodeColumns = [...(hasWriteAccess ? [{
        id: 'checkBox',
        accessor: 'checkBox',
        disableSortBy: true
    }] : []), ...PROGRAM_LIST_VIEW_EPISODE_COLUMN_HEADERS];

    const titleIndex = episodeColumns.findIndex((col) => col.id === 'title');
    if (titleIndex !== -1) {
        episodeColumns[titleIndex] = {
            ...episodeColumns[titleIndex],
            /* eslint-disable react/display-name */
            /* eslint-disable react/prop-types */
            Cell: ({ row }) => (
                <OverlayTrigger
                    overlay={<Tooltip id="program-title-tooltip">{row.values.title.value }</Tooltip>}
                    trigger={(row.values.title.value.length > 36) ? ['hover'] : null}
                >
                    <span
                        className='episode-title'
                        onClick={() => {
                        // We don't need show version GNID here (it's fine if it's null) - because we're loading an episode
                            dispatch(gnidsUpdateEpisode(null, showPresentationGnID, series?.original?.showTitle, seasonGnID, seasonNumber))
                            handleModal(row?.original, false, seasonGnID, seasonNumber, false)
                        }}
                    >
                        {row.values.title.value}
                    </span>
                </OverlayTrigger>
            )
            /* eslint-enable react/display-name */
            /* eslint-enable react/prop-types */
        };
    }
    const TablePopoverIndex = episodeColumns.findIndex((col) => col.id === 'TablePopover');
    if (TablePopoverIndex !== -1) {
        episodeColumns[TablePopoverIndex] = {
            ...episodeColumns[TablePopoverIndex],
            Cell: ({ row }) => {
                return hasWriteAccess ? (
                    <TablePopover
                        program={row?.original}
                        // Episode is from /shows/versions/presentations/seasons/episodes?showPresentationGnID={123}. Thus, GnId is used
                        gnID={row?.original?.gnID}
                        category={MOBIUS_PROGRAM_TYPES_VALUES.EPISODE}
                        handleModal={handleModal}
                        handleNotification={handleNotification}
                        setRegisterPresentationMode={setRegisterPresentationMode}
                        showRegisterOptions={false}
                    />
                ) : null;
            }
        };
    }

    const checkBoxesIndex = episodeColumns.findIndex((col) => col.id === 'checkBox');
    if (checkBoxesIndex !== -1) {
        episodeColumns[checkBoxesIndex] = {
            ...episodeColumns[checkBoxesIndex],
            /* eslint-disable react/display-name */
            /* eslint-disable react/prop-types */
            Header: () =>
                <div className="table-checkbox-header">
                    <GNHeaderCheckbox
                        isIndeterminate={selectedEpisodeIds.length > 0}
                        handleClick={() => {
                            if (selectedEpisodeIds.length > 0) {
                                handleClearAllEpisodes()
                            } else {
                                handleSelectAllEpisodes()
                            }
                        }}
                        label={selectedEpisodeIds.length > 0 && `(${selectedEpisodeIds.length})`}
                    />
                </div>,
            Cell: ({ row }) => <GNRowCheckbox
                checked={selectedEpisodeIds.includes(row?.original.gnID)}
                row={row?.original}
                addSelectedProgram={addSelectedEpisode}
                removeSelectedProgram={removedSelectedEpisode}
            />
            /* eslint-enable react/display-name */
            /* eslint-enable react/prop-types */
        };
    }

    // Do not display GNView Page column if entitilements does not include tmsID
    if (!hasTMSEntitlements) {
        const tmsIdColumnIdx = episodeColumns.findIndex(header => header.id === 'tms_id');
        episodeColumns.splice(tmsIdColumnIdx, 1);
    }

    return (
        <ErrorBoundary>
            <div className='gnids-program-season-and-episode-expand'>
                <div className='season-and-episode-table-container'>
                    <div className='table-top-section'>
                        <div className='seasons-edit-season'>
                            {seasons && seasonGnID && <GNDropdown
                                label={MOBIUS_LABELS.SEASONS_DROPDOWN_LABEL}
                                options={seasons}
                                value={seasonGnID}
                                handleChange={(season) => handleSeasonChange(season)}
                            />
                            }
                            {seasons && seasonGnID && <GNButton
                                buttonClass='edit-season-button'
                                disabled={!hasWriteAccess}
                                trigger={!hasWriteAccess ? ['hover', 'hover'] : null}
                                tooltip={<Tooltip id="request-publish-button-tooltip">{MOBIUS_TOOLTIPS.REQUIRES_WRITE_ACCESS}</Tooltip>}
                                hasWriteAccess={hasWriteAccess}
                                variant="light"
                                onClick={handleEditSeason}
                            >
                                <FontAwesomeIcon icon="pen" />
                                {MOBIUS_BUTTON_TEXT.EDIT_SEASON}
                            </GNButton>}
                            {seasons && seasonGnID && <GNButton
                                buttonClass='delete-season-button'
                                disabled={!hasWriteAccess}
                                trigger={['hover', 'hover']}
                                tooltip={
                                    <Tooltip id="delete-season-tooltip">{
                                        hasWriteAccess ? `${MOBIUS_TOOLTIPS.DELETE_SEASON} ${seasonNumber ? seasonNumber : MOBIUS_LABELS.NON_SEASON}` : MOBIUS_TOOLTIPS.REQUIRES_WRITE_ACCESS}
                                    </Tooltip>
                                }
                                hasWriteAccess={hasWriteAccess}
                                onClick={() => {
                                    setShowConfirmationModal(true);
                                }}
                            >
                                <FontAwesomeIcon icon='trash-alt' className='fa-trash-alt'/>
                                {MOBIUS_BUTTON_TEXT.DELETE_SEASON}
                            </GNButton>}
                        </div>
                        <div className='register-buttons'>
                            {seasons && seasonGnID && <GNButton
                                buttonClass='register-new-season-button'
                                disabled={!hasWriteAccess}
                                trigger={!hasWriteAccess ? ['hover', 'hover'] : null}
                                tooltip={<Tooltip id="request-publish-button-tooltip">{MOBIUS_TOOLTIPS.REQUIRES_WRITE_ACCESS}</Tooltip>}
                                hasWriteAccess={hasWriteAccess}
                                onClick={() => {
                                    dispatch(gnidsSeasonModalToggle(true, showPresentationGnID))
                                    dispatch(gnidsSetSeasonModalTitle(showTitle))
                                }}
                            >
                                <FontAwesomeIcon icon='plus' />
                                {MOBIUS_BUTTON_TEXT.REGISTER_NEW_SEASON}
                            </GNButton>}
                            {seasonGnID && (
                                <GNButton
                                    buttonClass='register-new-episode-button'
                                    disabled={!hasWriteAccess}
                                    trigger={!hasWriteAccess ? ['hover', 'hover'] : null}
                                    tooltip={<Tooltip id="request-publish-button-tooltip">{MOBIUS_TOOLTIPS.REQUIRES_WRITE_ACCESS}</Tooltip>}
                                    hasWriteAccess={hasWriteAccess}
                                    onClick={() => {
                                        dispatch(gnidsUpdateEpisode(null, showPresentationGnID, series?.original?.showTitle, seasonGnID, seasonNumber))
                                        handleModal(
                                            {type: MOBIUS_PROGRAM_TYPES_VALUES.EPISODE},
                                            false,
                                            showPresentationGnID,
                                            seasonNumber,
                                            true
                                        )
                                    }}
                                >
                                    <FontAwesomeIcon icon='plus' />
                                    {MOBIUS_BUTTON_TEXT.REGISTER_NEW_EPISODE}
                                </GNButton>
                            )}
                        </div>
                    </div>
                    {seasonGnID && (
                        <ErrorBoundary>
                            <GNClientTable
                                className='season-and-episode-table'
                                columns={episodeColumns}
                                data={episodes}
                                isLoading={episodesLoading}
                                pagination={true}
                                paginationBottom={false}
                                noDataMessage={
                                    <GNNoDataMessage
                                        programType={MOBIUS_PROGRAM_TYPES_VALUES.EPISODE}
                                        hasWriteAccess={hasWriteAccess}
                                        handleClick={() => {
                                            dispatch(gnidsUpdateEpisode(null, showPresentationGnID, series?.original?.showTitle, seasonGnID, seasonNumber))
                                            handleModal(
                                                {type: MOBIUS_PROGRAM_TYPES_VALUES.EPISODE},
                                                false,
                                                showPresentationGnID,
                                                seasonNumber,
                                                true
                                            )
                                        }}
                                    />
                                }
                            />
                        </ErrorBoundary>
                    )}
                    {!seasonGnID && !seasonsIsLoading && <div className="no-season-data-message">
                        <GNClientTable
                            className='season-and-episode-table'
                            columns={episodeColumns}
                            data={[]}
                            noDataMessage={
                                <GNNoDataMessage programType={MOBIUS_PROGRAM_TYPES_VALUES.SEASON} hasWriteAccess={hasWriteAccess} handleClick={() => {
                                    dispatch(gnidsSeasonModalToggle(true, showPresentationGnID))
                                    dispatch(gnidsSetSeasonModalTitle(showTitle))
                                }}/>
                            }
                        />
                    </div>}
                    {showConfirmationModal && (
                        <GNConfirmationModal
                            cancelButtonHandler={() => setShowConfirmationModal(false)}
                            cancelButtonText={MOBIUS_BUTTON_TEXT.CANCEL.toUpperCase()}
                            isLoading={isModalLoading}
                            message={MOBIUS_CONFIRMATIONS.DELETE_SEASON_CONFIRMATION_MESSAGE}
                            show={showConfirmationModal}
                            submitButtonHandler={handleSeasonDelete}
                            submitButtonText={MOBIUS_BUTTON_TEXT.DELETE.toUpperCase()}
                            title={MOBIUS_CONFIRMATIONS.DELETE_SEASON_CONFIRMATION_TITLE}
                        />
                    )}
                </div>
            </div>
        </ErrorBoundary>
    );
};

SeasonAndEpisodeExpand.propTypes = {
    handleModal: PropTypes.func.isRequired,
    handleNotification: PropTypes.func.isRequired,
    row: PropTypes.object.isRequired,
    setRegisterPresentationMode: PropTypes.func.isRequired,
    selectedEpisodeIds: PropTypes.array.isRequired,
    setSelectedEpisodeIds: PropTypes.func.isRequired,
    setAllExpandedEpisodes: PropTypes.func.isRequired,
    shouldSelectAllEpisodes: PropTypes.bool.isRequired
};

export default SeasonAndEpisodeExpand;
