import React, { useEffect, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import GNModal from '../../components/common/gnModal/GNModal';
import './SeasonModal.scss';
import LoadingSpinner from '../../components/common/loadingSpinner/LoadingSpinner';
import {
    MOBIUS_LABELS,
    PROGRAM_SIDE_MENU,
    MOBIUS_NOTIFICATIONS,
    MOBIUS_PROGRAM_TYPES_LABELS,
    PROGRAM_SIDE_MENU_RATIOS
} from '../../constants/Mobius';
import ClassNames from 'classnames';
import ScrollSpyMenu from '../../components/common/scrollSpyMenu/ScrollSpyMenu';
import ErrorBoundary from '../../components/common/errorBoundary/ErrorBoundary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    gnidsSeasonModalToggle,
    gnidsClearSeason,
    gnidsSetSeason,
    gnidsSetSeasonGNID
} from '../../actions/GNIDSSeasonModalActions';
import { useDispatch, useSelector } from 'react-redux';
import SeasonModalEdit from './SeasonModalEdit';
import {
    gnidsSeasonModalShow,
    gnidsSeasonModalTitle,
    gnidsSeasonRegisterConfirmationModalShow,
    gnidsSeasonModalSeason,
    gnidsSeasonGNID
} from '../../reducers/GNIDSSeasonModalReducer';
import GNNotification from '../../components/common/gnNotification/GNNotification';
import { gvauthSelMobiusSourceID } from '../../reducers/GNVAuthReducer';
import { gnviewSendLogMessage } from '../../services/GeneralService';
import PropTypes from 'prop-types';
import { mobiusGetSeason } from '../../actions/MobiusActions';

export const SeasonModal = ({ handleNotification, setRefreshRowSubComponent }) => {
    const dispatch = useDispatch();
    const menuItems = [PROGRAM_SIDE_MENU.OVERVIEW, PROGRAM_SIDE_MENU.DESCRIPTIONS];
    const menuRatios = PROGRAM_SIDE_MENU_RATIOS.slice(1, 3);
    const title = useSelector(gnidsSeasonModalTitle);
    const seasonGnID = useSelector(gnidsSeasonGNID);
    const showSeasonModal = useSelector(gnidsSeasonModalShow);
    const showConfirmationModal = useSelector(gnidsSeasonRegisterConfirmationModalShow);
    const sourceId = useSelector(gvauthSelMobiusSourceID);
    const registerSeason = useSelector(gnidsSeasonModalSeason);
    // Register Error Notification
    const [showErrNotification, setShowErrNotification] = useState(false);
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false)

    // isLoading and setIsLoading will be part of API intergration task
    const [isLoading, setIsLoading] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [refreshSeason, setRefreshSeason] = useState(false);

    const handleCancel = () => {
        if (isDirty) {
            setShowCancelConfirmation(true)
        } else {
            dispatch(gnidsSeasonModalToggle(isDirty));
            dispatch(gnidsClearSeason());
            setRefreshRowSubComponent(refreshSeason);
        }
    };

    const fetchSeason = () => {
        if (!seasonGnID) {
            return
        } // season registration case
        dispatch(mobiusGetSeason(seasonGnID, sourceId))
            .then((response) => {
                if (response) {
                    dispatch(gnidsSetSeason(response))
                }
                setIsLoading(false);
            })
            .catch((error) => {
                dispatch(
                    gnviewSendLogMessage(`mobiusGetSeason error: ${error.message}`, error, {
                        seasonGnID
                    })
                );
                dispatch(gnidsSetSeasonGNID(null));
                setIsLoading(false);
            });
    }

    useEffect(fetchSeason, [
        dispatch,
        seasonGnID,
        sourceId
    ]);

    const getSeasonTitle = () => {
        let seasonTitle = '';
        if (!seasonGnID) {
            seasonTitle = MOBIUS_LABELS.REGISTER_NEW_SEASON;
        } else if (registerSeason?.nonSeason || !registerSeason?.seasonNumber) {
            seasonTitle = MOBIUS_LABELS.NON_SEASON;
        } else {
            seasonTitle = registerSeason?.seasonNumber?.startsWith(MOBIUS_PROGRAM_TYPES_LABELS.SEASON) ? registerSeason?.seasonNumber : `${MOBIUS_PROGRAM_TYPES_LABELS.SEASON} ${registerSeason?.seasonNumber}` // We sometimes are getting just 1,2,.. etc so prepending it with Season Keyword
        }
        return (
            <span>
                <OverlayTrigger
                    overlay={<Tooltip id="program-title-tooltip">{title }</Tooltip>}
                    trigger={(title.length > 36) ? ['hover'] : null}
                    placement='bottom'
                >
                    <a className='show-title-breadcrumb'>{title}</a>
                </OverlayTrigger>
                <FontAwesomeIcon icon='angle-right' />
                <span className='season-title'>{seasonTitle}</span>
            </span>
        )
    }

    return (
        <ErrorBoundary>
            {/* We're doing a custom css hide here instead of using show because we don't want this to unmount and lose the user's current changes */}
            <GNModal
                show={true}
                onHide={() => handleCancel()}
                className={ClassNames('mobius-season-modal', {
                    hide: !showSeasonModal || showConfirmationModal || showCancelConfirmation
                })}
            >
                {isLoading && <LoadingSpinner />}
                {!isLoading && (
                    <div className='season-modal-container'>
                        <Modal.Title>
                            <div className="gnview-header-container">
                                <div className='gnview-header-title'>{getSeasonTitle()}</div>
                            </div>
                        </Modal.Title>
                        <Modal.Body>
                            <div className='season-modal-side-menu-container'>
                                <ScrollSpyMenu
                                    menuItemIds={menuItems}
                                    menuLabel={MOBIUS_LABELS.PROGRAM_DETAILS}
                                    menuRatios={menuRatios}
                                    targetContainerId='season-modal-body'
                                />

                            </div>
                            <div id='season-modal-body'>
                                <SeasonModalEdit
                                    handleCancel={handleCancel}
                                    seasonGnID={seasonGnID}
                                    setIsDirty={setIsDirty}
                                    setRefreshSeason={setRefreshSeason}
                                    showCancelConfirmation={showCancelConfirmation}
                                    setShowCancelConfirmation={setShowCancelConfirmation}
                                    handleNotification={handleNotification}
                                    setRefreshRowSubComponent={setRefreshRowSubComponent}
                                    setShowErrNotification={setShowErrNotification}
                                />
                            </div>
                        </Modal.Body>
                    </div>
                )}
            </GNModal>
            {showErrNotification && <GNNotification
                className="season-modal-error"
                handleShow={setShowErrNotification}
                message={MOBIUS_NOTIFICATIONS.SEASONS_ERROR_REGISTERED}
                milliseconds={5000}
                show={showErrNotification}
                success={false}
            />}
        </ErrorBoundary>
    );
};

SeasonModal.propTypes = {
    handleNotification: PropTypes.func.isRequired,
    setRefreshRowSubComponent: PropTypes.func.isRequired
};

export default SeasonModal;