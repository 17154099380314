import React, { useState, useEffect, useCallback, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    mobiusGetProgram,
    mobiusGetProgramPresentations,
    mobiusGetEpisodeByEpisodeId,
    gnidsClearEpisode,
    mobiusGetImageGNIdByPresentationGNId,
    mobiusGetImage
} from '../../actions/MobiusActions';
import { gnviewSendLogMessage } from '../../services/GeneralService';
import { Modal, Tooltip } from 'react-bootstrap';
import GNModal from '../../components/common/gnModal/GNModal';
import './ProgramModal.scss';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../components/common/loadingSpinner/LoadingSpinner';
import {
    MOBIUS_BUTTON_TEXT,
    MOBIUS_CONFIRMATIONS,
    MOBIUS_LABELS,
    MOBIUS_PROGRAM_TYPES_VALUES,
    MOBIUS_RELATED_PROGRAMS_COLUMN_HEADERS,
    PROGRAM_SIDE_MENU,
    PROGRAM_SIDE_MENU_RATIOS,
    MOBIUS_TOOLTIPS,
    MOBIUS_NOTIFICATIONS
} from '../../constants/Mobius';
import ProgramModalViewOnly from './ProgramModalViewOnly';
import MobiusStatusIcon from '../../components/mobius/mobiusStatusIcon/MobiusStatusIcon';
import moment from 'moment-timezone';
import { ROUTES } from '../../config/Routes';
import {
    gvauthHasMobiusWriteAccess,
    gvauthHasUserSelectedMappingFeature,
    gvauthSelMobiusSourceID
} from '../../reducers/GNVAuthReducer';
import { convertLanguageFromCode } from '../../utils/GeneralUtils';
import ProgramModalEdit from './ProgramModalEdit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GNConfirmationModal from '../../components/common/gnConfirmationModal/GNConfirmationModal';
import ClassNames from 'classnames';
import GNViewButton from '../../components/common/gnviewButton/GNViewButton';
import ScrollSpyMenu from '../../components/common/scrollSpyMenu/ScrollSpyMenu';
import { mobiusVocabHasTMSEntitlements, mobiusVocabSelEntitledLanguages } from '../../reducers/MobiusVocabReducer';
import GNServerTable from '../../components/common/gnServerTable/GNServerTable';
import ErrorBoundary from '../../components/common/errorBoundary/ErrorBoundary';
import GNButton from '../../components/common/gnButton/GNButton';
import {gnidsSelShowTitle, gnidsSelSeasonTitle} from '../../reducers/GNIDSEpisodeReducer';
import { gnidsProgram, gnidsProgramImages, gnidsSelectPresentationGnId, gnidsSelectProgramType, gnidsSelectSeasonNumber, gnidsSelectShowVersionGNID } from '../../reducers/GNIDSProgramModalReducer';
import { clearGnidsProgramImages, clearProgramModalData, setGnidsProgram, setGnidsProgramImages } from '../../actions/GNIDSProgramModalAction';
import { getProgramMenuItems, clearPresentationValues, clearVersionAndPresentationValues, getProgramMenuRatios } from '../../utils/MobiusUtils';
import { transformImageData } from '../../utils/ImageUtils';
import { OverlayTrigger } from 'react-bootstrap';

export const ProgramModal = ({
    handleModal,
    isRegisterModal = false,
    handleNotification,
    programModalEditMode = false,
    registerPresentationMode,
    registerVersionMode,
    setProgramModalEditMode,
    importStatusFlag
}) => {
    const dispatch = useDispatch();
    const mobiusEntitledLanguages = useSelector(mobiusVocabSelEntitledLanguages);
    const hasWriteAccess = useSelector(gvauthHasMobiusWriteAccess);
    const [isLoading, setIsLoading] = useState(true);
    const program = useSelector(gnidsProgram);
    const [allLanguages, setAllLanguages] = useState([]);
    const programType = useSelector(gnidsSelectProgramType);
    const seasonNumber = useSelector(gnidsSelectSeasonNumber);
    const versionGnId = useSelector(gnidsSelectShowVersionGNID);
    const gnID = useSelector(gnidsSelectPresentationGnId);
    const [showCancelConfirm, setShowCancelConfirm] = useState(false);
    const [showProgramModal, setShowProgramModal] = useState(true);
    const [isDirty, setIsDirty] = useState(false);
    const [showRelatedPrograms, setShowRelatedPrograms] = useState(false);
    const [refreshProgram, setRefreshProgram] = useState(false);
    const [editMode, setEditMode] = useState(!gnID || registerPresentationMode || registerVersionMode || programModalEditMode);
    const sourceId = useSelector(gvauthSelMobiusSourceID);
    const parentShowTitle = useSelector(gnidsSelShowTitle);
    const parentSeason = useSelector(gnidsSelSeasonTitle);
    const imagesData = useSelector(gnidsProgramImages);
    const hasTMSEntitlements = useSelector(mobiusVocabHasTMSEntitlements);
    const targetContainerRef = useRef(null);
    const userSelectedMappingFeatureEnabled = useSelector(gvauthHasUserSelectedMappingFeature);

    if (!userSelectedMappingFeatureEnabled) {
        delete PROGRAM_SIDE_MENU.MAPPINGS;
    }

    const fetchProgram = () => {
        const languages = mobiusEntitledLanguages.map((langCode) => ({
            name: convertLanguageFromCode(langCode),
            value: langCode
        }));
        if (!program && programType !== MOBIUS_PROGRAM_TYPES_VALUES.EPISODE && gnID && sourceId && versionGnId) {
            dispatch(mobiusGetProgram(gnID, sourceId, programType, versionGnId))
                .then((response) => {
                    if (response) {
                        dispatch(setGnidsProgram(response))
                    }
                })
                .catch((error) => {
                    dispatch(
                        gnviewSendLogMessage(`mobiusGetProgram error: ${error.message}`, error, {
                            gnID
                        })
                    );
                    setIsLoading(false);
                    handleModal(null, false);
                    let errorMsg = MOBIUS_NOTIFICATIONS.ERROR_COULD_NOT_BE_OPENED;
                    if (error.error === 'not_found') {
                        errorMsg = MOBIUS_NOTIFICATIONS.PROGRAM_DELETED
                    }
                    handleNotification(true, errorMsg, false)
                });
        } else if (programType === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE && gnID !== program?.gnID) {
            dispatch(mobiusGetEpisodeByEpisodeId(
                gnID,
                sourceId
            )).then((response) => {
                if (response) {
                    dispatch(setGnidsProgram(response));
                }
            }).catch((error) => {
                dispatch(
                    gnviewSendLogMessage(`mobiusGetEpisodeByEpisodeId error: ${error.message}`, error, {
                        gnID
                    })
                );
                setIsLoading(false);
            });
        } else if (isRegisterModal && !program) {
            setIsLoading(false);
            if (programType === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE) {
                dispatch(setGnidsProgram({
                    type: MOBIUS_PROGRAM_TYPES_VALUES.EPISODE
                }));
            }
        }
        if (program || imagesData) {
            setIsLoading(false)
        }
        setAllLanguages(languages);
    };

    // Get Related Versions and Presentations for a program
    const fetchRelatedProgramsData = useCallback(
        () => {
            return mobiusGetProgramPresentations(
                program?.rootGnID || program?.showRootGnID,
                sourceId,
                programType,
                program?.gnID
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [program, programType, sourceId]
    );

    // Mimicking componentDidMount
    useEffect(fetchProgram, [
        dispatch,
        gnID,
        handleModal,
        handleNotification,
        imagesData,
        isRegisterModal,
        mobiusEntitledLanguages,
        program,
        programType,
        sourceId,
        versionGnId,
        seasonNumber
    ]);

    const fetchImages = () => {
        if (program?.gnID?.length > 0 && sourceId?.length > 0 && !isRegisterModal) {
            setIsLoading(true);
            dispatch(clearGnidsProgramImages());
            dispatch(
                mobiusGetImageGNIdByPresentationGNId(
                program?.gnID,
                sourceId
                )
            ).then(async (response) => {
                const fetchImageData = (imageGnID) => {
                    const imageResponse = dispatch(mobiusGetImage(
                        imageGnID,
                        sourceId
                    )).catch((error) => {
                        dispatch(
                            gnviewSendLogMessage(`mobiusGetImage: ${error.message}`, error, {
                                imageGnID
                            })
                        )
                    });
                    return imageResponse
                };
                if (response.length) {
                    const imageList = await Promise.allSettled(
                        response.map((programImage) => {
                            return fetchImageData(programImage.imageGnID)
                        })
                    )

                    const filteredImageList = imageList.filter(image => typeof image.value === 'object')
                    const valuesFromFilteredImageList = filteredImageList.map(image => image.value)
                    const transformedImages = valuesFromFilteredImageList.map(image => transformImageData(image, true))

                    dispatch(setGnidsProgramImages(transformedImages))
                }
                setIsLoading(false)
            }).catch((error) => {
                dispatch(
                    gnviewSendLogMessage(`mobiusGetImageGNIdByPresentationGNId error: ${error.message}`, error, {
                        gnID: program?.gnID
                    })
                );
                setIsLoading(false)
            })
        }
    }

    useEffect(
        // eslint-disable-next-line react-hooks/exhaustive-deps
        fetchImages, [
            dispatch,
            isRegisterModal,
            program,
            sourceId
        ])

    const handleEditMode = () => {
        setEditMode((prevEditMode) => !prevEditMode);

        // Scroll the container to a specific position
        targetContainerRef.current.scrollTop = PROGRAM_SIDE_MENU_RATIOS[0];
    };

    const getProgramTitle = useCallback(() => {
        return program?.type === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE ? (
            <>
                <span>
                    <OverlayTrigger
                        overlay={<Tooltip id="program-title-tooltip">{parentShowTitle }</Tooltip>}
                        trigger={(parentShowTitle?.length > 36) ? ['hover'] : null}
                        placement='bottom'
                    >
                        <a className='show-title-breadcrumb' onClick={() => {
                            setIsLoading(true);
                            dispatch(clearProgramModalData());
                            const trimmedProgram = {
                                gnID: program?.showPresentationGnID,
                                type: MOBIUS_PROGRAM_TYPES_VALUES.SHOW,
                                versionGnID: program?.showVersionGnID
                            };
                            handleModal(trimmedProgram, true);
                        }}>{parentShowTitle}</a>
                    </OverlayTrigger>
                    {parentShowTitle && <FontAwesomeIcon icon='angle-right' />}
                    {parentSeason?.length > 0 && (
                        <>
                            {`Season ${parentSeason}`}
                            <FontAwesomeIcon icon='angle-right' />
                        </>
                    )}
                    <OverlayTrigger
                        overlay={<Tooltip id="program-title-tooltip">{program?.title?.value }</Tooltip>}
                        trigger={(program?.title?.value.length > 36) ? ['hover'] : null}
                        placement='bottom-start'
                    >
                        <span className='episode-title'>{program?.title?.value}</span>
                    </OverlayTrigger>
                </span>
            </>
        ) : (
            <>
                <OverlayTrigger
                    overlay={<Tooltip id="program-title-tooltip">{program?.title?.value }</Tooltip>}
                    trigger={(program?.title?.value.length > 36) ? ['hover'] : null}
                    placement="bottom"
                >
                    <span className='show-title-no-breadcrumb'>
                        {program?.title?.value}
                    </span>
                </OverlayTrigger>
            </>
        );
    }, [program, dispatch, parentShowTitle, parentSeason, setIsLoading, handleModal]);

    const formatModalTitle = useCallback(() => {
        if (gnID && !isRegisterModal) {
            return getProgramTitle();
        } else if (registerPresentationMode) {
            return MOBIUS_LABELS.REGISTER_NEW_PRESENTATION
        } else if (registerVersionMode) {
            return MOBIUS_LABELS.REGISTER_NEW_VERSION;
        } else if (isRegisterModal && parentShowTitle) {
            return MOBIUS_LABELS.REGISTER_NEW_EPISODE;
        } else if (isRegisterModal && !registerPresentationMode && !registerVersionMode) {
            return MOBIUS_LABELS.REGISTER_NEW_PROGRAM;
        }
        return null;
    }, [getProgramTitle, gnID, isRegisterModal, parentShowTitle, registerPresentationMode, registerVersionMode]);

    const formatEditProgram = () => {
        let editProgram;
        if (registerPresentationMode) {
            editProgram = clearPresentationValues(program);
        } else if (registerVersionMode) {
            editProgram = clearVersionAndPresentationValues(program);
        } else {
            editProgram = program;
        }

        return editProgram;
    };

    const handleCancel = (dirty) => {
        if (dirty) {
            setShowCancelConfirm(true);
        } else if (refreshProgram) {
            dispatch(gnidsClearEpisode());
            dispatch(clearProgramModalData());
            handleModal(program, false, null, null, false, refreshProgram);
            setProgramModalEditMode(false);
        } else if (importStatusFlag) {
            dispatch(gnidsClearEpisode());
            dispatch(clearProgramModalData());
            handleModal(null);
        } else {
            dispatch(gnidsClearEpisode());
            dispatch(clearProgramModalData());
            handleModal(null, false, null, null, false, false);
            setProgramModalEditMode(false);
        }
    };

    const handlePresentationChange = (data) => {
        setIsLoading(true)
        setShowRelatedPrograms(false);
        dispatch(clearProgramModalData());
        handleModal(data, true, null, null, false, false);
        setProgramModalEditMode(false);
    };

    return (
        <ErrorBoundary>
            {/* We're doing a custom css hide here instead of using show because we don't want this to unmount and lose the user's current changes */}
            <GNModal
                show={true}
                onHide={() => handleCancel(isDirty)}
                className={ClassNames('mobius-program-modal', {
                    hide: showCancelConfirm || !showProgramModal
                })}
            >
                {isLoading ?
                    <LoadingSpinner />
                    :
                    <div className='program-modal-container'>
                        <Modal.Title>
                            <div className="gnview-header-container">
                                <div className='gnview-header-title'>{formatModalTitle()}</div>
                                <div className="edit__button">
                                    {!editMode && !isRegisterModal && (

                                        <GNButton tooltip={<Tooltip id="edit-button-tooltip"> {hasWriteAccess ? MOBIUS_TOOLTIPS.EDIT_BUTTON : MOBIUS_TOOLTIPS.REQUIRES_WRITE_ACCESS}</Tooltip>}
                                            trigger={['hover']}
                                            onClick={handleEditMode}
                                            disabled={!hasWriteAccess}
                                            buttonClass='edit-button'
                                            hasWriteAccess={hasWriteAccess}
                                        >
                                            <FontAwesomeIcon icon="pen" /> {MOBIUS_BUTTON_TEXT.EDIT}
                                        </GNButton>
                                    )}
                                </div>
                            </div>
                            {!editMode && (
                                <div className='gnview-version-presentation-labels'>
                                    {isRegisterModal && (
                                        <span className='register-presentation-sub-header'>
                                            <span>{formatModalTitle()}</span>
                                        </span>
                                    )}
                                    {(program?.versions?.versionLabels?.length > 0 || program?.versionLabels?.length > 0) && (
                                        <span className='version-labels'>
                                            {program?.versions?.versionLabels?.join(', ') || program?.versionLabels?.join(', ')}
                                        </span>
                                    )}
                                    {program?.presentationLabels?.[0]?.length > 0 && (
                                        <span className='presentation-labels'>
                                            {program?.presentationLabels?.join(', ')}
                                        </span>
                                    )}
                                </div>
                            )}
                            {!editMode && (
                                <div className='title-sub-section'>
                                    <MobiusStatusIcon
                                        selected={true}
                                        status={program?.publishingStatus}
                                    />
                                    {/* Do not display GNView Button if entitilements does not include tmsID */}
                                    {hasTMSEntitlements && program?.tmsID && (
                                        <GNViewButton
                                            link={`${ROUTES.PROGRAM_DETAILS}/${program?.tmsID}`}
                                        />
                                    )}
                                    <span className='ids-section'>
                                        {(program?.rootGnID) && (
                                            <span className='id-text'>
                                                <span className='id-label'>Root {program.rootGnID}</span>
                                            </span>
                                        )}
                                        {program?.versionGnID && (
                                            <span className='id-text'>
                                                <span className='id-label'>Version {program.versionGnID}</span>
                                            </span>
                                        )}
                                        {program?.gnID && (
                                            <span className='id-text'>
                                                <span className='id-label'>Presentation {program.gnID}</span>
                                            </span>
                                        )}
                                        {/* Do not display GNView Button if entitilements does not include tmsID */}
                                        {hasTMSEntitlements && program?.tmsID && (
                                            <span className='id-text'>
                                                <span className='id-label'>TMS ID {program.tmsID}</span>
                                            </span>
                                        )}
                                    </span>
                                </div>
                            )}
                            {!editMode && program?.last_modified_date && (
                                <div className='last-updated'>
                                    Last Updated{' '}
                                    {moment(program.last_modified_date).format('MM/DD/YYYY')}
                                </div>
                            )}
                        </Modal.Title>
                        <Modal.Body>
                            <div className='program-modal-side-menu-container'>
                                <ScrollSpyMenu
                                    menuItemIds={getProgramMenuItems(PROGRAM_SIDE_MENU, programType, editMode, isRegisterModal, Boolean(program?.publishExceptions))}
                                    menuLabel={MOBIUS_LABELS.PROGRAM_DETAILS}
                                    menuRatios={getProgramMenuRatios(PROGRAM_SIDE_MENU_RATIOS, programType, editMode)}
                                    targetContainerId='program-modal-body'
                                    handleItemClick={() => {
                                        setShowRelatedPrograms(false)
                                    }}
                                    disabled={showRelatedPrograms}
                                />
                                {!editMode && !isRegisterModal && programType !== MOBIUS_PROGRAM_TYPES_VALUES.EPISODE && sourceId?.[0] && (
                                    <div className='related-programs-section'>
                                        <div className='related-programs-header'>
                                            {MOBIUS_LABELS.RELATED_PROGRAMS}
                                        </div>
                                        <div
                                            className={ClassNames('related-programs-button', {
                                                active: showRelatedPrograms
                                            })}
                                            onClick={() => setShowRelatedPrograms(true)}
                                        >
                                            {MOBIUS_LABELS.RELATED_VERSIONS_AND_PRESENTATIONS}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div id='program-modal-body' ref = {targetContainerRef}>
                                {!editMode && !showRelatedPrograms && !isRegisterModal && (
                                    <ProgramModalViewOnly
                                        handleModal={handleModal}
                                        setShowProgramModal={setShowProgramModal}
                                        setIsLoading={setIsLoading}
                                        handleNotification={handleNotification}
                                    />
                                )}
                                {!editMode &&
                                    showRelatedPrograms && (
                                    <GNServerTable
                                        className='related-programs-table'
                                        columns={MOBIUS_RELATED_PROGRAMS_COLUMN_HEADERS(
                                                program?.gnID,
                                                handlePresentationChange
                                        )}
                                        fetchData={fetchRelatedProgramsData}
                                        noDataMessage={MOBIUS_LABELS.NO_RELATED_PROGRAMS}
                                    />
                                )}
                                {editMode && (
                                    <ProgramModalEdit
                                        allLanguages={allLanguages}
                                        handleCancel={handleCancel}
                                        program={formatEditProgram()}
                                        isDirty={isDirty}
                                        setIsDirty={setIsDirty}
                                        setShowProgramModal={setShowProgramModal}
                                        setRefreshProgram={setRefreshProgram}
                                        isRegisterModal={isRegisterModal}
                                        handleModal={handleModal}
                                        handleNotification={handleNotification}
                                        registerPresentationMode={registerPresentationMode}
                                        registerVersionMode={registerVersionMode}
                                    />
                                )}
                            </div>
                        </Modal.Body>
                    </div>
                }
            </GNModal>
            <GNConfirmationModal
                cancelButtonHandler={() => setShowCancelConfirm(false)}
                cancelButtonText={MOBIUS_BUTTON_TEXT.CANCEL}
                message={MOBIUS_CONFIRMATIONS.CANCEL_CONFIRMATION_MESSAGE}
                show={showCancelConfirm}
                submitButtonHandler={() => {
                    handleModal(program);
                    dispatch(gnidsClearEpisode());
                    dispatch(clearProgramModalData());
                }}
                submitButtonText={MOBIUS_BUTTON_TEXT.EXIT}
                title={MOBIUS_CONFIRMATIONS.CANCEL_CONFIRMATION_TITLE}
            />
        </ErrorBoundary>
    );
};

ProgramModal.propTypes = {
    handleModal: PropTypes.func.isRequired,
    isRegisterModal: PropTypes.bool,
    handleNotification: PropTypes.func,
    programModalEditMode: PropTypes.bool,
    registerPresentationMode: PropTypes.bool,
    registerVersionMode: PropTypes.bool,
    setProgramModalEditMode: PropTypes.func,
    importStatusFlag: PropTypes.bool
};

export default ProgramModal;