import React from 'react'
import GNButton from '../../components/common/gnButton/GNButton'
import { gvauthHasMobiusWriteAccess } from '../../reducers/GNVAuthReducer';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MOBIUS_BUTTON_TEXT, MOBIUS_LABELS, MOBIUS_TOOLTIPS } from '../../constants/Mobius';
import { Alert, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './Mappings.scss';
import GNInput from '../../components/common/gnInput/GNInput';
import { MOBIUS_PROGRAM_FIELDS } from '../../constants/MobiusProgramValidation';
import { mapProgramTypeAndSubTypeToGnviewFormat } from '../../utils/ProgramUtils';

const Mappings = ({validateForm, values, handleFormChange, errors, viewOnly = false, isPublish = false}) => {
    const hasWriteAccess = useSelector(gvauthHasMobiusWriteAccess);

    const parseReleaseYear = (year, date) => {
        if (year) {
            return year;
        }
        return date ? new Date(date).getFullYear() : undefined;
    }

    const onClickSearchForTmsId = () => {
        const advancedsearchPath = 'advanced-search';
        const {title: {value: title, language: titleLanguage}, releaseYear, releaseDate, type, subType } = values;
        const {programType: programTypes, subType: programSubtypes} = mapProgramTypeAndSubTypeToGnviewFormat(type, subType);
        const urlSearchParams = new URLSearchParams({title, titleLanguage, releaseYear: parseReleaseYear(releaseYear, releaseDate), programTypes, programSubtypes});
        const urlToOpen = `${window.location.origin}/${advancedsearchPath}?${urlSearchParams}`;
        window.open(urlToOpen, '_blank');
    }

    const renderMappingInfo = () => {
        if (viewOnly) {
            if (values?.mappingInfo?.id || values?.internalAttributes?.selfMappingID) {
                return (
                    <div>
                        <div className='mapping-info'>
                            <p className='tmsid-label'>Self-linked TMS ID</p>
                            <p className='tmsid-value'>{isPublish ? values?.mappingInfo?.id : values?.internalAttributes?.selfMappingID}</p>
                        </div>
                        <Alert variant='info' className='request-publish-note'>
                            <FontAwesomeIcon icon='circle-info' /> Request publish to complete the self-map to TMS ID
                        </Alert>
                    </div>
                );
            } else {
                return <p className='no-data-message'>{MOBIUS_LABELS.NO_DATA_ENTERED}</p>;
            }
        } else {
            return null;
        }
    };

    return <div className='mappings-container'>
        {!viewOnly &&
            <GNButton
                tooltip={<Tooltip id="search-for-tms-button-tooltip">{MOBIUS_TOOLTIPS.REQUIRES_WRITE_ACCESS}</Tooltip>}
                trigger={!hasWriteAccess ? ['hover'] : null}
                onClick={onClickSearchForTmsId}
                disabled={!hasWriteAccess}
                buttonClass='search-for-tms-button'
                hasWriteAccess={hasWriteAccess}
            >
                <FontAwesomeIcon icon='magnifying-glass' />
                {MOBIUS_BUTTON_TEXT.SEARCH_FOR_TMS}
            </GNButton>
        }
        {!viewOnly &&
            <GNInput
                className='tms-id'
                handleChange={(item) => handleFormChange(MOBIUS_PROGRAM_FIELDS.MAPPINGS_ID, item)}
                isRequired={false}
                label='Self-linked TMS ID'
                placeholder='TMS ID'
                validateInput={() => validateForm(MOBIUS_PROGRAM_FIELDS.MAPPINGS_ID)}
                validationText={errors[MOBIUS_PROGRAM_FIELDS.MAPPINGS_ID]}
                value={values?.userMapping?.id} />
        }
        {renderMappingInfo()}
    </div>
}

Mappings.propTypes = {
    viewOnly: PropTypes.bool,
    isPublish: PropTypes.bool,
    validateForm: PropTypes.func,
    values: PropTypes.any,
    handleFormChange: PropTypes.func,
    errors: PropTypes.any
}

Mappings.defaultProps = {
    viewOnly: false,
    isPublish: false
}

export default Mappings;